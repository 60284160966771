import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
 
  invoice_id: any;
  table_year: any;
  details: any;
  flightinfo: any;
  clientinfo: any;
  groupinv: any;
  officeinfo: any;
  response: any;
  country_table: any;
  year: string;
  table: any;
  code: any;
  taxcode: string;
   
  
  constructor(private route:ActivatedRoute,private http:HttpClient) { }

 
  ngOnInit(): void {
    this.year = this.route.snapshot.paramMap.get('year');
    this.country_table = this.route.snapshot.paramMap.get('code');
    this.invoice_id = this.route.snapshot.paramMap.get('id');
      
    let param_value = this.invoice_id  + '&' + 'code=' + this.country_table +'&'+ 'year=' + this.year

    this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_bill_view/?id='+param_value)
    .subscribe((response: any) => {
      this.table = this.country_table;
      this.year = response.year;
      this.code = response.code;
      this.details = response.detail;
      this.officeinfo = JSON.parse(response?.office)

      if(this.details.vat == 0){
          this.taxcode = "001 ";
      }else {
      this.taxcode = this.details.vat;
    }


      if (this?.details?.flightinfo != '') {
        this.flightinfo = JSON.parse(this.details?.flightinfo)
      }
      if (this?.details?.client_info != '') {
        this.clientinfo = JSON.parse(this.details?.client_info)
      }
      if (this?.details?.groupinv) {
        this.groupinv = JSON.parse(this?.details?.groupinv)
        console.log(this.groupinv)
      }

    });

  }

}
