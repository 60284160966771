<div class="container">



<div class="card">
    <div class="card-datatable table-responsive">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
           
            <table class="datatables-review table dataTable no-footer dtr-column">
                <thead>
                    <tr>
                        
                        <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1" colspan="1"
                            style="width: 18px;" data-col="1" aria-label=""><input type="checkbox"
                                class="form-check-input"></th>
                        <th class="sorting sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 239px;" aria-label="Product: activate to sort column descending"
                            aria-sort="ascending">Product</th>       
                       
                        <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                            style="width: 89px;" aria-label="Date: activate to sort column ascending">Date</th>
                      
                   
                    </tr>
                </thead>
                <tbody>
                    <tr class="odd"*ngFor="let user of notepad">
                        
                          <td>
                            <div class="d-flex justify-content-start align-items-center customer-name">
                            <div class="d-flex flex-column">
 <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" data-bs-toggle="modal" data-bs-target="#notepadedit">
             <span class="fw-medium">#{{user.id}}</span></button>
          </div>                                    
                            </div>
                        </td>
                        <td>
                            <div>
                                <div>
                                    <div class="jq-ry-group-wrapper">
                                        <div class="jq-ry-normal-group jq-ry-group">
                                        </div>
                                       
                                    </div>
                                </div>
                               
                                <small class="text-break pe-3">
                                    <div [innerHTML]="user.notepad"></div>
                                     </small>
                            </div>
                            </td>
                            <td><span class="text-nowrap">{{user.date}}</span></td>
                           
                           
                    </tr>

                   
                </tbody>
            </table>
           
           
        </div>
    </div>
</div>

<div class="modal animate__animated animate__flipInY" id="notepadedit" tabindex="-1" aria-modal="true" role="dialog" >   
    <div class="modal-dialog modal-lg modal-simple modal-refer-and-earn">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <div>
                        <label class="form-label">Description <span class="text-muted">(Optional)</span></label>
                        <ngx-quill ngModel=" " #editor class="editor" formControlName="description" modules="modules"></ngx-quill>                      
                      </div>                   
                </div>                
              
            </div>
        </div>
    </div>
</div>


</div>