import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss']
})
export class NotepadComponent implements OnInit {
  loading: boolean;
  localUserIdsbag: any;
  localUserIds: any;
  notepad: any;
  account: number;

  constructor(private http:HttpClient,) { }

  ngOnInit(): void {

   // this.loading = true;
  //  var localUserId: any;
    //localUserId = JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')    
    //this.localUserIds = localUserId?.detail.id    
    //this.localUserIdsbag = localUserId?.detail.token
    //const payload = { key:this.localUserIdsbag};     
    this.http.get('https://www.kelvintravel.com/api_agent/agent/list_reminder/', ).subscribe((response: any) => {
    this.notepad= response?.detail;
   // this.account=1;
   // this.loading = false;
//  console.log(this.account)
   });

}


   

  

}
