<div class="container-xxl flex-grow-1 container-p-y">
    
    <div class="app-ecommerce">
      <!-- Add Product -->
      <div class="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div class="d-flex flex-column justify-content-center">
         
  
        </div>
       
      </div>
  
      <div class="row">
        <!-- First column-->
        <div class="col-12 col-lg-8">
          <!-- Product Information -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Image Data update</h5>
              <button (click)="update()">Submit/Update</button>
            </div>
            <div class="card-body" >
  
              <form [formGroup]="updateform">
              
              <!-- Description -->
              <div>
                <label class="form-label">Image Here 
                    <span class="text-muted">(Optional)</span></label>
                <ngx-quill formControlName="text" [content]="imageViewData" #editor class="imageViewData" modules="modules"></ngx-quill>              
              </div>  

             

            </form>
            </div>
          </div> 
          <!-- /Inventory -->
        </div>
        <!-- /Second column -->  
        <!-- Second column -->      
        <!-- /Second column -->
      </div>
    </div>  
  </div>
  