<div class="container-xxl flex-grow-1 container-p-y">
    <div class="card mb-4">
        <div class="card-widget-separator-wrapper">
            <div class="card-body card-widget-separator">
                <div class="row gy-4 gy-sm-1">
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">00</h3>
                                <p class="mb-0">Cash Recived</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class='bx bx-euro'></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none me-4" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">00</h3>
                                <p class="mb-0">Cash Deposit</p>
                            </div>
                            <div class="avatar me-lg-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-check-double bx-sm"></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
                            <div>
                                <h3 class="mb-2">00</h3>
                                <p class="mb-0">Cash in Hand</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-wallet bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="d-flex justify-content-between align-items-start">
                            <div>
                                <h3 class="mb-2">00</h3>
                                <p class="mb-0">Cash Expense</p>
                            </div>
                            <div class="avatar">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-error-alt bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Order List Table -->


    <div class="card">
        <div class="card-datatable table-responsive">
            <div class="card-header d-flex border-top rounded-0 flex-wrap">
                <div class="me-5 ms-n2 pe-5">
                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                        <label>
                            <input type="search" class="form-control"
                                placeholder="Search Product" aria-controls="DataTables_Table_0">
                            </label>
                        </div>
                        
                </div>
                
                <div class="d-flex justify-content-start justify-content-md-end align-items-baseline">
 <div class="dt-action-buttons d-flex align-items-start align-items-md-center justify-content-sm-center mb-3 mb-sm-0">
       <div class="dt-buttons d-flex flex-wrap"> 
            <div class="input-group input-daterange" id="bs-datepicker-daterange" style="width: 50%;"> 
                                        <input type="text" id="dateRangePicker" placeholder="MM/DD/YYYY" class="form-control">
                                        <span class="input-group-text">to</span>
                                        <input type="text" placeholder="MM/DD/YYYY" class="form-control">
                                      </div>
                                    <button class="dt-button buttons-collection btn btn-label-secondary me-3" tabindex="0"
                                        aria-controls="DataTables_Table_0" type="button" aria-haspopup="dialog" aria-expanded="false"><span><i
                                                class="bx bx-export me-1"></i>Search</span>
                                    </button>

                             
                                    
                                    
                                    

                                </div>
                    </div>
                </div>
            </div>
           <table class="datatables-order table border-top dataTable no-footer dtr-column collapsed">
            
                  
                <thead>
                    <tr>
                 
                        <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" style="width: 18px;">
                            <input type="checkbox" class="form-check-input"></th>
                        <th class="sorting" style="width: 64px;">order</th>
                        <th class="sorting sorting_asc" style="width: 149px;">customers</th>
                        <th class="sorting" style="width: 149;">TKT</th>
                        <th class="sorting"  style="width: 121px;">Price</th>                       
                        <th class="sorting" style="width: 130px;">status</th>
                        <th class="sorting"  style="width: 121px;">payment</th>
                        <th class="sorting" style="width: 153px;">Date</th>
                        <th class="sorting"  style="width: 121px;">Country</th>

                    </tr>
                </thead>
              
            </table>
        </div>
    </div>
</div>
 

 

 
