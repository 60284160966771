import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { NgxQuillModule } from '@dimpu/ngx-quill';

import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgentBookingComponent } from './agent-booking/agent-booking.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { ClientListComponent } from './client-list/client-list.component';
import { PendingtaskComponent } from './pendingtask/pendingtask.component';
import { PagesComponent } from './pages.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { AccountsComponent } from './accounts/accounts.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { InvoiceComponent } from '../auth/invoice/invoice.component';
import { InvoiceEditComponent } from './invoice-edit/invoice-edit.component';
import { AuthGuard } from 'src/app/auth.guard';
import { NotepadComponent } from './notepad/notepad.component';
import { UpdateorderComponent } from './updateorder/updateorder.component';
import { CashComponent } from './cash/cash.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        redirectTo: 'Dashboard',
        pathMatch: 'full',
              },
      {
        path: 'Dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'Invoice/:id/:year/:code',
        component: InvoiceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'Invoice-edit/:id/:year/:code',
        component: InvoiceEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'AddOrder/:clientID',
        component: AddOrderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'updateorder/:rowID/:userID',
        component: UpdateorderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'updateorder/:rowID/:userID',
        component: UpdateorderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'notepad',
        component: NotepadComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'Accounts/:country',
        component: AccountsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'Invoices/:country',
        component: InvoicesComponent,
        canActivate: [AuthGuard],

      },

      {
        path: 'AgentBooking/:id',
        component: AgentBookingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'Orderdetails/:pnr/:id/:clientId/:country/:countries',
        component: OrderdetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ClientEdit/:id',
        component: ClientEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ClientList',
        component: ClientListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'Pendingtask',
        component: PendingtaskComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
]



@NgModule({
  declarations: [
    DashboardComponent,PagesComponent,AgentBookingComponent,
    ClientEditComponent,ClientListComponent,PendingtaskComponent,
    NavbarComponent,FooterComponent,OrderdetailsComponent,
    AccountsComponent,InvoicesComponent, AddOrderComponent,InvoiceComponent, InvoiceEditComponent, NotepadComponent, UpdateorderComponent, CashComponent
  ],
  imports: [
    CommonModule,FormsModule, ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgxQuillModule

  ]
})
export class PagesModule { }
