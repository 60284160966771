<div class="container-xxl flex-grow-1 container-p-y">

    <div class="card mb-4">
        <div class="card-widget-separator-wrapper">
            <div class="card-body card-widget-separator">
                <div class="row gy-4 gy-sm-1">
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">56</h3>
                                <p class="mb-0">Pending Payment</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-calendar bx-sm"></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none me-4" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">0.00</h3>
                                <p class="mb-0">Completed</p>
                            </div>
                            <div class="avatar me-lg-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-check-double bx-sm"></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
                            <div>
                                <h3 class="mb-2">0.00</h3>
                                <p class="mb-0">Refunded</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-wallet bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="d-flex justify-content-between align-items-start">
                            <div>
                                <h3 class="mb-2">0.00</h3>
                                <p class="mb-0">Failed</p>
                            </div>
                            <div class="avatar">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-error-alt bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Order List Table -->

    <div class="card">
        <div class="card-datatable table-responsive">
            <div class="card-header d-flex border-top rounded-0 flex-wrap">
                    <div
                            class="dt-action-buttons d-flex align-items-start align-items-md-center justify-content-sm-center mb-3 mb-sm-0">
                    <div class="dt-buttons d-flex flex-wrap">
                                <form [formGroup]="filterForm">
                                    <div class="row"> 
            <div class=" col-lg-3 col-sm-12">        
          <input (keyup)="search_client_one()" (keyup)="client_ids()"ngModel={{client_id_get_one}} type="text"
                  class="col-2 form-control"placeholder="Client id"formControlName="client_id_get"aria-label="Product title" />
                  <button type="button" (click)="resetfilterForm()" aria-label="Close" class="btn-close text-reset"></button>
                   </div>
                    <div class="list-group" *ngIf="search_bar_one">
                      <div class="rinku">
                             <ul class="textbox">                                
                             <div *ngFor="let item of clientlist_one">
                                    <li class=" select textarea " (click)="onClientData_one(item)">
                                 <div>
                                   <div>
                            <span class=" textarea">  {{item.name}}     </span>
                            <span class="mb-0 w-px-100 text-warning">  {{item.email}} </span>
                            <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.use_id}}</span>           
                                   </div>                                  
                                 </div>
                               </li> 
                             </div>
                           </ul>
                         </div>                 
                 </div>  
                                <div class="col-lg-6 col-sm-12">
                                <div class="input-group input-daterange" id="bs-datepicker-daterange">
                                    <input
                                    placeholder="start date"
                                    type="date" class="form-control"
                                    formControlName="start_date"
                                    [(ngModel)]="start_datek" placeholder="MM/DD/YYYY"
                                  /> 
                                    <span class="input-group-text">to</span>
                                    <input
                                placeholder="end date"
                                type="date"
                                [(ngModel)]="end_datek"
                                formControlName="end_date" placeholder="MM/DD/YYYY" class="form-control"
                                />
                            </div>
                        </div>
                            <div class="demo-inline-spacing col-lg-3 col-sm-12">
                                <button (click)="applyFilters()" style="margin: 0 0.375rem !important;" class="m-0 btn btn-info">Filter</button>
                                <button (click)="applyFilters()"  style="margin: 0!important;"class="m-0 btn btn-warning">Export</button>
                              </div>

                        </div>
                    </form>
                                    </div>
                    </div>
                      <div class="demo-vertical-spacing">
                        <label class="switch switch-square switch-dark">
                          <input type="checkbox" (change)="applyFilters()"[(ngModel)]="PT_Client"  
                          class="switch-input " checked="">
                          <span class="switch-toggle-slider">
                            <span class="switch-on"></span>
                            <span class="switch-off"></span>
                          </span>
                          <span class="switch-label">:PT Client</span>
                        </label>
                        <div class="has-error">
                          <label class="switch switch-square switch-warning">
                            <input type="checkbox" (change)="applyFilters()" class="switch-input is-invalid"  [(ngModel)]="FR_Client" checked="">
                            <span class="switch-toggle-slider">
                              <span class="switch-on"></span>
                              <span class="switch-off"></span>
                            </span>
                            <span class="switch-label ">:FR Client</span>
                          </label>
                        </div>
                      </div>


                      <div class="demo-vertical-spacing">
                        <label class="switch switch-square">
                          <input type="checkbox" (change)="applyFilters()"  [(ngModel)]="BE_Client"  class="switch-input is-valid" checked="">
                          <span class="switch-toggle-slider">
                            <span class="switch-on"></span>
                            <span class="switch-off"></span>
                          </span>
                          <span class="switch-label">:BE Client</span>
                        </label>
                        <div class="has-error">
                          <label class="switch switch-square">
                            <input type="checkbox" (change)="applyFilters()"  class="switch-input is-invalid"  [(ngModel)]="PT_Agent" checked="">
                            <span class="switch-toggle-slider">
                              <span class="switch-on"></span>
                              <span class="switch-off"></span>
                            </span>
                            <span class="switch-label">:PT Agent</span>
                          </label>
                        </div>
                      </div>


                      <div class="demo-vertical-spacing">
                        <label class="switch switch-square">
                          <input type="checkbox" (change)="applyFilters()"  [(ngModel)]="BE_agent"  class="switch-input is-valid" checked="">
                          <span class="switch-toggle-slider">
                            <span class="switch-on"></span>
                            <span class="switch-off"></span>
                          </span>
                          <span class="switch-label">:BE agent</span>
                        </label>
                        <div class="has-error">
                          <label class="switch switch-square">
                            <input type="checkbox" (change)="applyFilters()" class="switch-input is-invalid"  [(ngModel)]="FR_Agent" checked="">
                            <span class="switch-toggle-slider">
                              <span class="switch-on"></span>
                              <span class="switch-off"></span>
                            </span>
                            <span class="switch-label">:FR Agent</span>
                          </label>
                        </div>
                      </div>

                      <div class="demo-vertical-spacing">
                        <label class="switch switch-square">
                          <input type="checkbox" (change)="applyFilters()" [(ngModel)]="Missing_Tkts"  class="switch-input is-valid" checked="">
                          <span class="switch-toggle-slider">
                            <span class="switch-on"></span>
                            <span class="switch-off"></span>
                          </span>
                          <span class="switch-label">:Missing</span>
                        </label>
                        <div class="has-error">
                          <label class="switch switch-square">
                            <input type="checkbox" (change)="applyFilters()"  class="switch-input is-invalid"  [(ngModel)]="All_Entries" checked="">
                            <span class="switch-toggle-slider">
                              <span class="switch-on"></span>
                              <span class="switch-off"></span>
                            </span>
                            <span class="switch-label">:All Tkt</span>
                          </label>
                        </div>
                      </div>

                      


             
            </div>
            <table class="datatables-order table border-top dataTable no-footer dtr-column collapsed">

             
                <thead>
                    <tr>

                        <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" style="width: 18px;">
                            <input type="checkbox" class="form-check-input">
                        </th>
                        <th class="sorting" style="width: 64px;">order</th>
                        <th class="sorting sorting_asc" style="width: 149px;">customers</th>
                        <th class="sorting" style="width: 149;">TKT</th>
                        <th class="sorting" style="width: 121px;">Price</th>
                        <th class="sorting" style="width: 130px;">status</th>
                        <th class="sorting" style="width: 121px;">payment</th>
                        <th class="sorting" style="width: 153px;">Date</th>
                        <th class="sorting" style="width: 121px;">Country</th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="even" *ngFor="let user of rows">
                        <td class="dt-checkboxes-cell">
                            <a (click)="pt_invoice(user)" data-bs-toggle="offcanvas" class="pointer"
                                data-bs-target="#offcanvasEcommerceCustomerinvoice">
                                <span class="badge badge-center rounded-pill bg-label-success w-px-30 h-px-30">
                                    <i class="bx bx-adjust bx-xs"> </i>
                                </span> </a>
                    
                            <div class="d-flex justify-content-sm-center align-items-sm-center">
                                <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow">
                                    <a (click)="get_data(user)" data-bs-toggle="offcanvas" class="pointer"
                                        data-bs-target="#offcanvasEcommerceCustomerAdd">
                                        <i class="bx bx-dots-vertical-rounded"></i></a> </button>
                           </div>
                             </td>

                        <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                         <div class="tooltip1 text-primary fw-medium ">{{user.pnr}}
                                    <span class="tooltiptext1">{{user.agent_pending}} </span>
                                </div>
                          
                            <a [routerLink]="['/Orderdetails/'+user.pnr+'/'+user.id+'/'+user.client_id+'/'+user.table_code+'/'+'null']"
                                class="dropdown-item">
                            <span class="text-warning fw-small">#{{user.id}}</span>  </a>

                        </td>
                        <td style="max-width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <div class="d-flex justify-content-start align-items-center order-name text-nowrap">
                                <div class="avatar-wrapper">
                                    <div class="avatar me-2">

                                        <a data-bs-toggle="modal" data-bs-target="#image_model"
                                            (click)="jpg_data(user)">

                                            <img src="../../assets/img/avatars/{{user.table_code}}.jpg" alt="Avatar"
                                                class="rounded-circle"></a>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <h6 class="m-0 "><a href="pages-profile-user.html" class="text-body">
                                            {{user?.client_id}}</a></h6>
                                    <small class="text-muted">{{user?.user_username}}</small>
                                </div>
                            </div>
                        </td>

                        <td style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <div class="d-flex justify-content-start align-items-center product-name">
                                <div class="avatar-wrapper">
                                    <div class="ravtar  avatar me-2 rounded-2 rbg-label-secondary"><img
                                            src="https://www.khalsatravel.net/webroot/frontend/airline-images_new/{{user.IATA_code}}.png"
                                            alt="code" class="rounded-2"></div>
                                </div>
                                <div class="d-flex flex-column">
                                    <h6 class="text-body text-nowrap mb-0">{{user.number}}
                                    </h6><small class="text-muted text-truncate d-none d-sm-block">

                                        {{user.name}}</small>
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><a
                                href="app-ecommerce-order-details.html">
                                <span class="fw-medium">{{user.net}}-{{user.amount}}</span></a><br>
                                <span *ngIf="user.agent_total" class="badge bg-label-warning"> {{user.agent_total}}</span>    
                              </td>
                        <td>
                            <span
                                [ngClass]="{'badge bg-label-success code': user.t_code === 'CANX',
                                'bg-label-danger': user.t_code === 'RFND',
                                'bg-label-warning': user.t_code === 'Pending', 
                                'bg-label-success': user.t_code === 'TKTT','
                                 bg-label-info': user.t_code === 'ok'}"
                                class="badge px-2">{{user.t_code}}</span>
                        </td>
                        <td style="max-width: 130px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <div class="d-flex align-items-center text-nowrap">
                                <img src="../../assets/img/icons/payments/cash.png" alt="mastercard" class="me-2"
                                    width="16">
                                <span>{{user.bill}} {{user.mode}}</span>

                            </div>
                            <span class="badge bg-label-warning rounded-pill text-uppercase">{{user.code}}</span>
                        </td>
                        <td class="sorting_1"><span class="text-nowrap">{{user.date}}</span><br>


                            <div class="avatar-initial font_balance">
                                <img src="../../assets/img/icons/unicons/paypal.png" alt="User"
                                    class="rounded width_balance"> {{user.balance}}

                            </div>
                        </td>

                        <td class="sorting_1">
                          <span class="badge bg-label-danger"> {{user.service}}</span>
                             
                             
                              <span class="badge bg-label-primary"> {{user.country_account}}</span>
                                
                        </td>




                    </tr>

                </tbody>
            </table>
        
        </div>
    </div>
</div>


<div class="offcanvas offcanvas-end" tabindex="-1"  id="offcanvasEcommerceCustomerAdd" aria-modal="true" role="dialog">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">Update Account: {{row_id}}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="cleanmodal()"></button>
    </div>
    <div class="offcanvas-body mx-0 flex-grow-0">
      <form  [formGroup]="userForm" >
        <div class="ecommerce-customer-add-basic mb-3">         
          <div class="mb-3 fv-plugins-icon-container">
              
            <input formControlName="client_id_get" type="hidden" ngModel="{{client_id_get}}" class="form-control">
           
                 <label class="form-label1" for="ecommerce-customer-add-name">Client ID*  {{email}}</label>   
                 <form [formGroup]="SerachclientDeatilsFrom"> 
                 <input (keyup)="search_client()" ngModel="{{client_id_get}}" formControlName="client_id_get" type="text" class="form-control">
                 <div class="list-group" *ngIf="search_bar">
                    <div class="rinku">
                           <ul class="textbox">                                
                           <div *ngFor="let item of clientlist">
                                  <li class=" select textarea " (click)="onClientData(item)">
                               <div>
                                 <div>
                          <span class=" textarea">  {{item.name}}     </span>
                          <span class="mb-0 w-px-100 text-warning">  {{item.email}} </span>
                          <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.use_id}}</span>           
                                 </div>
                                
                               </div>
                             </li> 
                           </div>
                         </ul>
                       </div>             
               
               </div>       
            </form>     
            
            </div>  
         




            <div class="mb-3">
                <label class="form-label1" for="ecommerce-customer-add-state">Name</label>
                <input formControlName="tkt_name" type="text" ngModelOptions ="{standalone: false}" ngModel="{{tkt_name}}" class="form-control">
              </div>
                  <div class="mb-3">
                 <label class="form-label1" for="ecommerce-customer-add-address-2"> Payment remark</label>
                 <input formControlName="remarkss"  type="text" ngModelOptions ="{standalone: true}" ngModel="{{remarks}}" class="form-control">
               </div>
               
               
               <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Gds</label>
           <input formControlName="gds" ngModel="{{gds}}"   ngModelOptions ="{standalone: false}"
            type="number" id="ecommerce-customer-add-state" class="form-control" placeholder="">
  
         </div>
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Sale Amount</label>
           <input formControlName="saleamount" ngModel="{{saleamount}}" type="number" id="ecommerce-customer-add-post-code" class="form-control" name="saleamount">
         </div>
       </div>
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Recived Amount</label>
           <input formControlName="recived"  ngModel="{{recived}}"  type="number"  ngModelOptions ="{standalone: false}"
            class="form-control" placeholder="" aria-label="Southern tip" name="customerState">
         </div>
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Mode of Payment</label>  
                <select formControlName="modeofpayment" ngModelOptions ="{standalone: false}" class="form-control" ngModel="{{modeofpayment}}" >
                 
                    <option *ngFor="let sta of pay_method" [ngValue]="sta">{{sta}}</option>
                 </select>  
         </div>
       </div>
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Office Country</label>
           <select formControlName="country_accounts" class="form-control" ngModelOptions ="{standalone: false}" ngModel="{{country_accounts}}" >
            <option [ngValue]="country_accounts" Value="country_accounts">{{country_accounts}}</option>
                <option *ngFor="let sta of accounts"  [ngValue]="sta">{{sta}}</option>
              </select>  
  
         </div>
        
         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-state">Need Invocie*</label>
          <select formControlName="invoicedata" class="form-control"ngModelOptions ="{standalone: false}" ngModel="{{invoicedata}}" >
            <option [ngValue]="invoicedata" Value="invoicedata">{{invoicedata}}</option>
            <option *ngFor="let sta of invoices"  [ngValue]="sta">{{sta}}</option>
             </select>   
        </div>
       </div>
  
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Service</label>
           <select formControlName="service" class="form-control" ngModelOptions ="{standalone: false}">
            <option ngModel="{{service}}">{{service}}</option>
             <option *ngFor="let sta of serviceItems" [ngValue]="sta">{{sta}}</option>
           </select>          
         </div>
         
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="commerce-customer-add-state">TAX ID</label>
           <input formControlName="tax_id" type="text" ngModel="{{tax_id}}"  class="form-control">
         </div>
       </div>
       
  
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">PNR No.</label>
           <input formControlName="pnrno" type="text "ngModel="{{pnrno}}" class="form-control">
         </div>
         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-town">IATA Code</label>
           
          <select formControlName="iata" class="form-control" ngModel="{{iata}}" >
            <option [ngValue]="iata" Value="iata">{{iata}}</option>
            <option *ngFor="let sta of iataslist"  [ngValue]="sta">{{sta}}</option>
             </select>                      
            </div>   
        
       </div>      

       <div class="row mb-3">        
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Ticket number</label>
           <input formControlName="tkt_number" type="text" ngModel="{{tkt_number}}" class="form-control" ngModelOptions ="{standalone: false}" >
         </div>

         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-post-code">Status</label>
          <select formControlName="status" class="form-control" ngModel="{{status}}" >
        <option [ngValue]="status" Value="status">{{status}}</option>
            <option *ngFor="let sta of statuslist"  [ngValue]="sta">{{sta}}</option>
          </select>              
        </div>  
       </div>         
     </div>        
     <div class="pt-3">
       <button (click)="update_accounts()" type="submit" class="btn btn-primary me-sm-3 me-1 data-submit">update</button>
       <button type=" " class="btn bg-label-danger" data-bs-dismiss="offcanvas" (click)="cleanmodal()">Discard</button>
       <button (click)="delete()"  
       data-bs-toggle="modal" data-bs-target="#enableOTP"type="submit" class="btn btn-danger btn-buy-now me-sm-3 me-1 data-submit">Delete</button>
     </div>   
     
      <input type="hidden" (click)="cleanmodal()"></form>
    </div>
  </div>


<div class=" modelpt offcanvas offcanvas-kelvin" tabindex="-1" id="offcanvasEcommerceCustomerinvoice" aria-modal="true"
    role="dialog">
    <button type="button" class="btn-close text-bcp bank" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <div class="offcanvas-header">
        <div class="mb-xl-0 mb-4">
            <h5 class="offcanvas-title">VAT: {{molonidata?.vat}}</h5>

            <p class="mb-1">{{molonidata?.name}}</p>
            <p class="mb-1"> {{molonidata?.email}}</p>
            <p class="mb-0">{{molonidata?.phone}}</p>
            <p class="mb-0">{{molonidata?.address}} {{molonidata?.city}} {{molonidata?.zip_code}}
                {{molonidata?.country_name}}
            </p>
        </div>

    </div>
    <div class="offcanvas-body mx-0 flex-grow-0">
        <form [formGroup]="row_updates" id=" " class=" " onsubmit="return false" novalidate="novalidate">

            <div class="col-12 col-md-12 fv-plugins-icon-container">
                <label class="form-label" for="modalAddressLastName">Client Id</label>
                <input ngModel="{{pax_id}}" ngModelOptions="{standalone: true}" formControlName="pax_id" type="text"
                    class="form-control" placeholder="" name="pax_id">

                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>
            <div class="row mb-3">

                <div class="col-12 col-md-6">
                    <label class="form-label" for="modalAddressFirstName">Bill Date*</label>
                    <input ngModel="{{date}}" ngModelOptions="{standalone: true}" formControlName="date" type="text"
                        class="form-control" placeholder="" name="date">

                    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <label class="form-label" for="modalAddressLastName">IATA CODE</label>
                    <select formControlName="iata" class="form-control" ngModel="{{iata}}">
                        <option [ngValue]="iata" Value="modeofpayment">{{iata}}</option>
                        <option *ngFor="let sta of iataslist" [ngValue]="sta">{{sta}}</option>
                    </select>
                </div>
                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-md-6 fv-plugins-icon-container">
                    <label class="form-label" for="modalAddressLastName">TAX ID</label>
                    <input ngModel="{{tax_id}}" ngModelOptions="{standalone: true}" formControlName="tax_id" type="text"
                        class="form-control" placeholder="" name="tax_id">
                    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                    </div>
                </div>



                <div class="col-12 col-md-6">
                    <label class="form-label" for="modalAddressCity">PNR No.</label>
                    <input ngModel="{{pnrno}}" ngModelOptions="{standalone: true}" formControlName="pnrno" type="text"
                        class="form-control" placeholder="" name="pnrno">

                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-md-6">
                    <label class="form-label" for="modalAddressZipCode">Sale Value</label>
                    <input ngModel="{{saleamount}}" ngModelOptions="{standalone: true}" formControlName="saleamount"
                        type="text" class="form-control" placeholder="" name="customerName">

                </div>
                <div class="col-12 col-md-6">
                    <label class="form-label" for="modalAddressZipCode">GDS</label>
                    <input ngModel="{{gds}}" ngModelOptions="{standalone: true}" formControlName="gds" type="text"
                        class="form-control" placeholder="" name="customerName">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-md-6">
                    <label class="form-label" for="modalAddressLandmark">Mode of Payment</label>
                    <select formControlName="modeofpayment" class="form-control" ngModel="{{modeofpayment}}">
                        <option [ngValue]="modeofpayment" Value="modeofpayment">{{modeofpayment}}</option>
                        <option *ngFor="let sta of pay_method" [ngValue]="sta">{{sta}}</option>
                    </select>
                </div>





                <div class="col-12 col-md-6">
                    <label class="form-label" for="modalAddressZipCode">recived Amount</label>
                    <input ngModel="{{recived}}" ngModelOptions="{standalone: true}" formControlName="recived"
                        type="text" class="form-control" placeholder="" name="customerName">
                </div>
            </div>
            <div class="col-12 ">
                <label class="form-label" for="modalAddressAddress1">TKT No/Name of Pax</label>
                <input ngModel="{{tktnameforbill}}" ngModelOptions="{standalone: true}" formControlName="tkt_name"
                    type="text" class="form-control" placeholder="" name="customerName">


            </div>


            <div class="row mb-3">
                <div class="col-12 col-md-4">
                    <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal"
                        aria-label="Close">Cancel</button>
                </div>
                <div class="col-12 col-md-4">
                    <button (click)="send_to_moloni()" type="submit" class="btn btn-danger me-sm-3 me-1">Submit</button>

                </div>
                
            </div>
            <input type="hidden">
        </form>



    </div>
</div>


<div class="modal animate__animated animate__flipInY" id="image_model" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">

                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body px-0">
                <div class="row pl-3">
                    <div *ngIf="loaderi" class="loaderi" style="margin: 40%;"></div>
                    <div class="col-md-3 image_base">
                        <div [innerHTML]="imageViewData"></div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



 

  <div class="modal animate__animated animate__flipInY" id="enableOTP" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
      <div class="modal-content p-3 p-md-5">
        <div class="modal-body">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="text-center mb-4">
            <h3 class="mb-5">One Time Password</h3>
          </div>
         
          <form  [formGroup]="deleteForm" > 
              <div class="col-12 fv-plugins-icon-container">
              <label class="form-label" for="modalEnableOTPPhone"></label>
              <div class="input-group input-group-merge has-validation">
                <span class="input-group-text"></span>
                <input type="text" formControlName="delete_id" id="delete_id" name="modalEnableOTPPhone" class="form-control phone-number-otp-mask" 
                placeholder="202 555 0111">
                <input type="hidden" ngModel="{{row_id}}"formControlName="row_id" id="row_id">
              </div>
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                
              </div>
            </div>
            <div class="col-12">
              <button type="submit" (click)="onSubmitClient()" data-bs-dismiss="modal" class="btn btn-primary me-sm-3 me-1">Submit</button>
              <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Close</button>
            </div>
          <input type="hidden"></form>
        </div>
      </div>
    </div>
  </div>
 
 
<div *ngIf="loading" class="w-100 d-flex align-items-center justify-content-center"
    style="left: 0; top: 0; bottom: 0; right: 0;  z-index: 99;">
    <div class="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>


