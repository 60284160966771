<div class="content-wrapper">

  <!-- Content -->
  <div *ngIf="loading">
    <div class="loaderorder">
      
    </div>
    </div>
    <div class="container-xxl flex-grow-1 container-p-y">
      

<div class="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between mb-4 text-center text-sm-start gap-2">
<div class="mb-2 mb-sm-0">
<h4 class="mb-1">
Customer ID #{{res?.id}}
</h4>
<p class="mb-0">
  Customer ID #{{res?.date}}
</p>
</div>
<button type="button" class="btn btn-label-danger delete-customer">Delete Customer</button>
</div>


<div class="row">
<!-- Customer-detail Sidebar -->
<div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
<!-- Customer-detail Card -->
<div class="card mb-4">
<div class="card-body">
  <div class="customer-avatar-section">
    <div class="d-flex align-items-center flex-column">
      <img class="img-fluid rounded my-3" src="../../assets/img/avatars/12.png" height="110" width="110" alt="User avatar">
      <div class="customer-info text-center">
        <h4 class="mb-1">{{res?.user_code}}</h4>
        <small>Customer ID #{{res?.id}}</small>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-around flex-wrap mt-4 py-3">
    <div class="d-flex align-items-center gap-2">
      <div class="avatar">
        <div class="avatar-initial rounded bg-label-primary"><i class="bx bx-cart-alt bx-sm"></i>
        </div>
      </div>
      <div>
        <h5 class="mb-0">184</h5>
        <span>Orders</span>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2">
      <div class="avatar">
        <div class="avatar-initial rounded bg-label-primary"><i class="bx bx-dollar bx-sm"></i>
        </div>
      </div>
      <div>
        <h5 class="mb-0">$12,378</h5>
        <span>Spent</span>
      </div>
    </div>
  </div>

  <div class="info-container">
    <small class="d-block pt-4 border-top fw-normal text-uppercase text-muted my-3">DETAILS</small>
    <ul class="list-unstyled">
      <li class="mb-3">
        <span class="fw-medium me-2">Username:</span>
        <span>{{res?.name}}</span>
      </li>
      <li class="mb-3">
        <span class="fw-medium me-2">Email:</span>
        <span>{{res?.mail}}</span>
      </li>
      <li class="mb-3">
        <span class="fw-medium me-2">Status:</span>
        <span class="badge bg-label-success">Active</span>
      </li>
      <li class="mb-3">
        <span class="fw-medium me-2">Contact:</span>
        <span>{{res?.mobile}}</span>
      </li>

      <li class="mb-3">
        <span class="fw-medium me-2">Country:</span>
        <span>{{res?.country_code}}</span>
      </li>
    </ul>
    <div class="d-flex justify-content-center">
     
      <button type="submit"  data-bs-toggle="modal" data-bs-target="#editUser" class="btn btn-danger me-2"(click)="load_editdata()">Edit Details</button>
      
    </div>
  </div>
</div>
</div>
<!-- /Customer-detail Card -->
 
</div>
<!--/ Customer Sidebar -->


<!-- Customer Content -->
<div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
<!-- Customer Pills -->
<ul class="nav nav-pills flex-column flex-md-row mb-4">
  <li class="nav-item"><button class="nav-link active" data-bs-toggle="tab" data-bs-target="#overview"><i class="bx bx-user me-1"></i>Overview</button></li>
  <li class="nav-item"><button class="nav-link" data-bs-toggle="tab" data-bs-target="#security"><i class="bx bx-user me-1"></i>Security</button></li>
  <li class="nav-item"><button class="nav-link" (click)="load_molini()" data-bs-toggle="tab" data-bs-target="#billing_adress"><i class="bx bx-user me-1"></i>Billing Address</button></li>
</ul>
<!--/ Customer Pills -->

<!-- / Customer cards -->
<div class="tab-content p-0 pe-md-6 ps-md-0 mb-4">

<div class="tab-pane fade show active" id="overview" role="tabpanel">
  <div class="row text-nowrap">
    <div class="col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="card-icon mb-3">
            <div class="avatar">
              <div class="avatar-initial rounded bg-label-primary"><i class="bx bx-dollar bx-sm"></i>
              </div>
            </div>
          </div>
          <div class="card-info">
            <h4 class="card-title mb-3">Account Balance</h4>
            <div class="d-flex align-items-end mb-1 gap-1">
              <h4 class="text-primary mb-0">$2345</h4>
              <p class="mb-0"> Credit Left</p>
            </div>
            <p class="text-muted mb-0 text-truncate">Account balance for next purchase</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <div class="card-icon mb-3">
            <div class="avatar">
              <div class="avatar-initial rounded bg-label-success"><i class="bx bx-gift bx-sm"></i>
              </div>
            </div>
          </div>
          <div class="card-info">
            <h4 class="card-title mb-3">Loyalty Program</h4>
            <span class="badge bg-label-success mb-1">Platinum member</span>
            <p class="text-muted mb-0">3000 points to next tier</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <div class="card-icon mb-3">
            <div class="avatar">
              <div class="avatar-initial rounded bg-label-warning"><i class="bx bx-star bx-sm"></i>
              </div>
            </div>
          </div>
          <div class="card-info">
            <h4 class="card-title mb-3">Wishlist</h4>
            <div class="d-flex align-items-end mb-1 gap-1">
              <h4 class="text-warning mb-0">15</h4>
              <p class="mb-0">Items in wishlist</p>
            </div>
            <p class="text-muted mb-0 text-truncate">Receive notification when items go on sale</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <div class="card-icon mb-3">
            <div class="avatar">
              <div class="avatar-initial rounded bg-label-info"><i class="bx bxs-discount bx-sm"></i>
              </div>
            </div>
          </div>
          <div class="card-info">
            <h4 class="card-title mb-3">Coupons</h4>
            <div class="d-flex align-items-end mb-1 gap-1">
              <h4 class="text-info mb-0">21</h4>
              <p class="mb-0">Coupons you win</p>
            </div>

            <p class="text-muted mb-0 text-truncate">Use coupon on next purchase</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="tab-pane fade show" id="security" role="tabpanel">
<div class="card mb-4">
      <h5 class="card-header">Change Password</h5>
      <div class="card-body">
        <form id="formChangePassword" method="GET" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">

          <div class="row">
            <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
              <label class="form-label" >New Password</label>
              <div class="input-group input-group-merge has-validation">
                <input class="form-control" type="text" name="newPassword" placeholder="············">
              </div>
            </div>

            <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
              <label class="form-label">Confirm New Password</label>
              <div class="input-group input-group-merge has-validation">
                <input class="form-control" type="text" name="confirmPassword"  placeholder="············">
              </div>
            </div>
            <div>
              <button type="submit" class="btn btn-primary me-2">Change Password</button>
            </div>
          </div>
        <input type="hidden"></form>
      </div>
    </div>
</div>


<div class="tab-pane fade show" id="billing_adress" role="tabpanel">
  <div class="card mb-4">

    <div *ngIf="result" class="alert alert-danger alert-dismissible" role="alert">
      <h6 class="alert-heading d-flex align-items-center mb-1">{{result}}</h6>
      <p class="mb-0"></p>
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
      </button>
    </div>

    
        <h5 class="card-header" >Portugal Office Only who need Bill: <span class="badge bg-label-success"> {{import_tax_id}} </span>  </h5>
       
    

     
        <div class="card-body">
          <div class="mb-3 col-12 col-sm-12">
          <form class="mb-3 col-12 col-sm-12" [formGroup]="search_form"> <div>
            <label class="mb-3 col-12 col-sm-12">
                <input type="search" formControlName="vat1" (keyup)="seachVAT()"
                    class="form-control" placeholder="Search Clients">
                  </label>
                </div>
              </form> 
            </div>                
              <div  class="list-group" *ngIf="search_bar">
                <ul class="gzVnrw jIeOgV AutoComplete__List">

                <div *ngFor="let item of clientlist">
                  <li class="htoDjs cLbWEz AutoComplete__ListItem  AutoComplete__ListItem " (click)="onClientData(item.vat)">
                    <div
                      style="display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #d9dee3;padding-bottom: 10px;">
                      <div>
                        <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Name: <span
                            style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item?.name}}</span> </div>
                        <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Email: <span
                            style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item?.email}}</span> </div>
                      </div>
                      <div>
                        <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Phone: <span
                            style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item?.phone}}</span> </div>
                        <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Vat: <span
                            style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item?.vat}}</span> </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div> 
          <form [formGroup]="moloni_form" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
  
            <div class="row">

              <div style="display:none" class="mb-3 col-12 col-sm-12 form-password-toggle fv-plugins-icon-container">
                <label class="form-label" >Customer id</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control"  type="text" formControlName="customer_id1" placeholder="Customer id">
                </div>
              </div>


              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label" >Name</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.name}}" type="text" formControlName="name1" placeholder="Name">
                </div>
              </div>
  
              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label">Email</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.email}}" type="text" formControlName="email1"  placeholder="Email">
                </div>
              </div>

              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label">Phone</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.phone}}" type="text" formControlName="phone"  placeholder="Phone">
                </div>
              </div>


              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label">Vat</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.vat}}" type="text" formControlName="vat1"  placeholder="Vat">
                </div>
              </div>


              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label">City</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.city}}" type="text" formControlName="city1"  placeholder="City">
                </div>
              </div>


              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label">Zip Code</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.zip_code}}"  type="text" formControlName="zip_code1"  placeholder="Zip Code">
                </div>
              </div>


              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label">Address</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.address}}"  type="text" formControlName="address1"  placeholder="Address">
                </div>
              </div>

              <input type="hidden"ngModel="{{molinires?.country_id}}" formControlName="country_id">
                
              <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                <label class="form-label">Country</label>
                <div class="input-group input-group-merge has-validation">
                  <input class="form-control" ngModel="{{molinires?.country?.name}}"  type="text" formControlName="country_name1"  placeholder="Country">
                </div>
              </div>


              <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-danger me-2" (click)="edit_moloni_data()">Update Details</button>
                
             <button type="submit"  data-bs-toggle="modal" data-bs-target="#createUser" class="btn btn-danger me-2">Create New Vat Client</button>
    
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>


</div>
</div>
<!--/ Customer Content -->
</div>



<!-- new create User Modal -->
<div class="modal fade" id="createUser" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-simple modal-edit-user">
  <div class="modal-content p-3 p-md-5">
  <div class="modal-body">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    <div class="text-center mb-4">
      <div *ngIf="result" class="alert alert-danger alert-dismissible" role="alert">
        {{result}}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
        </button>
      </div>   
      <h3>Create Vat Client Portugal</h3>
      
    </div>
    <form [formGroup]="create_form" id="editUserForm" class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework">
     
      <div class="col-12 col-md-6">
        <label class="form-label" for="modalEditTaxID">VAT NUMBER</label>
        <input type="text" id="modalEditTaxID" formControlName="vat" class="form-control modal-edit-tax-id" placeholder="123 456 7890">
      </div>

      <div class="col-12 col-md-6 fv-plugins-icon-container">
        <label class="form-label" for="modalEditUserFirstName">VAT Name</label>
        <input type="text" id="modalEditUserFirstName" formControlName="name" name="modalEditUserFirstName" class="form-control" placeholder="John">
      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
  
      <div class="col-12 col-md-12 fv-plugins-icon-container">
        <label class="form-label" for="modalEditUserLastName">Address</label>
        <input type="text" id="modalEditUserLastName" formControlName="address" name="modalEditUserLastName" class="form-control" placeholder="Doe">
      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
      
      <div class="col-12 fv-plugins-icon-container">
        <label class="form-label" for="modalEditUserName">City</label>
        <input type="text" id="modalEditUserName"  formControlName="city" name="modalEditUserName" class="form-control" placeholder="john.doe.007">
      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
      <div class="col-12 col-md-6">
        <label class="form-label" for="modalEditUserEmail">zip code</label>
        <input type="text" id="modalEditUserEmail"  formControlName="zip_code" name="modalEditUserEmail" class="form-control" placeholder="2660-212">
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label" for="modalEditUserStatus">Country</label>
            <select  name="modalEditUserStatus" formControlName="country" class="form-control" >      
          

                                             
                                    <option value ="1">Portugal</option>
                                    <option value ="33">Brasil</option>
                                    <option value ="8">Angola</option>
                                    <option value ="39">Cabo Verde</option>
                                    <option value ="142">Moçambique</option>
                                    <option value ="185">S. Tomé e Príncipe</option>
                                    <option value ="70">Espanha</option>
                                    <option value ="174">Reino Unido</option>
                                    <option value ="79">França</option>
                                    <option value ="6">Alemanha</option>
                                    <option value ="77">Finlândia</option>
                                    <option value ="71">Estados Unidos da América</option>
                                    <option value ="107">República da Irlanda</option>
                                    <option value ="2">Afeganistão</option>
                                    <option value ="3">Africa do Sul</option>
                                    <option value ="4">Alaska</option>
                                    <option value ="5">Albânia</option>
                                    <option value ="7">Andorra</option>
                                    <option value ="9">Anguilla</option>
                                    <option value ="10">Antígua e Barbuda</option>
                                    <option value ="11">Antilhas Holandesas</option>
                                    <option value ="12">Arábia Saudita</option>
                                    <option value ="13">Argélia</option>
                                    <option value ="14">Argentina</option>
                                    <option value ="15">Arménia</option>
                                    <option value ="16">Aruba</option>
                                    <option value ="17">Ascensão</option>
                                    <option value ="18">Austrália</option>
                                    <option value ="19">Áustria</option>
                                    <option value ="20">Azerbaijão</option>
                                    <option value ="21">Bahamas</option>
                                    <option value ="22">Bahrein</option>
                                    <option value ="23">Bangladesh</option>
                                    <option value ="24">Barbados</option>
                                    <option value ="25">Bélgica</option>
                                    <option value ="26">Belize</option>
                                    <option value ="27">Benin</option>
                                    <option value ="28">Bermudas</option>
                                    <option value ="29">Bielorrussia</option>
                                    <option value ="30">Bolívia</option>
                                    <option value ="31">Bósnia-Herzegovina</option>
                                    <option value ="32">Botswana</option>
                                    <option value ="34">Brunei</option>
                                    <option value ="35">Bulgária</option>
                                    <option value ="36">Burkina Faso</option>
                                    <option value ="37">Burundi</option>
                                    <option value ="38">Butão</option>
                                    <option value ="40">Camarões</option>
                                    <option value ="41">Camboja</option>
                                    <option value ="42">Canadá</option>
                                    <option value ="43">Carriacou</option>
                                    <option value ="44">Ilhas Caimão</option>
                                    <option value ="45">Cazaquistão</option>
                                    <option value ="46">Chade</option>
                                    <option value ="47">Chile</option>
                                    <option value ="48">China</option>
                                    <option value ="49">Chipre</option>
                                    <option value ="50">Colombia</option>
                                    <option value ="51">Comores</option>
                                    <option value ="52">Congo</option>
                                    <option value ="53">Ilhas Cook</option>
                                    <option value ="54">Coreia do Norte</option>
                                    <option value ="55">Coreia do Sul</option>
                                    <option value ="56">Costa do Marfim</option>
                                    <option value ="57">Costa Rica</option>
                                    <option value ="58">Croácia</option>
                                    <option value ="59">Cuba</option>
                                    <option value ="60">Djibouti</option>
                                    <option value ="61">Dinamarca</option>
                                    <option value ="62">Dominica</option>
                                    <option value ="63">El Salvador</option>
                                    <option value ="64">Egipto</option>
                                    <option value ="65">Emirados Árabes Unidos</option>
                                    <option value ="66">Equador</option>
                                    <option value ="67">Eritreia</option>
                                    <option value ="68">Eslováquia</option>
                                    <option value ="69">Eslovénia</option>
                                    <option value ="72">Estónia</option>
                                    <option value ="73">Etiópia</option>
                                    <option value ="74">Ilhas Feroe</option>
                                    <option value ="75">Fiji</option>
                                    <option value ="76">Filipinas</option>
                                    <option value ="78">Formosa</option>
                                    <option value ="80">Gabão</option>
                                    <option value ="81">Gâmbia</option>
                                    <option value ="82">Gana</option>
                                    <option value ="83">Geórgia</option>
                                    <option value ="84">Gibraltar</option>
                                    <option value ="85">Granada</option>
                                    <option value ="86">Grécia</option>
                                    <option value ="87">Gronelândia</option>
                                    <option value ="88">Guadalupe</option>
                                    <option value ="89">Guam</option>
                                    <option value ="90">Guatemala</option>
                                    <option value ="91">Guiana</option>
                                    <option value ="92">Guiana Francesa</option>
                                    <option value ="93">Guiné - Bissau</option>
                                    <option value ="94">Guiné-Conacri</option>
                                    <option value ="95">Guiné Equatorial</option>
                                    <option value ="96">Haiti</option>
                                    <option value ="97">Hawai</option>
                                    <option value ="98">Holanda</option>
                                    <option value ="99">Honduras</option>
                                    <option value ="100">Hong Kong</option>
                                    <option value ="101">Hungria</option>
                                    <option value ="102">Iémen</option>
                                    <option value ="103">India</option>
                                    <option value ="104">Indonésia</option>
                                    <option value ="105">Irão</option>
                                    <option value ="106">Iraque</option>
                                    <option value ="108">Irlanda do Norte</option>
                                    <option value ="109">Islândia</option>
                                    <option value ="110">Israel</option>
                                    <option value ="111">Itália</option>
                                    <option value ="112">Jamaica</option>
                                    <option value ="113">Japão</option>
                                    <option value ="114">Jordânia</option>
                                    <option value ="115">Koweit</option>
                                    <option value ="116">Laos</option>
                                    <option value ="117">Lesoto</option>
                                    <option value ="118">Letónia</option>
                                    <option value ="119">Líbano</option>
                                    <option value ="120">Libéria</option>
                                    <option value ="121">Líbia</option>
                                    <option value ="122">Liechtenstein</option>
                                    <option value ="123">Lituânia</option>
                                    <option value ="124">Luxemburgo</option>
                                    <option value ="125">Macau</option>
                                    <option value ="126">Macedónia</option>
                                    <option value ="127">Madagáscar</option>
                                    <option value ="128">Malásia</option>
                                    <option value ="129">Malawi</option>
                                    <option value ="130">Maldivas</option>
                                    <option value ="131">Mali</option>
                                    <option value ="132">Malta</option>
                                    <option value ="133">Malvinas</option>
                                    <option value ="134">Marrocos</option>
                                    <option value ="135">Ilhas Marshall</option>
                                    <option value ="136">Martinica</option>
                                    <option value ="137">Mauritânia</option>
                                    <option value ="138">Maurícias</option>
                                    <option value ="139">Mayotte</option>
                                    <option value ="140">México</option>
                                    <option value ="141">Micronésia</option>
                                    <option value ="143">Moldávia</option>
                                    <option value ="144">Mónaco</option>
                                    <option value ="145">Mongólia</option>
                                    <option value ="146">Montserrat</option>
                                    <option value ="147">Myanmar</option>
                                    <option value ="148">Namíbia</option>
                                    <option value ="149">Nauru</option>
                                    <option value ="150">Nepal</option>
                                    <option value ="151">Nicarágua</option>
                                    <option value ="152">Niger</option>
                                    <option value ="153">Nigéria</option>
                                    <option value ="154">Niue</option>
                                    <option value ="155">Norfolk</option>
                                    <option value ="156">Noruega</option>
                                    <option value ="157">Nova Caledónia</option>
                                    <option value ="158">Nova Zelândia</option>
                                    <option value ="159">Omã</option>
                                    <option value ="160">Palau</option>
                                    <option value ="161">Panamá</option>
                                    <option value ="162">Papua e Nova Guiné</option>
                                    <option value ="163">Paquistão</option>
                                    <option value ="164">Paraguai</option>
                                    <option value ="165">Peru</option>
                                    <option value ="166">Pitcairn</option>
                                    <option value ="167">Polinésia Francesa</option>
                                    <option value ="168">Polónia</option>
                                    <option value ="169">Porto Rico</option>
                                    <option value ="170">Qatar</option>
                                    <option value ="171">Quenia</option>
                                    <option value ="172">Quirguistão</option>
                                    <option value ="173">Quiribati</option>
                                    <option value ="175">Rep. Centro Africana</option>
                                    <option value ="176">República Checa</option>
                                    <option value ="177">Rep. Dem. Congo</option>
                                    <option value ="178">Rep.Dominicana</option>
                                    <option value ="179">Reunião</option>
                                    <option value ="180">Roménia</option>
                                    <option value ="181">Ruanda</option>
                                    <option value ="182">Rússia</option>
                                    <option value ="183">S. Helena</option>
                                    <option value ="184">S. Marino</option>
                                    <option value ="186">S. vicente e Grenadines</option>
                                    <option value ="189">Samoa Americana</option>
                                    <option value ="190">Samoa Ocidental</option>
                                    <option value ="191">Seychelles</option>
                                    <option value ="192">Senegal</option>
                                    <option value ="193">Serra Leoa</option>
                                    <option value ="194">Montenegro</option>
                                    <option value ="195">Servia</option>
                                    <option value ="196">Singapura</option>
                                    <option value ="197">Síria</option>
                                    <option value ="198">Somália</option>
                                    <option value ="199">Sri Lanka</option>
                                    <option value ="200">Suazilândia</option>
                                    <option value ="201">Sudão</option>
                                    <option value ="202">Suécia</option>
                                    <option value ="203">Suíça</option>
                                    <option value ="204">Suriname</option>
                                    <option value ="205">Tailândia</option>
                                    <option value ="206">Tajiquistão</option>
                                    <option value ="207">Tanzânia</option>
                                    <option value ="208">Timor Lorosae</option>
                                    <option value ="209">Trinidade e Tobago</option>
                                    <option value ="210">Togo</option>
                                    <option value ="211">Tokelau</option>
                                    <option value ="212">Tonga</option>
                                    <option value ="213">Tristão da Cunha</option>
                                    <option value ="214">Tunísia</option>
                                    <option value ="215">Turks e Caicos</option>
                                    <option value ="216">Turquemenistão</option>
                                    <option value ="217">Turquia</option>
                                    <option value ="218">Tuvalu</option>
                                    <option value ="219">Ucrânia</option>
                                    <option value ="220">Uganda</option>
                                    <option value ="221">Uruguai</option>
                                    <option value ="222">Uzbequistão</option>
                                    <option value ="223">Vanuatu</option>
                                    <option value ="224">Vaticano</option>
                                    <option value ="225">Venezuela</option>
                                    <option value ="226">Vietname</option>
                                    <option value ="229">Wallis e Futuna</option>
                                    <option value ="230">Zâmbia</option>
                                    <option value ="231">Zimbabwe</option>
                                    <option value ="232">Ilhas Virgens Britânicas</option>
                                    <option value ="233">Kosovo</option>
                                    <option value ="234">Palestina</option>
                                    <option value ="236">Jersey</option>
                                    <option value ="237">São Bartolomeu</option>
                                    <option value ="238">Ilha de Man</option>

        </select>
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label" for="modalEditTaxID">Phone</label>
        <input type="text" id="modalEditTaxID" formControlName="phone" class="form-control modal-edit-tax-id" placeholder="123 456 7890">
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label" for="modalEditUserPhone">E-mail</label>
        <div class="input-group input-group-merge">
          <input type="text" id="modalEditUserPhone" formControlName="email" class="form-control phone-number-mask" placeholder="">
        </div>
      </div>
      <div class="col-12 text-center">
        <button type="submit"   class="btn btn-danger me-2"(click)="create_client()">Create Client</button>
         
        <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
      </div>
   </form>
  </div>
  </div>
  </div>
  </div>
  <!--/ new create User Modal -->
<!-- Modal -->
<!-- Edit User Modal -->
<div class="modal fade" id="editUser" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-lg modal-simple modal-edit-user">
<div class="modal-content p-3 p-md-5">
<div class="modal-body">
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  <div class="text-center mb-4">
    <h3>Edit User Information</h3>
    <p>Updating user details will receive a privacy audit.</p>
  </div>
  <form [formGroup]="edit_form" id="editUserForm" class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework">
    
    <div class="col-12 col-md-12 fv-plugins-icon-container">
      <label class="form-label" for="modalEditUserFirstName">First Name</label>
      <input type="text" id="modalEditUserFirstName" ngModel="{{editformres?.name}}"  formControlName="name1" name="modalEditUserFirstName" class="form-control" placeholder="John">
    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
    
    <div class="col-12 fv-plugins-icon-container"> 
      <label class="form-label" for="modalEditUserName">Username</label>
      <input type="text" id="modalEditUserName" ngModel="{{editformres?.user_code}}" formControlName="user_code1" name="modalEditUserName" class="form-control" placeholder="john.doe.007">
    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
    <div class="col-12 col-md-6">
      <label class="form-label" for="modalEditUserEmail">Email</label>
      <input type="text" id="modalEditUserEmail" ngModel="{{editformres?.mail}}" formControlName="mail1" name="modalEditUserEmail" class="form-control" placeholder="example@domain.com">
    </div>
    <div class="col-12 col-md-6">
      <label class="form-label" for="modalEditUserStatus">Country</label>
          <select  name="modalEditUserStatus" formControlName="country_code1" class="form-control" ngModelOptions ="{standalone: false}" ngModel="{{editformres?.country_code}}" >
            
        <option [ngValue]="editformres?.country_code" Value="country_code1">{{editformres?.country_code}}</option>
           <option value="PT">Portugal</option>
        <option value="BE">Belgium</option>
        <option value="FR">France</option>
      </select>
    </div>
    <div class="col-12 col-md-6">
      <label class="form-label" for="modalEditTaxID">Tax ID</label>
      <input type="text" id="modalEditTaxID" ngModel="{{editformres?.tax_id}}" formControlName="tax_id1" class="form-control modal-edit-tax-id" placeholder="123 456 7890">
    </div>
    <div class="col-12 col-md-6">
      <label class="form-label" for="modalEditUserPhone">Phone Number</label>
      <div class="input-group input-group-merge">
        <input type="text" id="modalEditUserPhone" ngModel="{{editformres?.mobile}}" formControlName="mobile1" class="form-control phone-number-mask" placeholder="202 555 0111">
      </div>
    </div>
    <div class="col-12 text-center">
      <button type="submit" data-bs-dismiss="modal" class="btn btn-danger me-2"(click)="update_client()">Update Details</button>
       
      <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
    </div>
 </form>
</div>
</div>
</div>
</div>
<!--/ Edit User Modal -->

<!-- Add New Credit Card Modal -->
<div class="modal fade" id="upgradePlanModal" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered modal-simple modal-upgrade-plan">
<div class="modal-content p-3 p-md-5">
<div class="modal-body">
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  <div class="text-center mb-4">
    <h3>Upgrade Plan</h3>
    <p>Choose the best plan for user.</p>
  </div>
  <form id="upgradePlanForm" class="row g-3" onsubmit="return false">
    <div class="col-sm-9">
      <label class="form-label" for="choosePlan">Choose Plan</label>
      <select id="choosePlan" name="choosePlan" class="form-select" aria-label="Choose Plan">
        <option selected="">Choose Plan</option>
        <option value="standard">Standard - $99/month</option>
        <option value="exclusive">Exclusive - $249/month</option>
        <option value="Enterprise">Enterprise - $499/month</option>
      </select>
    </div>
    <div class="col-sm-3 d-flex align-items-end">
      <button type="submit" class="btn btn-primary">Upgrade</button>
    </div>
  </form>
</div>
<hr class="mx-md-n5 mx-n3">
<div class="modal-body">
  <h6 class="mb-0">User current plan is standard plan</h6>
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div class="d-flex justify-content-center me-2 mt-3">
      <sup class="h5 pricing-currency pt-1 mt-3 mb-0 me-1 text-primary">$</sup>
      <h1 class="display-3 mb-0 text-primary">99</h1>
      <sub class="h5 pricing-duration mt-auto mb-2">/month</sub>
    </div>
    <button class="btn btn-label-danger cancel-subscription mt-3">Cancel Subscription</button>
  </div>
</div>
</div>
</div>
</div>
<!--/ Add New Credit Card Modal -->

<!-- /Modal -->
    </div>
    <!-- / Content -->

    
  

    
    <div class="content-backdrop fade"></div>
  </div>