<div class ="container">
  <div class="alert alert-danger" *ngIf="text" role="alert">
    {{text}} 
 <button type="button" (click)="cleartext1(text)"class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
   
       
  </div>
  <div class="alert alert-alert alert-primary" *ngIf="text2" role="alert">  
    {{text2}}
    <button type="button" (click)="cleartext2(text)"class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  
  </div>
  <div class="alert alert-alert alert-primary" *ngIf="text3" role="alert">  
    {{text3}}
    <button type="button" (click)="cleartext3(text)"class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  
  </div>
  <div class="alert alert-dark  alert-primary" *ngIf="text4" role="alert">  
    {{text4}}
    <button type="button" (click)="cleartext4(text)" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  
  </div>
</div>


<div class="container-xxl flex-grow-1 container-p-y">
    <div class="card mb-4">
        <div class="card-widget-separator-wrapper">
            <div class="card-body card-widget-separator">
                <div class="row gy-4 gy-sm-1">
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">56</h3>
                                <p class="mb-0">Pending Payment</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-calendar bx-sm"></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none me-4" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">0</h3>
                                <p class="mb-0">Completed</p>
                            </div>
                            <div class="avatar me-lg-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-check-double bx-sm"></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
                            <div>
                                <h3 class="mb-2">124</h3>
                                <p class="mb-0">Clients</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-wallet bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="d-flex justify-content-between align-items-start">
                            <div>
                                <h3 class="mb-2">32</h3>
                                <p class="mb-0">Travel Agent</p>
                            </div>
                            <div class="avatar">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-error-alt bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Order List Table -->


    <div class="card">
        <div class="card-datatable table-responsive">
            <div class="card-header d-flex border-top rounded-0 flex-wrap">
                <div class="me-5 ms-n2 pe-5">
                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                        <label>
                            <input type="search" class="form-control"
                                placeholder="Search Product" aria-controls="DataTables_Table_0">
                            </label>
                        </div>
                        
                </div>
                
                <div class="d-flex justify-content-start justify-content-md-end align-items-baseline">
 <div class="dt-action-buttons d-flex align-items-start align-items-md-center justify-content-sm-center mb-3 mb-sm-0">
       <div class="dt-buttons d-flex flex-wrap"> 
    <a class="dt-button add-new btn btn-warning" routerLink="/AddOrder/null"> <span>
     <i class="bx bx-plus me-0 me-sm-1"></i>
     <span class="d-none d-sm-inline-block"> Add Order</span>
       </span></a>
 </div>
        </div>
                </div>
            </div>
           <table class="datatables-order table border-top dataTable no-footer dtr-column collapsed">
            
                  
                <thead>
                    <tr>
                 
                        <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" style="width: 18px;">
                            <input type="checkbox" class="form-check-input"></th>
                        <th class="sorting" style="width: 64px;">order</th>
                        <th class="sorting sorting_asc" style="width: 149px;">customers</th>
                        <th class="sorting" style="width: 149;">TKT</th>
                        <th class="sorting"  style="width: 121px;">Price</th>                       
                        <th class="sorting" style="width: 130px;">status</th>
                        <th class="sorting"  style="width: 121px;">payment</th>
                        <th class="sorting" style="width: 153px;">Date</th>
                        <th class="sorting"  style="width: 121px;">Country</th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="even" *ngFor="let user of rows">
                         
                        <td class="dt-checkboxes-cell" >
                            <div class="d-flex justify-content-sm-center align-items-sm-center">
                <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow">
   <a (click)="get_data(user)"data-bs-toggle="offcanvas" class="pointer" data-bs-target="#offcanvasEcommerceCustomerAdd">
                        <i class="bx bx-dots-vertical-rounded"></i></a>    </button> 
       
          <a [routerLink] = "['/updateorder/'+user.id+'/account']" class="text-primary fw-medium"> <i class="fab fa-angular fa-lg text-danger me-3"> </i> </a>     
        </div>    
       </td>  


       <td style="max-width:60px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">

        <div class=" text-danger fw-medium ">{{user.pnr}}
         
      </div> 
      
         <a [routerLink] = "['/Orderdetails/'+user.pnr+'/'+user.id+'/'+user.client_id+'/'+account+'/'+'null']" class="text-primary fw-medium">  
                              #{{user.id}}</a></td>
                       
                        <td style="max-width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <div class="d-flex justify-content-start align-items-center order-name text-nowrap">
                            
                             <div class="avatar-wrapper">
                                    <div class="avatar me-2">
                            <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" (click)="jpg_data(user)" data-bs-toggle="modal" data-bs-target="#image_model">
                        
                              <img src="../../assets/img/avatars/{{user.country_account}}.png" alt="Avatar" class="rounded-circle"> 
                                    </button>
                                             
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <h6 class="m-0">
                                      
                                  <a  class="text-body text-warning">
                                        {{user.client_id}}</a></h6>
                                        <div class="tooltip1">
                                    <small class="text-muted"tooltip="rr ">{{user.user_username}}</small>
                                    <span class="tooltiptext1">{{user.admin_id}}</span>
                                </div> </div>
                            </div>
                        </td>
                        <td style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <div class="d-flex justify-content-start align-items-center product-name">
                              <div class="tooltip1"> 
                                 
                                <div class="d-flex flex-column" >
                                  <span class="tooltiptext1">{{user.agent_pending}}</span></div>
                                    <h6 class="text-body text-nowrap mb-0">{{user.number}}
                                        </h6><small 
                                        class="textcolour text-muted text-truncate d-none d-sm-block">
                                      
                                        {{user.name}}</small>
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <a href="app-ecommerce-order-details.html">
                            <span class="fw-medium" >{{user.net}}-{{user.amount}} </span></a></td>
                        <td>
  <span [ngClass]="{'bg-label-warning': user.t_code === 'Pending', 
  'bg-label-success': user.t_code === 'TKTT',
  'bg-label-danger': user.t_code === 'Cancel',
  'bg-label-info': user.t_code === 'ok'}" 
  class="badge px-2" >{{user.t_code}}</span>
                        </td>
                        <td style="max-width: 130px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"> 
                            <div class="d-flex align-items-center text-nowrap">
                                <img src="../../assets/img/icons/payments/cash.png" alt="mastercard" class="me-2" width="16">
                                <span>{{user.bill}} {{user.mode}}</span>
                                
                            </div>
                            <span class="badge rounded bg-label-danger">{{user.iata}}</span>
                        </td>
                        <td class="sorting_1">
                          <div class="d-flex align-items-center text-nowrap">

                          <span class="text-nowrap">{{user.date}}</span></div> 
                          <div class="avatar-initial font_balance rounded bg-label-danger">
                            <img src="../../assets/img/icons/unicons/paypal.png" alt="User"
                                class="rounded width_balance " > {{user.balance}}

                        </div>
                          
                     
                      </td>
                        
                        <td class="sorting_1">

                          <span class="badge rounded bg-label-dark">  {{user.service}}</span>
                          <span class="badge rounded bg-label-primary">  {{user.country_account}}</span>
                      
                                    </td>
                        
                       

    
                    </tr>
                 
                    </tbody>
            </table>
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1"  id="offcanvasEcommerceCustomerAdd" aria-modal="true" role="dialog">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">Update Account</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="cleanmodal()"></button>
    </div>
    <div class="offcanvas-body mx-0 flex-grow-0">
      <form  [formGroup]="userForm" >
        <div class="ecommerce-customer-add-basic mb-3">         
          <div class="mb-3 fv-plugins-icon-container">
            <label class="form-label1" for="ecommerce-customer-add-name">Client ID*</label>                     
            <input ngModel="{{client_id}}" ngModelOptions ="{standalone: false}"formControlName="client_id" type="text" class="form-control">
            </div>  
         
            <div class="mb-3">
                <label class="form-label1" for="ecommerce-customer-add-state">Name</label>
                <input formControlName="tkt_name" type="text" ngModelOptions ="{standalone: false}" ngModel="{{tkt_name}}" class="form-control">
              </div>
                  <div class="mb-3">
                 <label class="form-label1" for="ecommerce-customer-add-address-2"> Payment remark</label>
                 <input formControlName="remarkss"  type="text" ngModelOptions ="{standalone: true}" ngModel="{{remarks}}" class="form-control">
               </div>
               
               
               <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Gds</label>
           <input formControlName="gds" ngModel="{{gds}}"   ngModelOptions ="{standalone: false}"
            type="number" id="ecommerce-customer-add-state" class="form-control" placeholder="">
  
         </div>
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Sale Amount</label>
           <input formControlName="saleamount" ngModel="{{saleamount}}" type="number" id="ecommerce-customer-add-post-code" class="form-control" name="saleamount">
         </div>
       </div>
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Recived Amount</label>
           <input formControlName="recived"  ngModel="{{recived}}"  type="number"  ngModelOptions ="{standalone: false}"
            class="form-control" placeholder="" aria-label="Southern tip" name="customerState">
         </div>
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Mode of Payment</label>  
                <select formControlName="modeofpayment" ngModelOptions ="{standalone: false}" class="form-control" ngModel="{{modeofpayment}}" >
                 
                    <option *ngFor="let sta of pay_method" [ngValue]="sta">{{sta}}</option>
                 </select>  
         </div>
       </div>
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Office Country</label>
           <select formControlName="country_accounts" class="form-control" ngModelOptions ="{standalone: false}" ngModel="{{country_accounts}}" >
            <option [ngValue]="country_accounts" Value="country_accounts">{{country_accounts}}</option>
                <option *ngFor="let sta of accounts"  [ngValue]="sta">{{sta}}</option>
              </select>  
  
         </div>
        
         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-state">Need Invocie*</label>
          <select formControlName="invoicedata" class="form-control"ngModelOptions ="{standalone: false}" ngModel="{{invoicedata}}" >
            <option [ngValue]="invoicedata" Value="invoicedata">{{invoicedata}}</option>
            <option *ngFor="let sta of invoices"  [ngValue]="sta">{{sta}}</option>
             </select>   
        </div>
       </div>
  
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Service</label>
           <select formControlName="service" class="form-control" ngModelOptions ="{standalone: false}"ngModel="{{service}}">
            <option [ngValue]="service" Value="service">{{service}}</option>          
             <option *ngFor="let sta of serviceItems" [ngValue]="sta">{{sta}}</option>
           </select>          
         </div>
         
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="commerce-customer-add-state">TAX ID</label>
           <input formControlName="tax_id" type="text" ngModel="{{tax_id}}"  class="form-control">
         </div>
       </div>
       
  
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">PNR No.</label>
           <input formControlName="pnrno" type="text "ngModel="{{pnrno}}" class="form-control">
         </div>
         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-town">IATA Code</label>
           
          <select formControlName="iata" class="form-control" ngModel="{{iata}}" >
            <option [ngValue]="iata" Value="iata">{{iata}}</option>
            <option *ngFor="let sta of iataslist"  [ngValue]="sta">{{sta}}</option>
             </select>                      
            </div>   
        
       </div>      

       <div class="row mb-3">        
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Ticket number</label>
           <input formControlName="tkt_number" type="text" ngModel="{{tkt_number}}" class="form-control" ngModelOptions ="{standalone: false}" >
         </div>

         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-post-code">Status</label>
          <select formControlName="status" class="form-control" ngModel="{{status}}" >
        <option [ngValue]="status" Value="status">{{status}}</option>
            <option *ngFor="let sta of statuslist"  [ngValue]="sta">{{sta}}</option>
          </select>              
        </div>  
       </div>         
     </div>        
     <div class="pt-3">
       <button (click)="update_accounts()" data-bs-dismiss="offcanvas" type="submit" class="btn btn-primary me-sm-3 me-1 data-submit">update</button>
       <button type=" " class="btn bg-label-danger" data-bs-dismiss="offcanvas" (click)="cleanmodal()">Discard</button>
     </div>     
      <input type="hidden" (click)="cleanmodal()"></form>
    </div>
  </div>



  <div class="modal animate__animated animate__flipInY" id="image_model" tabindex="-1" aria-modal="true" role="dialog" >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
           
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body px-0">
          <div class="row pl-3">
            <div *ngIf="loaderi"  class="loaderi"style="flex-end;margin: 40%;"></div>
            <div class="col-md-3 image_base">
                <div [innerHTML]="imageViewData"></div>

            </div>
             
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
   




<div *ngIf="loading" class="w-100 d-flex align-items-center justify-content-center"
style="position: ; left: 0; top: 0; bottom: 0; right: 0;  z-index: 99;">
<div class="loader">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
</div>
