
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from "@angular/platform-browser";

import { FormArray, FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import Swal from 'sweetalert2';
import { interval } from 'rxjs';


@Component({
  selector: 'app-pendingtask',
  templateUrl: './pendingtask.component.html',
  styleUrls: ['./pendingtask.component.scss']
})
export class PendingtaskComponent implements OnInit {
  rows:any
  userService: any;
  account: any;
  router: any;
   loading: boolean;
  admin_id: any;
  time: Date = new Date();
  localUserIds: any;
  localUserIdsbag: any;
  key: any;
  id: any;

  accounts=["Portugal","Belgium","France"];
  invoices=["Yes","No"];
  serviceItems = 
  ["amadeus",
  "BE_AMADEUS",
  "FR_AMADEUS",
  "REISSUE",  
  "BE_GAL_TKT",
  "FR_GAL_TKT",
  "GAL_TKT",
  "void",
  "repeat",
  "SPARK_PT_EK",
  "SPARK_BE_LH",
  "SPARK_BE_EK",
  "old_falta",
  "sdp",
  "refund",
  "ndc_BA",
  "ONLINETKT",
  "VISA",
  "others"
  ];
  pay_method = 
  ["Blank",
  'waiting',
  'BEL_Cash',
  'Bank_kbc',
  'Bank_caixa',
  'Bank_novo',
  'Bank_bcp',
  'Bank_bpi',
  'Ingenico',
  'bancontact',
  'visa_elec',
  'adjust',
  'bank',
  'Revoutl_Fr',];




  statuslist = 
  ['ok',
  "Pending",
  "CANX",
  'TKTT',
  'Refund',
  'Cancel'
  ];

  iataslist= ["64215502",'8208233','20290314','GTF','EVA','GETFLIGHT','other'];
  row_update: any; 
  country: any;
  pnrbar: any;
  client_id: any;
  gds: any;
  pnrno: any;
  tkt_number: any;
  tkt_name: any;
  status: any;
  saleamount: any;
  recived: any;
  modeofpayment: any;
  service: any;
  country_accounts: any;
  tax_id: any;
  remarks: any;
  iata: any;
  invoicedata: any;
  row_id: any;
  res1: any;
  res2: any;
  res3: any;
  res4: any;
  modelopen: boolean=false;
  userForm: FormGroup;
  custmer_ref: any;
  picture: any;
  imageFileBinary: string;
  imageViewData: any;
  loaderi: boolean;
  message: boolean;
  text: any;
  text2: any;
  text3: any;
  text4: any;
  subscription: any;
 


  constructor(private http:HttpClient,public formBuilder: FormBuilder,private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    
  ) {
    const source = interval(120000); // 120000 milliseconds = 2 minutes
    this.subscription = source.subscribe(() => {
      // Call your function here
      this.yourFunction();
    });
    this.userForm = this.fb.group({
      client_id: new FormControl('', Validators.required),
      tax_id: new FormControl('', Validators.required),
      vat_no: new FormControl('', Validators.required),
      tkt_name: new FormControl('', Validators.required),
      tkt_number: new FormControl('', Validators.required),
      country_accounts: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      service: new FormControl('', Validators.required),
      remarkss: new FormControl('', Validators.required),
      gds: new FormControl('', Validators.required),
      saleamount: new FormControl('', Validators.required),
      recived: new FormControl('', Validators.required),
      modeofpayment: new FormControl('', Validators.required),
      iata: new FormControl('', Validators.required),
      pnrno: new FormControl('', Validators.required),
      invoicedata: new FormControl('', Validators.required),
    });


    
   }
   cleanmodal() {
    this.client_id = ""
    this.gds = ""
    this.pnrno =""
    this.tkt_number = ""
    this.tkt_name = ""
    this.status = ""
    this.saleamount = ""
    this.recived = ""
    this.modeofpayment =""
    this.service = ""
    this.country_accounts = ""
    this.tax_id = ""
    this.remarks = ""
    this.iata = ""
    this.invoicedata = ""

   }  

   ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {

     
    
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
   // console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 



    this.loading = true;
    var localUserId: any;
    localUserId = JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')    
    this.localUserIds = localUserId?.detail.id    
    this.localUserIdsbag = localUserId?.detail.token
    let param_value =   localUserId?.detail.token;  
    this.http.get('https://www.kelvintravel.com/api_agent/agent/api_taskpending/?key='+param_value).subscribe((response: any) => {
    this.rows= response;
    this.account=1;
    this.loading = false;
//  console.log(this.account)
   });

}

 
   yourFunction(){
    this.loading = true;
    var localUserId: any;
    localUserId = JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')    
    this.localUserIds = localUserId?.detail.id    
    this.localUserIdsbag = localUserId?.detail.token
    let param_value =   localUserId?.detail.token;  
    this.http.get('https://www.kelvintravel.com/api_agent/agent/api_taskpending/?key='+param_value).subscribe((response: any) => {
    this.rows= response;
    this.account=1;
    this.loading = false;
//  console.log(this.account)
   });


}






jpg_data(user) {
  this.imageViewData = null;
  this.loaderi = true;
  this.custmer_ref = user.custmer_ref;
  const payload = { custmer_ref:this.custmer_ref,id:this.localUserIds};   
  this.http.post('https://www.kelvintravel.com/api_agent/flight/Api_base/',payload).subscribe((response: any) => {
   
    this.imageViewData = response;
    this.loaderi = false;
   });



}

get_data(user) {
   this.client_id = ""
 this.gds = ""
 this.pnrno =""
 this.tkt_number = ""
 this.tkt_name = ""
 this.status = ""
 this.saleamount = ""
 this.recived = ""
 this.modeofpayment =""
 this.service = ""
 this.country_accounts = ""
 this.tax_id = ""
 this.remarks = ""
 this.iata = ""
 this.invoicedata = ""
  ///send here line no for get data
 // console.log(this.status)
 this.pnrbar =0 ;
 this.country = 1;
const payload = {lineno:user.id,table:this.country};
this.row_id = user.id;
this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row', payload).subscribe((response: any) => {
 //console.log(this.pnrbar)
if (this.pnrbar ==0){
 
 this.client_id = response?.data?.client_id; 
 this.gds = response?.data?.gds; 
 this.pnrno = response?.data?.pnrno; 
 this.tkt_number = response?.data?.tkt_number; 
 this.tkt_name = response?.data?.tkt_name; 
 this.status = response?.data?.status;
 this.saleamount = response?.data?.saleamount; 
 this.recived = response?.data?.recived; 
 this.modeofpayment = response?.data?.modeofpayment; 
 this.service = response?.data?.service; 
 this.country_accounts = response?.data?.country_accounts; 
 this.tax_id = response?.data?.tax_id; 
 this.remarks = response.data?.remarks; 
 this.iata = response?.data?.iata; 
 this.invoicedata = response?.data?.invoice; 
 this.modelopen = true;
}


}
);
}

  cleartext1(text) {
    this.text = "";
  }
  cleartext2(text) {
    this.text2 = "";
  }
  cleartext3(text) {
    this.text3 = "";
  }
  cleartext4(text) {
    this.text4 = "";
  }


update_accounts(){
  this.country = 1;
  const payload = {
    client_id_get: this.userForm.value.client_id,
    country_accounts: this.userForm.value.country_accounts,
    gds: this.userForm.value.gds,
    iata: this.userForm.value.iata,
    modeofpayment: this.userForm.value.modeofpayment,
    pnrno: this.userForm.value.pnrno,
    recived: this.userForm.value.recived,
    remarks: this.userForm.value.remarkss,
    saleamount: this.userForm.value.saleamount,
    service: this.userForm.value.service,
    status: this.userForm.value.status,
    tax_id: this.userForm.value.tax_id,
    invoicedata: this.userForm.value.invoicedata,
    tkt_name: this.userForm.value.tkt_name,
    row_id:this.row_id,
    table:this.country,
    admin_id: this.id,
    tkt_number: this.userForm.value.tkt_number
  };
   
  
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row/update_data', payload).subscribe((response: any) => {
      if (response.status == 1){
      
     this.message = true;    
     this.res1 = response.error?.update;
     this.res2 = response.error?.update1;
     this.res3 = response.error?.update2;
     this.res4 = response.error?.update3;
     sessionStorage.setItem('message', JSON.stringify(response.error));
     //window.location.reload();
     this.loading = true;
     var localUserId: any;
     localUserId = JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')    
     this.localUserIds = localUserId?.detail.id    
     this.localUserIdsbag = localUserId?.detail.token
     let param_value =   localUserId?.detail.token;  

     this.http.get('https://www.kelvintravel.com/api_agent/agent/api_taskpending/?key='+param_value).subscribe((response: any) => {
     this.rows= response;
     this.account=1;
     this.loading = false;
     var message: any;
    message = JSON.parse(sessionStorage?.getItem('message') || '{}')      
    if(message?.update){
    this.message = true; 
    this.text = message?.update;
    this.text2 = message?.update1;
    this.text3 = message?.update2;
    this.text4 = message?.update3;
    sessionStorage.removeItem('message')
                      }  

    });

      } else {
        
       
          this.res1 = response.error;

      }

     },
     err => {
      alert("Ticket number can not blank ");
     });
  
      
}

editORDER(lineID){

}

}
