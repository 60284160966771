import { Component, OnInit ,ViewChild} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';

@Component({
  selector: 'app-updateorder',
  templateUrl: './updateorder.component.html',
  styleUrls: ['./updateorder.component.scss']
})
export class UpdateorderComponent implements OnInit {
  rowid: string;
  userID: string;
  localUserIds: any;
  localUserIdsbag: any;
  rows: any;
  account: number;
  rowsimage: any;
  rowsimage1: any;
  imageViewData: any;

  updateform: FormGroup;
  constructor(private http:HttpClient,private route:ActivatedRoute,private router:Router,) { 
    this.updateform = new FormGroup({
      text: new FormControl(),
    })
  }

  ngOnInit(): void {
    this.rowid = this.route.snapshot.paramMap.get('rowID');
    this.userID = this.route.snapshot.paramMap.get('userID');
    
      var localUserId: any;
      localUserId = JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')    
      this.localUserIds = localUserId?.detail.id        
      const payload = {lineno:this.rowid,table:1}; 
      this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row', payload).subscribe((response: any) => { 
      this.rowsimage = response?.data;
      this.rowsimage1 = this.rowsimage.img;
      this.account = 1;    
      this.yourFunction()
     });

    
       }

       update(){ 

            const payload = {id: this.localUserIds ,custmer_ref : this.rowsimage1,text : this.updateform.value.text};
    this.http.post('https://www.kelvintravel.com/api_agent/flight/Api_base/dd', payload).subscribe((response: any) => {
      console.log(response)
 
   });
       }


   yourFunction(){
    const payload = {custmer_ref : this.rowsimage1};
    this.http.post('https://www.kelvintravel.com/api_agent/flight/Api_base/', payload).subscribe((response: any) => {
    this.imageViewData = response;
    this.account=1;
 
   });


}

}
