import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, empty } from 'rxjs';
import { ActivatedRoute,Router } from '@angular/router';


@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})


export class AddOrderComponent implements OnInit {

  
  @ViewChild('editor') editor;

  draft_requests:any[]=[]
  SerachclientDeatilsFrom: FormGroup;
 paymethodFrom: FormGroup;
  mode_of_payment:any
  clientlist:any
  search_bar:boolean=false
  serviceItems = ["amadeus",
    'GAL_TKT',
    'BE_AMADEUS',
    'FR_AMADEUS',
    'REISSUE',
    'SPARK_PT_LH',
    'SPARK_BE_LH',
    'SPARK_PT_EK',
    'SPARK_BE_EK',
    'SPARK_FR_EK',
    'BE_GAL_TKT',
    'FR_GAL_TKT',
    'SPARK',
    'old_falta',
    'sdp',
    'refund',
    'ndc',
    'VISA',
    'others',    
    'ndc_BE',
    'others',
    'ONLINETKT'];

  modules: any = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent        
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }],
        ['link', 'image', 'video']
      ],
    },
  }
  

  multiBancoData: any;
  multiBancoDone: boolean = true;
  mulltiBanccoPaytmentsuccfullyinterval: any;

  invoices=["No Need","France","Belgium","Portugal"]
  accounts=["Portugal","Belgium","France"]
  client_code: any;
  client_email: any;
  client_phone_Id: any;
  client_use_id: any;
  client_name: any;
  demo: number;
  draft_pnr: any;
  draft_sale_value: any;
  draft_amount_received: any;
  draft_service: any;
  draft_description: any;
  draft_payment_method: any;
  draft_invoicecountry: any;
  draft_country_account: any;
  inviteLink: any;
 submitted = false;
 psa_id: any;
  key: any;
  id: any;
  router: any;
  sussess: boolean;

  constructor( private route:ActivatedRoute,public formBuilder: FormBuilder,
    private http: HttpClient,
    ) {
      this.SerachclientDeatilsFrom = this.formBuilder.group({

        client: new FormControl('', Validators.required),
        sale_value: new FormControl('', Validators.required),
        pnr: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        amount_received: new FormControl(''),
        service: new FormControl('', Validators.required),
        country_account: new FormControl('', Validators.required),
        invoicecountry: new FormControl('', Validators.required),

      });
      this.paymethodFrom = this.formBuilder.group({

        payment_method: new FormControl('', Validators.required),

      });
     }
     
     
  ngOnInit(): void {


     
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 


    this.psa_id = this.route.snapshot.paramMap.get('clientID');
    this.SerachclientDeatilsFrom.value.client=this.psa_id
    this.search_client()
    if (JSON.parse(localStorage?.getItem('draftREQ'))){
      this.draft_requests[0]=JSON.parse(localStorage?.getItem('draftREQ')|| '{}')
      this.draft_requests[0].slot='draftREQ'
    }

    if (JSON.parse(localStorage?.getItem('draftREQ2'))){
      this.draft_requests[1]=JSON.parse(localStorage?.getItem('draftREQ2')|| '{}')
      this.draft_requests[1].slot='draftREQ2'
    }

    if (JSON.parse(localStorage?.getItem('draftREQ3'))){
      this.draft_requests[2]=JSON.parse(localStorage?.getItem('draftREQ3')|| '{}')
      this.draft_requests[2].slot='draftREQ3'
    }

    if (JSON.parse(localStorage?.getItem('draftREQ4'))){
      this.draft_requests[3]=JSON.parse(localStorage?.getItem('draftREQ4')|| '{}')
      this.draft_requests[3].slot='draftREQ4'
    }

    if (JSON.parse(localStorage?.getItem('draftREQ5'))){
      this.draft_requests[4]=JSON.parse(localStorage?.getItem('draftREQ5')|| '{}')
      this.draft_requests[4].slot='draftREQ5'
    }
    
  }


  gernateqr_code() {
   
    let req = {
      'psa_id':this.client_use_id,
      'pnrno' :this.SerachclientDeatilsFrom.value.pnr,  
      'link' : 'https://www.kelvintravel.com/' + 'payment-by-link/' + this.client_use_id + '/' + this.SerachclientDeatilsFrom.value.pnr + '/' + this.SerachclientDeatilsFrom.value.amount_received,
      'amount' :this.SerachclientDeatilsFrom.value.amount_received,
      'mode' :'link',
      'admin_id':'1'
    }
    this.http.post('https://www.kelvintravel.com/api_agent/flight/api_paybylink/insert',req).subscribe((response: any) => {
      this.inviteLink='https://www.kelvintravel.com/'+'payment-by-link/'+ this.client_use_id+'/'+this.SerachclientDeatilsFrom.value.pnr+'/'+this.SerachclientDeatilsFrom.value.amount_received+'/' + response.key;

      
 });
    
  }


  gernateUrl() {
   
    let req = {
      'psa_id':this.client_use_id,
      'pnrno' :this.SerachclientDeatilsFrom.value.pnr,  
      'link' : 'https://www.kelvintravel.com/' + 'payment-by-link/' + this.client_use_id + '/' + this.SerachclientDeatilsFrom.value.pnr + '/' + this.SerachclientDeatilsFrom.value.amount_received,
      'amount' :this.SerachclientDeatilsFrom.value.amount_received,
      'mode' :'link',
      'admin_id':'1'
    }
    this.http.post('https://www.kelvintravel.com/api_agent/flight/api_paybylink/insert',req).subscribe((response: any) => {
      this.inviteLink='https://www.kelvintravel.com/'+'payment-by-link/'+ this.client_use_id+'/'+this.SerachclientDeatilsFrom.value.pnr+'/'+this.SerachclientDeatilsFrom.value.amount_received+'/' + response.key;

      
 });
    
  }

  api_mb(data): Observable<Object> {
    return this.http.post('https://www.kelvintravel.com/api_agent/flight/api_mb/backoffice', data);
  }
  multiBanco() {
    let reqData = {
      user_id: this.client_use_id,
      price: this.SerachclientDeatilsFrom.value.amount_received,
      pnr: this.SerachclientDeatilsFrom.value.pnr,  
      saleamount: this.SerachclientDeatilsFrom.value.sale_value,
      service: this.SerachclientDeatilsFrom.value.service,
      mode_Of_Payment: this.paymethodFrom.value.payment_method,
      adminId: '1'

    }

    this.api_mb(reqData).subscribe((res: any) => {
     // console.log(res)
      this.multiBancoData = res
      // if (res) {
      //   this.mulltiBanccoPaytmentsuccfully(this.multiBancoData);
      // }
    })

  }


  load_draft(ele) {
  let draftdate=JSON.parse(localStorage?.getItem(ele)|| '{}')


  
  //this.client_use_id=draftdate.userId
  this.draft_pnr=draftdate.pnr
  this.draft_sale_value=draftdate.price
  this.draft_amount_received=draftdate.saleamount
  this.draft_service=draftdate.service
  this.draft_description=draftdate.imageFrom
  this.draft_payment_method=draftdate.mode_Of_Payment
  this.draft_invoicecountry=draftdate.invoicecountry
  this.draft_country_account =draftdate.country_account

  this.search_client()
  }

  logChange($event) {
    console.log(this.editor);
    console.log($event);
  }


  onClientData(ele) {

    this.client_code = ele.code;
    this.client_name= ele.name;
    this.client_email = ele.email;
    this.client_phone_Id = ele.phone;
    this.client_use_id = ele.use_id;
    this.SerachclientDeatilsFrom.value.client=ele.email
    this.search_bar=false
  }

  search_client(){
    if (this.SerachclientDeatilsFrom.value.client.length > 2){ 
      this.search_bar=true
      this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_getclient?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
        this.clientlist=response
        
   });

  }
  else{
    this.search_bar=false
  }
}


get pnr() { return this.SerachclientDeatilsFrom.get('pnr'); }
get price() { return this.SerachclientDeatilsFrom.get('price'); }
get amount_received() { return this.SerachclientDeatilsFrom.get('amount_received'); }
get service() { return this.SerachclientDeatilsFrom.get('service'); }
get description() { return this.SerachclientDeatilsFrom.get('description'); }
get payment_method() { return this.paymethodFrom.get('payment_method'); }
get invoicecountry() { return this.SerachclientDeatilsFrom.get('invoicecountry'); }
get sale_value() { return this.SerachclientDeatilsFrom.get('sale_value'); }
get country_account() { return this.SerachclientDeatilsFrom.get('country_account'); }

  change_payment_method(mode){
    this.paymethodFrom.value.payment_method=mode
  }

  submit_as_draft(){
    const payload = {
      userId: this.client_use_id,
      pnr: this.SerachclientDeatilsFrom.value.pnr,
      price: this.SerachclientDeatilsFrom.value.sale_value,
      saleamount: this.SerachclientDeatilsFrom.value.amount_received,
      service: this.SerachclientDeatilsFrom.value.service,
      imageFrom: this.SerachclientDeatilsFrom.value.description,
      admin_id: '1',
      mode_Of_Payment: this.paymethodFrom.value.payment_method,
      invoicecountry: this.SerachclientDeatilsFrom.value.invoicecountry,
      country_account: this.SerachclientDeatilsFrom.value.country_account 
    };
    
    let string = JSON.stringify(payload);
    
    if (localStorage.getItem('draftREQ5')) {
      localStorage.setItem('draftREQ5', string);
    } else if (localStorage.getItem('draftREQ4')) {
      localStorage.setItem('draftREQ5', string);
    } else if (localStorage.getItem('draftREQ3')) {
      localStorage.setItem('draftREQ4', string);
    } else if (localStorage.getItem('draftREQ2')) {
      localStorage.setItem('draftREQ3', string);
    } else if (localStorage.getItem('draftREQ')) {
      localStorage.setItem('draftREQ2', string);
    } else {
      localStorage.setItem('draftREQ', string);
    }
    

}

  submit(){
 
const payload = {userId:this.client_use_id,
      pnr:this.SerachclientDeatilsFrom.value.pnr,
      saleamount:this.SerachclientDeatilsFrom.value.sale_value,
      amount_received:this.SerachclientDeatilsFrom.value.amount_received,
     service:this.SerachclientDeatilsFrom.value.service,
     imageFrom:this.SerachclientDeatilsFrom.value.description,
     admin_id:this.id ,
     mode_Of_Payment:this.paymethodFrom.value.payment_method,
     invoicecountry:this.SerachclientDeatilsFrom.value.invoicecountry,
    country_account:this.SerachclientDeatilsFrom.value.country_account };
    //console.log(response);
    //// add back data slso for first request
this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_createorder', payload).subscribe((response: any) => {
 if(response.status = 1) {
alert("Order Submited")

 }
   });
   this.SerachclientDeatilsFrom.reset()
   this.paymethodFrom.reset()
  }

  discard(){
  this.SerachclientDeatilsFrom.reset()
  this.paymethodFrom.reset()
} 
}
