<div class="container-xxl flex-grow-1 container-p-y">
    <div class="card mb-4">
        <div class="card-widget-separator-wrapper">
            <div class="card-body card-widget-separator">
                <div class="row gy-4 gy-sm-1" *ngFor="let accountss of account">
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">{{accountss.rcvpayment}}</h3>
                                <p class="mb-0">Payment Transfer</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-calendar bx-sm"></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none me-4" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 class="mb-2">{{accountss.issues}}</h3>
                                <p class="mb-0">Sold tkts</p>
                            </div>
                            <div class="avatar me-lg-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-check-double bx-sm"></i>
                                </span>
                            </div>
                        </div>
                        <hr class="d-none d-sm-block d-lg-none" />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div
                            class="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
                            <div>
                                <h3 class="mb-2">{{accountss.falta}}</h3>
                                <p class="mb-0">Difference</p>
                            </div>
                            <div class="avatar me-sm-4">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-wallet bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="d-flex justify-content-between align-items-start">
                            <div>
                                <h3 class="mb-2">{{accountss.paxbalance}}</h3>
                                <p class="mb-0">Balance</p>
                            </div>
                            <div class="avatar">
                                <span class="avatar-initial rounded bg-label-secondary">
                                    <i class="bx bx-error-alt bx-sm"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Order List Table -->

    <div class="card">
        <div class="card-datatable table-responsive">
            <div class="card-header d-flex border-top rounded-0 flex-wrap">
                <div class="me-5 ms-n2 ">
                    <form [formGroup]="SerachclientDeatilsFrom">  
                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                        <label>
                            <input (keyup)="search_client()" ngModel={{client_use_id}}
                type="text" ngModel="{{client_use_id}}"
                class="form-control"
                id="ecommerce-product-name"
                placeholder="Client id"
                formControlName="client"
                aria-label="Product title" />
                            </label>
                            <div class="list-group" *ngIf="search_bar">
                                 <div class="rinku">
                                        <ul class="textbox">                                
                                        <div *ngFor="let item of clientlist">
                                               <li class=" select textarea " (click)="onClientData(item)">
                                            <div>
                                              <div>
                                       <span class=" textarea">  {{item.name}}     </span>
                                       <span class="mb-0 w-px-100 text-warning">  {{item.email}} </span>
                                       <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.use_id}}</span>           
                                              </div>
                                             
                                            </div>
                                          </li> 
                                        </div>
                                      </ul>
                                    </div>
                               
                            
                            </div>


                        </div>
                    </form>   
                </div>


                <div class="me-5 ms-n2 onoff">
                    
                    <label class="switch m-auto pe-2">PT
                      <input type="checkbox" class="switch-input">
                      <span class="switch-toggle-slider">
                        <span class="switch-on"></span>
                        <span class="switch-off"></span>
                      </span>
                    </label>

                    <label class="switch m-auto pe-2">FR
                        <input type="checkbox" class="switch-input">
                        <span class="switch-toggle-slider">
                          <span class="switch-on"></span>
                          <span class="switch-off"></span>
                        </span>
                      </label>

                      <label class="switch m-auto pe-2">BE
                        <input type="checkbox" class="switch-input">
                        <span class="switch-toggle-slider">
                          <span class="switch-on"></span>
                          <span class="switch-off"></span>
                        </span>
                      </label>

                  </div>





                
                
                <div class="d-flex justify-content-start justify-content-md-end align-items-baseline">
 <div class="dt-action-buttons d-flex align-items-start align-items-md-center justify-content-sm-center mb-3 mb-sm-0">
                        
                        <div class="dt-buttons d-flex flex-wrap"> 
 <div class="input-group input-daterange" id="bs-datepicker-daterange" style="width: 50%;"> 
 <input type="text" id="dateRangePicker" placeholder="MM/DD/YYYY" class="form-control">
                                        <span class="input-group-text">to</span>
                                        <input type="text" placeholder="MM/DD/YYYY" class="form-control">
                                      </div>
                                    <button class="dt-button buttons-collection btn btn-label-secondary me-3" tabindex="0"
                                        aria-controls="DataTables_Table_0" type="button" aria-haspopup="dialog" aria-expanded="false"><span><i
                                                class="bx bx-export me-1"></i>Search</span>
                                    </button>

                                    <button 
                                class="dt-button buttons-collection dropdown-toggle btn btn-label-secondary me-3" tabindex="0"
                                aria-controls="DataTables_Table_0" type="button" aria-haspopup="dialog"
                                aria-expanded="false"><span><i class="bx bx-export me-1"></i>Export</span><span
                                    class="dt-down-arrow">▼</span></button> 

                                    
                                    
                                    <a class="dt-button add-new btn btn-primary" routerLink="/AddOrder">
                                        <span>
                                            <i class="bx bx-plus me-0 me-sm-1"></i>
                                            <span class="d-none d-sm-inline-block"> Add Order</span>
                                        </span>
                                    </a>

                                </div>
                    </div>
                </div>
            </div>
           <table class="datatables-order table border-top dataTable no-footer dtr-column collapsed">
            
                  
                <thead>
                    <tr>
                 
                        <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" style="width: 18px;">
                            <input type="checkbox" class="form-check-input"></th>
                        <th class="sorting" style="width: 64px;">order</th>
                   
                        <th class="sorting" style="width: 149;">TKT</th>
                        <th class="sorting"  style="width: 121px;">Price</th>      
                        <th class="sorting"  style="width: 121px;">sale</th>                    
                        <th class="sorting" style="width: 130px;">status</th>
                        <th class="sorting"  style="width: 121px;">payment</th>
                        <th class="sorting" style="width: 153px;">Date</th>
                        <th class="sorting"  style="width: 121px;">Country</th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="even" *ngFor="let user of rows">
                         
                        <td class="dt-checkboxes-cell" >
                            <div class="d-flex justify-content-sm-center align-items-sm-center">
                                <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                    <i class="bx bx-dots-vertical-rounded"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end m-0">
                                    <a href="app-ecommerce-order-details.html" class="dropdown-item">View</a>
                                    <a href="app-ecommerce-order-details.html" class="dropdown-item">Edit</a>
                                    <a href="javascript:0;" class="dropdown-item delete-record">Delete</a>
                                </div>
                            </div></td> 
                        <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                 <a [routerLink] = "['/Orderdetails/'+user.pnr+'/'+user.id+'/'+user.client_id+'/'+account+'/'+'null']" class="dropdown-item">  
                             
                            <span class="text-primary fw-medium" >#{{user.pnr}}</span></a></td>
                       
                        
                        <td style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <div class="d-flex justify-content-start align-items-center product-name">
                                <div class="avatar-wrapper">
                                    <div class="ravtar  avatar me-2 rounded-2 rbg-label-secondary"><img
                        src="https://www.khalsatravel.net/webroot/frontend/airline-images_new/{{user.IATA_code}}.png" alt="code" class="rounded-2"></div>
                                </div>
                                <div class="d-flex flex-column" >
                                    <h6 class="text-body text-nowrap mb-0">{{user.number}}
                                        </h6><small 
                                        class="text-muted text-truncate d-none d-sm-block">
                                      
                                        {{user.name}}</small>
                                </div>
                            </div>
                        </td>
                        <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <a href="app-ecommerce-order-details.html">
                            <span class="fw-medium" >{{user.net}}  </span></a></td>
                            <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                <a href="app-ecommerce-order-details.html">
                                <span class="fw-medium" > {{user.amount}} </span></a></td>
                                
                                <td>
                            <span [ngClass]="{'bg-label-warning': user.t_code === 'Pending', 'bg-label-success': user.t_code === 'TKTT','bg-label-info': user.t_code === 'ok'}" class="badge px-2" >{{user.t_code}}</span>
                        </td>
                        <td style="max-width: 130px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"> 
                            <div class="d-flex align-items-center text-nowrap">
                                <img src="../../assets/img/icons/payments/cash.png" alt="mastercard" class="me-2" width="16">
                                <span>{{user.bill}} {{user.mode}}</span>
                            </div>
                        </td>
                        <td class="sorting_1"><span class="text-nowrap">{{user.date}}</span></td>
                        
                        <td class="sorting_1">
                             <small class="text-success text-truncate d-none d-sm-block">
                            {{user.service}}</small>
                            <small 
                                        class="text-danger text-truncate d-none d-sm-block">
                                      
                                        {{user.country_account}}</small>
                                    </td>
                        
                       

    
                    </tr>
                 
                    </tbody>
            </table>
        </div>
    </div>
</div>

<div *ngIf="loading" class="w-100 d-flex align-items-center justify-content-center"
style="position: fixed; left: 0; top: 0; bottom: 0; right: 0;  z-index: 99;">
<div class="loader">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
</div>
