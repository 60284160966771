import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';

 import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
 interface CalendarEvent {
  date: Date;
  description: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  rows:any
  userService: any;
  account: number;
  router: any;
  SerachclientDeatilsFrom: FormGroup; 
  addkanban: FormGroup; 
  editkanban: FormGroup; 
  search_bar: boolean;
  clientlist: any;
  kanban: any;
  loading: boolean;
  datalist: any;
  clienttkt: any;
  search_bartkt: boolean;
  search_barFR: boolean;
  search_barBE: boolean;
  key: any;
  id: any;
  user: any
  currentDate: Date = new Date();
  selectedDay: number | null = null;
  selectedMonthName: string = '';
 
  taskadddate: string;
  imageViewData: null;
  loaderi: boolean;
  custmer_ref: any;
  localUserIds: any;
  client_id: string;
  gds: string;
  pnrno: string;
  tkt_number: string;
  tkt_name: string;
  status: string;
  saleamount: string;
  recived: string;
  modeofpayment: string;
  service: string;
  country_accounts: string;
  tax_id: string;
  remarks: string;
  iata: string;
  invoicedata: string;
  pnrbar: number;
  country: number;
  row_id: any;
  modelopen: boolean;
  userForm: FormGroup;
  idmessage:any;

  accounts=["Portugal","Belgium","France"];
  invoices=["Yes","No"];
  serviceItems = 
  ["amadeus",
  "BE_AMADEUS",
  "FR_AMADEUS",
  "REISSUE",  
  "BE_GAL_TKT",
  "FR_GAL_TKT",
  "GAL_TKT",
  "void",
  "repeat",
  "SPARK_PT_EK",
  "SPARK_BE_LH",
  "SPARK_BE_EK",
  "old_falta",
  "sdp",
  "refund",
  "ndc_BA",
  "ONLINETKT",
  "VISA",
  "others"
  ];
  pay_method = 
  ["Blank",
  'waiting',
  'BEL_Cash',
  'Bank_kbc',
  'Bank_caixa',
  'Bank_novo',
  'bcp bank',
  'Bank_bpi',
  'Ingenico',
  'bancontact',
  'visa_elec',
  'adjust',
  'Revoutl_Fr',];

  statuslist = 
  ["Pending","CANX",
  'TKTT',
  'Refund',
  'Cancel',
  'ok'];

  iataslist= ["64215502",'8208233','20290314','GTF','EVA','GETFLIGHT','other'];
  message: boolean;
  res1: any;
  res2: any;
  res3: any;
  res4: any;
  tbname: any;
  pnrcode: any;
  event: any;
  events: any;
  openmodel: boolean;
  data: any;
  date_Time: any;
  title: any;
  clientlist_pt: any;
  clientlist_fr: any;
  clientlist_be: any;
  clienttkt_pt: any;
  clienttkt_fr: any;
  clienttkt_be: any;
  search_bartkt_fr: boolean;
  search_bartkt_be: boolean;
  comment: any;

  constructor(public formBuilder: FormBuilder,private fb: FormBuilder,
    private http: HttpClient,) {
      this.SerachclientDeatilsFrom = this.formBuilder.group({
        client: ['', Validators.required],      
        pnr: ['', Validators.required],        
      });

      this.addkanban = this.formBuilder.group({
        title: ['', Validators.required],      
        comment: ['', Validators.required],        
        label: ['', Validators.required],        
        status: ['', Validators.required],        
        date: ['', Validators.required],        
      });

      this.editkanban = this.formBuilder.group({
        title: ['', Validators.required],      
        comment: ['', Validators.required],        
        label: ['', Validators.required],        
        status: ['', Validators.required],        
        date: ['', Validators.required],  
        id: ['' , Validators.required],      
      });



      this.userForm = this.fb.group({
        client_id: new FormControl('', Validators.required),
        tax_id: new FormControl('', Validators.required),
        vat_no: new FormControl('', Validators.required),
        tkt_name: new FormControl('', Validators.required),
        tkt_number: new FormControl('', Validators.required),
        country_accounts: new FormControl('', Validators.required),
        status: new FormControl('', Validators.required),
        service: new FormControl('', Validators.required),
        remarkss: new FormControl('', Validators.required),
        gds: new FormControl('', Validators.required),
        saleamount: new FormControl('', Validators.required),
        recived: new FormControl('', Validators.required),
        modeofpayment: new FormControl('', Validators.required),
        iata: new FormControl('', Validators.required),
        pnrno: new FormControl('', Validators.required),
        invoicedata: new FormControl('', Validators.required),
      });

      



   }
   getMonthDays(year: number, month: number): number[] {
    const numDays = new Date(year, month + 1, 0).getDate();
    this.currentDate.setMonth(this.currentDate.getMonth() );
    this.updateSelectedMonthName();
    return Array.from({ length: numDays }, (_, index) => index + 1);
  }

  getweekdat( ){
     
    
  }


  getDayClass(day: number): string {
    const dayOfWeek = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), day).getDay();
    const dayClasses = ['badge bg-label-primary', 'badge bg-label-secondary', 'badge bg-label-success', 'badge bg-label-danger', 'badge bg-label-warning', 'badge bg-label-info', 'badge bg-label-dark'];
    return dayClasses[dayOfWeek];
  }
  
  nextMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.updateSelectedMonthName();
  }

  prevMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.updateSelectedMonthName();
  }
  getFirstDayOfMonth(year: number, month: number): number {
    return new Date(year, month, 1).getDay(); // Get the day of the week (0 - 6) for the first day of the month
  }
  updateSelectedMonthName(): void {
    this.selectedMonthName = formatDate(this.currentDate, 'MMMM yyyy', 'en-US');
  }
  getDayName(day: number): string {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const firstDayOfMonth = this.getFirstDayOfMonth(this.currentDate.getFullYear(), this.currentDate.getMonth());
    const index = (day + firstDayOfMonth - 1) % 7; 
    return dayNames[index];
  }
  addEvent(day: number): void {
    this.selectedDay = day;
    const eventDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), day);
    const description = prompt('Enter event description:');
    if (description) {
      const newEvent = { id: this.events.length + 1, date: eventDate.toISOString(), description };
      this.events.push(newEvent);
      console.log('Event added successfully:', newEvent);
    }
  }

  edit() {}



  getEventsForDay(day: number): any[] {

   this.events

  const eventDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), day);
  return this.events.filter(event => {
    const eventDateObj = new Date(event.date_Time);
    return eventDateObj.getDate() === day &&
           eventDateObj.getMonth() === this.currentDate.getMonth() &&
           eventDateObj.getFullYear() === this.currentDate.getFullYear();
  });
}
  ngOnInit(): void {

//    console.log(formatDate(this.currentDate, 'MMMM yyyy', 'en-US'))

    
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
    this.localUserIds =user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 



  this.http.get('https://www.kelvintravel.com/api_agent/agent/api_kanban/get',).subscribe((response: any) => {
           // console.log(response)
  this.events = response
   });

  }


  get_data(user) {
    this.client_id = ""
  this.gds = ""
  this.pnrno =""
  this.tkt_number = ""
  this.tkt_name = ""
  this.status = ""
  this.saleamount = ""
  this.recived = ""
  this.modeofpayment =""
  this.service = ""
  this.country_accounts = ""
  this.tax_id = ""
  this.remarks = ""
  this.iata = ""
  this.invoicedata = ""
   ///send here line no for get data
  //console.log("fffff")
  this.pnrbar =0 ;
  this.country = this.tbname;
 const payload = {lineno:user.id,table:user.table};
 this.row_id = user.id;
 this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row', payload).subscribe((response: any) => {
  //console.log(this.pnrbar)
 if (this.pnrbar ==0){
  
  this.client_id = response?.data?.client_id; 
  this.gds = response?.data?.gds; 
  this.pnrno = response?.data?.pnrno; 
  this.tkt_number = response?.data?.tkt_number; 
  this.tkt_name = response?.data?.tkt_name; 
  this.status = response?.data?.status;
  this.saleamount = response?.data?.saleamount; 
  this.recived = response?.data?.recived; 
  this.modeofpayment = response?.data?.modeofpayment; 
  this.service = response?.data?.service; 
  this.country_accounts = response?.data?.country_accounts; 
  this.tax_id = response?.data?.tax_id; 
  this.remarks = response.data?.remarks; 
  this.iata = response?.data?.iata; 
  this.invoicedata = response?.data?.invoice; 
  this.modelopen = true;
 }
 
 
 }
 );
 }

  update_accounts(){
  this.country = this.tbname;
  const payload = {client_id: this.userForm.value.client_id,
    country_accounts: this.userForm.value.country_accounts,
    gds: this.userForm.value.gds,
    iata: this.userForm.value.iata,
    modeofpayment: this.userForm.value.modeofpayment,
    pnrno: this.userForm.value.pnrno,
    recived: this.userForm.value.recived,
    remarks: this.userForm.value.remarkss,
    saleamount: this.userForm.value.saleamount,
    service: this.userForm.value.service,
    status: this.userForm.value.status,
    tax_id: this.userForm.value.tax_id,
    invoicedata: this.userForm.value.invoicedata,
    tkt_name: this.userForm.value.tkt_name,
    row_id:this.row_id,
    table:this.country,
    tkt_number: this.userForm.value.tkt_number};
  
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row/update_data', payload).subscribe((response: any) => {
      if (response.status == 1){
      
     this.message = true;    
     this.res1 = response.error?.update;
     this.res2 = response.error?.update1;
     this.res3 = response.error?.update2;
     this.res4 = response.error?.update3;
     sessionStorage.setItem('message', JSON.stringify(response.error));
   //  window.location.reload();
     
    
       this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist?pnr='+this.pnrcode).subscribe((response: any) => {  
       this.search_bar=false
      // this.loading = false;
       this.datalist=response.detail;
       this.tbname=response.tbname
         
    });
    
   

   
      } else {
        
       
          this.res1 = response.error;

      }

     },
     err => {
      alert("Ticket number can not blank ");
     });
  
      
}

  jpg_data(user) {
    this.imageViewData = null;
    this.loaderi = true;
    this.custmer_ref = user.custmer_ref;
    const payload = { custmer_ref:this.custmer_ref,id:this.localUserIds};   
    this.http.post('https://www.kelvintravel.com/api_agent/flight/Api_base/',payload).subscribe((response: any) => {
     
      this.imageViewData = response;
      this.loaderi = false;
     });
  
  
  
  }
  
  search_client(){
    if (this.SerachclientDeatilsFrom.value.client.length > 2){ 
    
      this.http.get('https://www.kelvintravel.com/api_agent/flight/api_getrequestpnr?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
        this.clientlist_pt =response 
        if  (response){   
        this.search_bar=true  
      }   
   });
  }
  else{
    this.search_bar=false
  }
}
  onClientData(item){    
  this.pnrcode = item.pnr;
  // this.loading = true; 
     this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist?pnr='+item.pnr).subscribe((response: any) => {  
     this.search_bar=false
    // this.loading = false;
     this.datalist=response.detail;
     this.tbname=response.tbname
       
  });
  
 }



 search_clientFR(){
  if (this.SerachclientDeatilsFrom.value.client.length > 2){ 
   
    this.http.get('https://www.kelvintravel.com/api_agent/flight/api_getrequestpnr/indexfr?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
      this.clientlist_fr =response   
      if  (response){  
        this.search_barFR=true 
      }    
 });
}
else{
  this.search_barFR=false
}
}
onClientDataFR(item){    
// this.loading = true; 
   this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist/fr?pnr='+item.pnr).subscribe((response: any) => {  
   this.search_barFR=false
  // this.loading = false;
   this.datalist=response.detail;
   this.tbname=response.tbname;
});

}



search_clientBE(){
  if (this.SerachclientDeatilsFrom.value.client.length > 2){ 
    
    this.http.get('https://www.kelvintravel.com/api_agent/flight/api_getrequestpnr/indexbe?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
      this.clientlist_be = response 
      if  (response){ 
         this.search_barBE=true  
        }     
 });
}
else{
  this.search_barBE=false
}
}
onClientDataBE(item){    
// this.loading = true; 
   this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist/be?pnr='+item.pnr).subscribe((response: any) => {  
   this.search_barBE=false
  // this.loading = false;
   this.datalist=response.detail;
    
});

}

search_client_tktno_be(){
  if (this.SerachclientDeatilsFrom.value.client.length > 4){ 
 
    this.http.get('https://www.kelvintravel.com/api_agent/flight/api_getrequestdocno/indexbe/?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
    if  (response){ 
      this.search_bartkt_be =true
      this.clienttkt_be = response;   
    }else {       
      this.search_bartkt_be =false
    }    
      
 });
}
else{
  this.search_bartkt=false
}
}

search_client_tktno_fr(){
  if (this.SerachclientDeatilsFrom.value.client.length > 4){ 
 
    this.http.get('https://www.kelvintravel.com/api_agent/flight/api_getrequestdocno/indexfr/?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
    if  (response){ 
      this.search_bartkt_fr=true
      this.clienttkt_fr = response;   
    }else {       
      this.search_bartkt_fr=false
    }    
      
 });
}
else{
  this.search_bartkt=false
}
}
onClientDatatkt_fr(item){   

  // this.loading = true; 
     this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist/be/?docno='+item.tktno).subscribe((response: any) => {  
     this.search_bartkt_fr =false
    // this.loading = false;
     this.datalist=response.detail;
       
  });
  
 }


search_client_tktno(){
  if (this.SerachclientDeatilsFrom.value.client.length > 4){ 
 
    this.http.get('https://www.kelvintravel.com/api_agent/flight/api_getrequestdocno?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
    if  (response){ 
      this.search_bartkt=true
      this.clienttkt_pt = response;   
    }else {       
      this.search_bartkt=false
    }    
      
 });
}
else{
  this.search_bartkt=false
}
}
onClientDatatkt(item){   

  // this.loading = true; 
     this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist?docno='+item.tktno).subscribe((response: any) => {  
     this.search_bartkt=false
    // this.loading = false;
     this.datalist=response.detail;
       
  });
  
 }

 

add_in_kanban(){
  
  let payload = {formdata:this.addkanban.value,userId:this.id}
  this.http.post('https://www.kelvintravel.com/api_agent/agent/api_kanban',payload).subscribe((response: any) => {
  this.http.get('https://www.kelvintravel.com/api_agent/agent/api_kanban/get',).subscribe((response: any) => {
      // console.log(response)
  this.events = response
});
        });
}

edit_in_kanban(idmessage){
  
   this.openmodel = true;
    let param_value =  idmessage.id;
    this.http.get('https://www.kelvintravel.com/api_agent/agent/api_kanban/get_id/?key='+param_value).subscribe((response: any) => {
    this.data = response?.id;
    this.status = response?.status;
    this.title = response?.title;
    this.comment = response?.comment;
    this.date_Time = response?.date_Time;

  
        });
}


update_kanban(){
  //this.openmodel = false;

  let payload = {formdata:this.editkanban.value,userId:this.id}
  this.http.post('https://www.kelvintravel.com/api_agent/agent/api_kanban/get_update',payload).subscribe((response: any) => {
  


this.http.get('https://www.kelvintravel.com/api_agent/agent/api_kanban/get',).subscribe((response: any) => {
  // console.log(response)
this.events = response

//this.editkanban.reset();

});
});       
}



  
  
  

close_model(){
this.openmodel = false;
}

go_to_add(day: any){
  this.addkanban.reset(); 
  const eventDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), day);
  
  this.taskadddate=formatDate(eventDate, 'Y-MM-dd hh:mm:ss', 'en-US')
}


}
