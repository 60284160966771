<div class="container-xxl flex-grow-1 container-p-y">
  <div class="kanban-wrapper ps ps--active-y">




    <div class="container-xxl flex-grow-1 container-p-y">
     
      <div class="card app-calendar-wrapper">
        <div class="row g-0">
          <!-- Calendar Sidebar -->
           
          <!-- /Calendar Sidebar -->

          <div class="col app-calendar-content">
            <div class="card shadow-none border-0">
              <div class="card-body pb-0 rinkubottom">
                <!-- FullCalendar -->
                <div id="calendar" class="">
                  <div class=" ">
                    <div class="">
                      
                      <div class="">
                        
                        <div class ="row mb-3">
                        <div class="calander">
                        
                            <button type="button" (click)="prevMonth()" class="btn btn-label-primary">prevMonth</button>
                            <h2 class="fc-toolbar-title" id="fc-dom-1">{{selectedMonthName}}</h2>
                            <button type="button" (click)="nextMonth()" class="btn btn-label-primary">nextMonth</button>
                            </div>
                        </div>                     
                    </div>                     
                  </div>

                  <div class="fc-dayGridMonth-view fc-view fc-daygrid">
                  
                    <div class="days ">
                      <div class="fc-daygrid-day-frame fc-scrollgrid-sync-inner rinkuday"
                        *ngFor="let day of getMonthDays(currentDate.getFullYear(), currentDate.getMonth())">
                  
                        <div class="fc-daygrid-day-top">
                          <a [ngClass]="getDayClass(day)" title="Go to {{day}}  {{getDayName(day)}}" data-navlink="" tabindex="0"
                            id="fc-dom-4" class="fc-daygrid-day-number">
                            {{day}} {{getDayName(day)}}
                          </a>
                  
              <a (click)="go_to_add(day)" data-bs-toggle="offcanvas" data-bs-target="#addcanvas" aria-controls="addcanvas"
            style="float: right; cursor: pointer;" [ngClass]="getDayClass(day)" title="Go to {{day}}  {{getDayName(day)}}" data-navlink=""
                            tabindex="0" id="fc-dom-4" class="fc-daygrid-day-number">
                        
                             + 
                                
                          </a>
                  
                        </div>
                  
                  
                        <div class="fc-daygrid-day-events">
                          <ul>
                            <ng-container *ngFor="let event of getEventsForDay(day)">                  
                              <li (click)="edit_in_kanban(event)" data-bs-toggle="offcanvas" data-bs-target="#editcanvas" data-eid="in-progress-1">
                                <a >{{event.title}}</a>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                 
                  </div>
                </div>
              </div>
            </div>
            <div class="app-overlay"></div>
            <!-- FullCalendar Offcanvas -->
            <div class="offcanvas offcanvas-end event-sidebar" tabindex="-1" id="addEventSidebar"
              aria-labelledby="addEventSidebarLabel">
              <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title mb-2" id="addEventSidebarLabel">Add Event</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <form class="event-form pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="eventForm"
                  onsubmit="return false" novalidate="novalidate">
                  <div class="mb-3 fv-plugins-icon-container">
                    <label class="form-label" for="eventTitle">Title</label>
                    <input type="text" class="form-control" id="eventTitle" name="eventTitle" placeholder="Event Title">
                    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="eventLabel">Label</label>
                    <div class="position-relative"><select
                        class="select2 select-event-label form-select select2-hidden-accessible" id="eventLabel"
                        name="eventLabel" data-select2-id="eventLabel" tabindex="-1" aria-hidden="true">
                        <option data-label="primary" value="Business" selected="" data-select2-id="2">Business</option>
                        <option data-label="danger" value="Personal">Personal</option>
                        <option data-label="warning" value="Family">Family</option>
                        <option data-label="success" value="Holiday">Holiday</option>
                        <option data-label="info" value="ETC">ETC</option>
                      </select><span class="select2 select2-container select2-container--default" dir="ltr"
                        data-select2-id="1" style="width: 335px;"><span class="selection"><span
                            class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true"
                            aria-expanded="false" tabindex="0" aria-disabled="false"
                            aria-labelledby="select2-eventLabel-container"><span class="select2-selection__rendered"
                              id="select2-eventLabel-container" role="textbox" aria-readonly="true"
                              title="Business"><span class="badge badge-dot bg-primary me-2">
                              </span>Business</span><span class="select2-selection__arrow" role="presentation"><b
                                role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                          aria-hidden="true"></span></span></div>
                  </div>
                  <div class="mb-3 fv-plugins-icon-container">
                    <label class="form-label" for="eventStartDate">Start Date</label>
                    <input type="text" class="form-control flatpickr-input" id="eventStartDate" name="eventStartDate"
                      placeholder="Start Date" readonly="readonly">
                    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                    </div>
                  </div>
                  <div class="mb-3 fv-plugins-icon-container">
                    <label class="form-label" for="eventEndDate">End Date</label>
                    <input type="text" class="form-control flatpickr-input" id="eventEndDate" name="eventEndDate"
                      placeholder="End Date" readonly="readonly">
                    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="switch">
                      <input type="checkbox" class="switch-input allDay-switch">
                      <span class="switch-toggle-slider">
                        <span class="switch-on"></span>
                        <span class="switch-off"></span>
                      </span>
                      <span class="switch-label">All Day</span>
                    </label>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="eventURL">Event URL</label>
                    <input type="url" class="form-control" id="eventURL" name="eventURL"
                      placeholder="https://www.google.com">
                  </div>
                  <div class="mb-3 select2-primary">
                    <label class="form-label" for="eventGuests">Add Guests</label>
                    <div class="position-relative"><select
                        class="select2 select-event-guests form-select select2-hidden-accessible" id="eventGuests"
                        name="eventGuests" multiple="" data-select2-id="eventGuests" tabindex="-1" aria-hidden="true">
                        <option data-avatar="1.png" value="Jane Foster">Jane Foster</option>
                        <option data-avatar="3.png" value="Donna Frank">Donna Frank</option>
                        <option data-avatar="5.png" value="Gabrielle Robertson">Gabrielle Robertson</option>
                        <option data-avatar="7.png" value="Lori Spears">Lori Spears</option>
                        <option data-avatar="9.png" value="Sandy Vega">Sandy Vega</option>
                        <option data-avatar="11.png" value="Cheryl May">Cheryl May</option>
                      </select><span class="select2 select2-container select2-container--default" dir="ltr"
                        data-select2-id="3" style="width: 335px;"><span class="selection"><span
                            class="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true"
                            aria-expanded="false" tabindex="-1" aria-disabled="false">
                            <ul class="select2-selection__rendered">
                              <li class="select2-search select2-search--inline"><input class="select2-search__field"
                                  type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none"
                                  spellcheck="false" role="searchbox" aria-autocomplete="list"
                                  placeholder="Select value" style="width: 321px;"></li>
                            </ul>
                          </span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span></div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="eventLocation">Location</label>
                    <input type="text" class="form-control" id="eventLocation" name="eventLocation"
                      placeholder="Enter Location">
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="eventDescription">Description</label>
                    <textarea class="form-control" name="eventDescription" id="eventDescription"></textarea>
                  </div>
                  <div class="mb-3 d-flex justify-content-sm-between justify-content-start my-4">
                    <div>
                      <button type="submit" class="btn btn-primary btn-add-event me-sm-3 me-1">Add</button>
                      <button type="reset" class="btn btn-label-secondary btn-cancel me-sm-0 me-1"
                        data-bs-dismiss="offcanvas">Cancel</button>
                    </div>
                    <div><button class="btn btn-label-danger btn-delete-event d-none">Delete</button></div>
                  </div>
                  <input type="hidden">
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
 
    <div class="row">
      <div class="col-md-12 col-lg-4 mb-4">
        <div class="card">
          <div class="d-flex align-items-end row">
            <div class="col-8">
              <div class="card-body">
                <h6 class="card-title mb-1 text-nowrap">Seach with PNR PT</h6>
                <small class="d-block mb-3 text-nowrap">
                  <form [formGroup]="SerachclientDeatilsFrom">
                    <input (keyup)="search_client()" type="text" class="form-control  " placeholder="Client Name"
                      formControlName="client" aria-label="Product title" />
                  </form>
                  <div class="rinku" *ngIf="search_bar">
                    <ul class="textbox">

                      <div *ngFor="let item of clientlist_pt">
                        <li class=" select textarea " (click)="onClientData(item)">
                          <div>
                            <div>
                              <span class=" textarea"> {{item.name}} </span>
                              <span class="mb-0 w-px-100 text-warning"> {{item.tktno}} </span>
                              <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.pnr}}</span>
                            </div>

                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>


                </small>
                <small class="d-block mb-3 text-nowrap">
                  <h6 class="card-title mb-1 text-nowrap">Seach with Ticket Number</h6>
                  <form [formGroup]="SerachclientDeatilsFrom">
                    <input (keyup)="search_client_tktno()" type="text" class="form-control  " placeholder="Client Name"
                      formControlName="client" aria-label="Product title" />
                  </form>
                </small>
                <div class="rinku" *ngIf="search_bartkt">
                  <ul class="textbox">

                    <div *ngFor="let item of clienttkt_pt">
                      <li class=" select textarea " (click)="onClientDatatkt(item)">
                        <div>
                          <div>
                            <span class=" textarea"> {{item.name}} </span>
                            <span class="mb-0 w-px-100 text-warning"> {{item.tktno}} </span>
                            <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.pnr}}</span>
                          </div>

                        </div>
                      </li>
                    </div>
                  </ul>
                </div>





              </div>
            </div>

            <div class="col-4 pt-3 ps-0">
              <img src="../../assets/img/illustrations/prize-light.png" width="90" height="140" class="rounded-start"
                alt="View Sales">
            </div>
          </div>
        </div>
      </div>





      <div class="col-md-12 col-lg-4 mb-4">
        <div class="card">
          <div class="d-flex align-items-end row">
            <div class="col-8">
              <div class="card-body">
                <h6 class="card-title mb-1 text-nowrap">Seach with PNR FR</h6>
                <small class="d-block mb-3 text-nowrap">
                  <form [formGroup]="SerachclientDeatilsFrom">
                    <input (keyup)="search_clientFR()" type="text" class="form-control  " placeholder="Client Name"
                      formControlName="client" aria-label="Product title" />
                  </form>
                </small>
                <div class="rinku_fr" *ngIf="search_barFR">
                  <ul class="textbox">

                    <div *ngFor="let item of clientlist_fr">
                      <li class=" select textarea " (click)="onClientDataFR(item)">
                        <div>
                          <div>
                            <span class=" textarea"> {{item.name}} </span>
                            <span class="mb-0 w-px-100 text-warning"> {{item.tktno}} </span>
                            <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.pnr}}</span>
                          </div>

                        </div>
                      </li>
                    </div>
                  </ul>
                </div>

                <small class="d-block mb-3 text-nowrap">
                  <h6 class="card-title mb-1 text-nowrap">Seach with Ticket Number</h6>
                  <form [formGroup]="SerachclientDeatilsFrom">
                    <input (keyup)="search_client_tktno_fr()" type="text" class="form-control  " placeholder="Client Name"
                      formControlName="client" aria-label="Product title" />
                  </form>
                </small>
                <div class="rinku_fr" *ngIf="search_bartkt_fr">
                  <ul class="textbox">

                    <div *ngFor="let item of clienttkt_fr">
                      <li class=" select textarea " (click)="onClientDatatkt_fr(item)">
                        <div>
                          <div>
                            <span class=" textarea"> {{item.name}} </span>
                            <span class="mb-0 w-px-100 text-warning"> {{item.tktno}} </span>
                            <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.pnr}}</span>
                          </div>

                        </div>
                      </li>
                    </div>
                  </ul>
                </div>

              </div>
            </div>

            <div class="col-4 pt-3 ps-0">
              <img src="../../assets/img/illustrations/prize-light.png" width="90" height="140" class="rounded-start"
                alt="View Sales">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4 mb-4">
        <div class="card">
          <div class="d-flex align-items-end row">
            <div class="col-8">
              <div class="card-body">
                <h6 class="card-title mb-1 text-nowrap">Seach with PNR BE</h6>
                <small class="d-block mb-3 text-nowrap">
                  <form [formGroup]="SerachclientDeatilsFrom">
                    <input (keyup)="search_clientBE()" type="text" class="form-control  " placeholder="Client Name"
                      formControlName="client" aria-label="Product title" />
                  </form>
                </small>
                <div class="rinku_be" *ngIf="search_barBE">
                  <ul class="textbox">

                    <div *ngFor="let item of clientlist_be">
                      <li class=" select textarea " (click)="onClientDataBE(item)">
                        <div>
                          <div>
                            <span class=" textarea"> {{item.name}} </span>
                            <span class="mb-0 w-px-100 text-warning"> {{item.tktno}} </span>
                            <span class="mb-0 w-px-100 text-primary" text-capitalized="">{{item.pnr}}</span>
                          </div>

                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
                <small class="d-block mb-3 text-nowrap">
                  <h6 class="card-title mb-1 text-nowrap">Seach with Ticket Number</h6>
                  <form [formGroup]="SerachclientDeatilsFrom">
                    <input (keyup)="search_client_tktno_be()" type="text" class="form-control  " placeholder="Client Name"
                      formControlName="client" aria-label="Product title" />
                  </form>
                </small>
              </div>
            </div>

            <div class="col-4 pt-3 ps-0">
              <img src="../../assets/img/illustrations/prize-light.png" width="90" height="140" class="rounded-start"
                alt="View Sales">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card" *ngIf="datalist">
      <table class="datatables-order table border-top dataTable no-footer dtr-column collapsed">
        <thead>
          <tr>
            <th class="sorting" style="width: 64px;">#</th>
            <th class="sorting" style="width: 64px;">order</th>
            <th class="sorting sorting_asc" style="width: 149px;">customers</th>
            <th class="sorting" style="width: 149;">TKT</th>
            <th class="sorting" style="width: 121px;">Price</th>
            <th class="sorting" style="width: 130px;">status</th>
            <th class="sorting" style="width: 121px;">payment</th>
            <th class="sorting" style="width: 153px;">Date</th>
            <th class="sorting" style="width: 121px;">Country</th>
          </tr>
        </thead>
        <tbody>
          <tr class="even" *ngFor="let user of datalist">



            <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <div class="d-flex justify-content-start align-items-center mb-4">
                <span class="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center">
                  <i class="bx bx-cart-alt bx-sm lh-sm" data-bs-toggle="offcanvas"(click)="get_data(user) "data-bs-target="#offcanvasEcommerceCustomerAdd">
        
                  </i></span>
              
              </div>
            </td>
            <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">

             

                <span class="text-primary fw-medium">#{{user.pnr}}</span>
                <a [routerLink]="['/Orderdetails/'+user.pnr+'/'+user.id+'/'+user.client_id+'/'+account+'/'+'null']"
                class="dropdown-item">
                  <span class="text-warning fw-small">{{user.id}}</span></a>
            </td>
            <td style="max-width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <div class="d-flex justify-content-start align-items-center order-name text-nowrap">
                <div class="avatar-wrapper">
                  <div class="avatar me-2">
                    <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" (click)="jpg_data(user)" data-bs-toggle="modal" data-bs-target="#image_model">
                      <img src="../../assets/img/avatars/12.png" alt="Avatar" class="rounded-circle"></button>
                      

              
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <h6 class="m-0 "><a href="pages-profile-user.html" class="text-body">
                      {{user.client_id}}</a></h6>
                  <small class="text-muted">{{user.user_username}}</small>
                </div>
              </div>
            </td>

            <td style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <div class="d-flex justify-content-start align-items-center product-name">
                <div class="avatar-wrapper">
                  <div class="ravtar  avatar me-2 rounded-2 rbg-label-secondary"><img
                      src="https://www.khalsatravel.net/webroot/frontend/airline-images_new/{{user.IATA_code}}.png"
                      alt="code" class="rounded-2"></div>
                </div>
                <div class="d-flex flex-column">
                  <h6 class="text-body text-nowrap mb-0">{{user.number}}
                  </h6><small class="text-muted text-truncate d-none d-sm-block">

                    {{user.name}}</small>
                </div>
              </div>
            </td>
            <td style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><a
                href="app-ecommerce-order-details.html">
                <span class="fw-medium">{{user.net}}-{{user.amount}}</span></a></td>
            <td>
              <span
                [ngClass]="{'bg-label-info code': user.t_code === 'CANN','bg-label-danger': user.t_code === 'RFND','bg-label-warning': user.t_code === 'Pending', 'bg-label-success': user.t_code === 'TKTT','bg-label-info': user.t_code === 'ok'}"
                class="badge px-2">{{user.t_code}}</span>
            </td>
            <td style="max-width: 130px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <div class="d-flex align-items-center text-nowrap ">
                <img src="../../assets/img/icons/payments/cash.png" alt="mastercard" class="me-2" width="16">
                <span>{{user.bill}} {{user.mode}}</span>

              </div>
              <small class="text-warning text-truncate d-none d-sm-block">{{user.code}}</small>
            </td>
            <td class="sorting_1"><span class="text-nowrap">{{user.date}}</span><br>


              <div class="avatar-initial font_balance ">
                <img src="../../assets/img/icons/unicons/paypal.png" alt="User" class="rounded width_balance paypal">
                {{user.balance}}

              </div>
            </td>

            <td class="sorting_1">
              <small class="text-success text-truncate d-none d-sm-block">
                {{user.service}}</small>
              <small class="text-danger text-truncate d-none d-sm-block">

                {{user.country_account}}</small>
            </td>




          </tr>

        </tbody>
      </table>
    </div>
  </div>



  <div class="offcanvas offcanvas-end" tabindex="-1"  id="offcanvasEcommerceCustomerAdd" aria-modal="true" role="dialog">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">Update Account</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body mx-0 flex-grow-0">
      <form  [formGroup]="userForm" >
        <div class="ecommerce-customer-add-basic mb-3">         
          <div class="mb-3 fv-plugins-icon-container">
            <label class="form-label1" for="ecommerce-customer-add-name">Client ID*</label>                     
            <input ngModel="{{client_id}}" ngModelOptions ="{standalone: false}"formControlName="client_id" type="text" class="form-control">
            </div>  
         
            <div class="mb-3">
                <label class="form-label1" for="ecommerce-customer-add-state">Name</label>
                <input formControlName="tkt_name" type="text" ngModelOptions ="{standalone: false}" ngModel="{{tkt_name}}" class="form-control">
              </div>
                  <div class="mb-3">
                 <label class="form-label1" for="ecommerce-customer-add-address-2"> Payment remark</label>
                 <input formControlName="remarkss"  type="text" ngModelOptions ="{standalone: true}" ngModel="{{remarks}}" class="form-control">
               </div>
               
               
               <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Gds</label>
           <input formControlName="gds" ngModel="{{gds}}"   ngModelOptions ="{standalone: false}"
            type="number" id="ecommerce-customer-add-state" class="form-control" placeholder="">
  
         </div>
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Sale Amount</label>
           <input formControlName="saleamount" ngModel="{{saleamount}}" type="number" id="ecommerce-customer-add-post-code" class="form-control" name="saleamount">
         </div>
       </div>
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Recived Amount</label>
           <input formControlName="recived"  ngModel="{{recived}}"  type="number"  ngModelOptions ="{standalone: false}"
            class="form-control" placeholder="" aria-label="Southern tip" name="customerState">
         </div>
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Mode of Payment</label>  
                <select formControlName="modeofpayment" ngModelOptions ="{standalone: false}" class="form-control" ngModel="{{modeofpayment}}" >
                 
                    <option *ngFor="let sta of pay_method" [ngValue]="sta">{{sta}}</option>
                 </select>  
         </div>
       </div>
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Office Country</label>
           <select formControlName="country_accounts" class="form-control" ngModelOptions ="{standalone: false}" ngModel="{{country_accounts}}" >
            <option [ngValue]="country_accounts" Value="country_accounts">{{country_accounts}}</option>
                <option *ngFor="let sta of accounts"  [ngValue]="sta">{{sta}}</option>
              </select>  
  
         </div>
        
         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-state">Need Invocie*</label>
          <select formControlName="invoicedata" class="form-control"ngModelOptions ="{standalone: false}" ngModel="{{invoicedata}}" >
            <option [ngValue]="invoicedata" Value="invoicedata">{{invoicedata}}</option>
            <option *ngFor="let sta of invoices"  [ngValue]="sta">{{sta}}</option>
             </select>   
        </div>
       </div>
  
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">Service</label>
           <select formControlName="service" class="form-control" ngModelOptions ="{standalone: false}" ngModel="{{service}}" >
            <option [ngValue]="service" Value="service">{{service}}</option>
                <option *ngFor="let sta of serviceItems"  [ngValue]="sta">{{sta}}</option>
         
           </select>          
         </div>
         
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="commerce-customer-add-state">TAX ID</label>
           <input formControlName="tax_id" type="text" ngModel="{{tax_id}}"  class="form-control">
         </div>
       </div>
       
  
       <div class="row mb-3">
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-state">PNR No.</label>
           <input formControlName="pnrno" type="text "ngModel="{{pnrno}}" class="form-control">
         </div>
         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-town">IATA Code</label>
           
          <select formControlName="iata" class="form-control" ngModel="{{iata}}" >
            <option [ngValue]="iata" Value="iata">{{iata}}</option>
            <option *ngFor="let sta of iataslist"  [ngValue]="sta">{{sta}}</option>
             </select>                      
            </div>   
        
       </div>      

       <div class="row mb-3">        
         <div class="col-12 col-sm-6">
           <label class="form-label1" for="ecommerce-customer-add-post-code">Ticket number</label>
           <input formControlName="tkt_number" type="text" ngModel="{{tkt_number}}" class="form-control" ngModelOptions ="{standalone: false}" >
         </div>

         <div class="col-12 col-sm-6">
          <label class="form-label1" for="ecommerce-customer-add-post-code">Status</label>
          <select formControlName="status" class="form-control" ngModel="{{status}}" >
        <option [ngValue]="status" Value="status">{{status}}</option>
            <option *ngFor="let sta of statuslist"  [ngValue]="sta">{{sta}}</option>
          </select>              
        </div>  
       </div>         
     </div>        
     <div class="pt-3">
       <button (click)="update_accounts()" type="submit" class="btn btn-primary me-sm-3 me-1 data-submit">update</button>
       <button type=" " class="btn bg-label-danger" data-bs-dismiss="offcanvas" >Discard</button>
     </div>     
      <input type="hidden" ></form>
    </div>
  </div>


  <div class="modal animate__animated animate__flipInY" id="image_model" tabindex="-1" aria-modal="true" role="dialog" >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
           
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body px-0">
          <div class="row pl-3">
            <div *ngIf="loaderi"  class="loaderi"style="flex-end;margin: 40%;"></div>
            <div class="col-md-3 image_base">
                <div [innerHTML]="imageViewData"></div>

            </div>
             
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="w-100 d-flex align-items-center justify-content-center"
    style="position: ; left: 0; top: 0; bottom: 0; right: 0;  z-index: 99;">
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <div class="offcanvas offcanvas-end kanban-update-item-sidebar" abindex="-1" id="addcanvas" aria-modal="true"
  role="dialog">
  <div class="offcanvas-header border-bottom py-3 my-1">
    <h5 class="offcanvas-title">Add Task on {{taskadddate}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body pt-4">
    <div class="tab-content px-0 pb-0 border-0">
      <!-- Update item/tasks -->
      <div class="tab-pane fade show active">
        <form [formGroup]="addkanban">

          <input type="hidden" id="date" ngModel="{{taskadddate}}"  formControlName="date">

          <div class="mb-3">
            <label class="form-label" for="title">Title</label>
            <input formControlName="title" type="text" id="title" class="form-control" placeholder="Enter Title">
          </div>
          <div class="mb-3">
            <label class="form-label" for="label"> Status</label>
            <div class="position-relative">
              <select formControlName="status" class="select2 select2-label form-select select2-hidden-accessible"
                id="label" data-select2-id="label" tabindex="-1" aria-hidden="true">
               
                <option data-color="bg-label-warning" value="Pending">Pending</option>
              
              </select>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Comment</label>
            <div class="comment-editor border-bottom-0 ql-container ql-snow">
              <textarea formControlName="comment" class="form-control flatpickr-input ql-editor ql-blank"></textarea>
            </div>
            <div class="d-flex justify-content-end">
              
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-wrap">
              <button (click)="add_in_kanban()" type="button" class="btn btn-primary me-3"
                data-bs-dismiss="offcanvas">
                Save
              </button>
              <button type="button" class="btn btn-label-secondary" data-bs-dismiss="offcanvas">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>
  <div  class="offcanvas offcanvas-end kanban-update-item-sidebar" abindex="-1" id="editcanvas" aria-modal="true"
    role="dialog">
    <div class="offcanvas-header border-bottom py-3 my-1">
      <h5 class="offcanvas-title">Edit Task </h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body pt-4">       
      <div class="tab-content px-0 pb-0 border-0">
        <!-- Update item/tasks -->
        <div class="tab-pane fade show active" id="tab-update" role="tabpanel">
          <form [formGroup]="editkanban">
            <div class="mb-3">
              <label class="form-label" for="title">Due Date</label>
  <input ngModel="{{date_Time}}" ormControlName="date" ngModelOptions ="{standalone: false}"formControlName="date" type="text" class="form-control">
           </div>           
            <div class="mb-3">
              <label class="form-label" for="title">title</label>
  <input ngModel="{{title}}" ormControlName="date" ngModelOptions ="{standalone: false}"formControlName="title" type="text" class="form-control">
            </div>             
            <div class="mb-3">
              <label class="form-label" for="label"> Status</label>
              <div class="position-relative">           
            
              
              <select formControlName="status" class="form-control" ngModelOptions ="{standalone: false}" >
                <option   Value=" "> </option>
                <option   Value="Pending">Pending</option>
                <option   Value="Done">Done</option>  
                 
                  


                </select>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Comment</label>
              <div class="comment-editor border-bottom-0 ql-container ql-snow">
                <textarea formControlName="comment" ngModel="{{comment}}"class="form-control flatpickr-input ql-editor ql-blank"></textarea>
              </div>
              <div class="d-flex justify-content-end">                
              </div>
            </div>
            <input ngModel="{{data}}"formControlName="id" type="hidden"> 
            <div class="mb-3">
              <div class="d-flex flex-wrap">
                <button type="button" class="btn btn-primary me-3" data-bs-dismiss="offcanvas"
                  (click)="update_kanban()">
                  Save
                </button>
                <button type="button" class="btn btn-label-secondary" data-bs-dismiss="offcanvas">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- Activities -->      
      </div>
    </div>
  </div>


 

