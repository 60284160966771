<div class="content-wrapper">

    <!-- Content -->
    <div *ngIf="loading">
        <div class="loaderorder">
          
        </div>
        </div>
    <div class="container-xxl flex-grow-1 container-p-y">

       <!-- customers List Table -->
        <div class="card">

            <div class="card-datatable table-responsive">
                <div class="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div class="card-header d-flex flex-wrap py-3">
                        <div class="me-5 ms-n2">
                            <form [formGroup]="search_form"> <div>
                                <label>
                                    <input type="search" formControlName="id" (keyup)="search_client()"
                                        class="form-control" placeholder="Search Clients"></label></div></form>
                        </div>
                        <div
                            class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center
                             justify-content-md-end gap-3 gap-sm-2 flex-wrap flex-sm-nowrap pt-0">                           
                            <div class="dt-buttons btn-group flex-wrap d-flex">
                                <div class="btn-group">
                                <button
                                    class="btn btn-secondary add-new btn-danger" tabindex="0"
                                    aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasEcommerceCustomerAdd"><span><i
                                            class="bx bx-plus me-0 me-sm-1"></i><span
                                            class="d-none d-sm-inline-block">Add Customer</span></span></button>
                            </div>
                        </div>
                    </div>


                    
                    <table class="table border-top dataTable no-footer dtr-column"
                        id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info" style="width: 1390px;">
                        <thead>
                            <tr> 
                                <th style="width: 365px;">Customer</th>
                                <th style="width: 178px;">Customer Id</th>
                                <th style="width: 296px;">Country</th>
                                <th style="width: 103px;">vat</th>
                                <th style="width: 103px;">type</th>
                                 <th style="width: 170px;">Balance</th>
                            </tr>
                        </thead>
                        <tbody *ngIf ="displayone">
                            <tr class="odd" *ngFor="let user of rows">
                                                             <td class="sorting_1">
                                    <div class="d-flex justify-content-start align-items-center customer-name">
                                        <div class="avatar-wrapper">
                                            <div class="avatar me-2"><img src="../../assets/img/avatars/4.png"
                                                    alt="Avatar" class="rounded-circle"></div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <a  (click)="go_to_edit(user.id)"><span
                                                style="
                                                cursor: pointer;
                                            "class="text-danger fw-medium">{{user.name}}</span></a><small
                                                class="text-muted">{{user.mail}}</small></div>
                                    </div>
                                </td>
                                <td><span class="text-primary fw-medium">#{{user.id}}</span></td>
                                <td>
                                    <div class="d-flex justify-content-start align-items-center customer-country">
                                        <div><i class="fis fi fi-pt rounded-circle me-2 fs-3"></i></div>
                                        <div><span>{{user.country_code}}</span></div>
                                    </div>
                                </td> 
                                 <td><span>{{user.tax_number}}</span></td>
                                <td><span>{{user.user_type}}</span></td>
                                <td><span class="fw-medium text-heading">{{user.client_balance}}</span></td>
                            </tr>
 
                        </tbody>


                        <tbody *ngIf ="display">
                            <tr class="odd" *ngFor="let user of rows">
                                                             <td class="sorting_1">
                                    <div class="d-flex justify-content-start align-items-center customer-name">
                                        <div class="avatar-wrapper">
                                            <div class="avatar me-2"><img src="../../assets/img/avatars/4.png"
                                                    alt="Avatar" class="rounded-circle"></div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <a  (click)="go_to_edit(user.id)"><span
                                                style="
                                                cursor: pointer;
                                            "class="text-danger fw-medium">{{user.name}}</span></a><small
                                                class="text-muted">{{user.email}}</small></div>
                                    </div>
                                </td>
                                <td><span class="text-primary fw-medium">#{{user.use_id}}</span></td>
                                <td>
                                    <div class="d-flex justify-content-start align-items-center customer-country">
                                        <div><i class="fis fi fi-pt rounded-circle me-2 fs-3"></i></div>
                                        <div><span>{{user.phone}}</span></div>
                                    </div>
                                </td>
                                <td><span>{{user.user_type}}</span></td>
                                <td><span class="fw-medium text-heading">{{user.vat}}</span></td>
                            </tr>
 
                        </tbody>


                    </table>
                  
                    <div style="width: 1%;"></div>
                </div>
            </div>
            <!-- Offcanvas to add new customer -->
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEcommerceCustomerAdd"
                aria-labelledby="offcanvasEcommerceCustomerAddLabel">
                <div class="offcanvas-header">
                    <h5 id="offcanvasEcommerceCustomerAddLabel" class="offcanvas-title">Add Customer</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div class="offcanvas-body mx-0 flex-grow-0">
                    <form [formGroup]="add_client_form" class="ecommerce-customer-add pt-0 fv-plugins-bootstrap5 fv-plugins-framework"
                        id="eCommerceCustomerAddForm" onsubmit="return false" novalidate="novalidate">
                        <div class="ecommerce-customer-add-basic mb-3">
                            <h6 class="mb-3">Basic Information</h6>                            
                            <div class="mb-3 fv-plugins-icon-container">
                                <label class="form-label" for="ecommerce-customer-add-email">Email*</label>
                                <input type="text" id="ecommerce-customer-add-email" class="form-control"
                                    placeholder="john.doe@example.com" aria-label="john.doe@example.com"
                                    formControlName="email">
                               
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 col-sm-12">
                                    <label class="form-label" for="ecommerce-customer-add-state">Name </label>
                                    <input type="text" id="ecommerce-customer-add-state" class="form-control"
                                        placeholder="Southern tip" aria-label="Southern tip" formControlName="name">
                                </div>
                                <div class="col-12 col-sm-12">
                                    <label class="form-label" for="ecommerce-customer-add-post-code">Phone No</label>
                                    <input type="text" id="ecommerce-customer-add-post-code" class="form-control"
                                        placeholder="7349922220"  formControlName="phone">
                                </div>
                            </div> 
                        </div>

                        <div>
                            <label class="form-label" for="ecommerce-customer-add-country">Country</label>
                            <div class="position-relative">
                                <select id="ecommerce-customer-add-country" formControlName="country"
                                    class="select2 form-select select2-hidden-accessible"
                                    data-select2-id="ecommerce-customer-add-country" tabindex="-1"
                                    aria-hidden="true">
                                    <option value="" data-select2-id="2">Select</option>
                                    <option value="Pt">Portugal</option>
                                    <option value="Be">Belgium</option>
                                    <option value="Fr">France</option>
                                    <option value="De">Germany</option>                                    
                                   
                                </select></div>
                        </div>
 
                        
                        <div class="pt-3">
                            <button (click)="add_client()" type="submit" class="btn btn-primary me-sm-3 me-1 data-submit">Add</button>
                            <button type="reset" class="btn bg-label-danger"
                                data-bs-dismiss="offcanvas">Discard</button>
                        </div>
                        <input type="hidden">
                    </form>
                </div>
            </div>
        </div>


    </div>
    <!-- / Content -->




    <div class="content-backdrop fade"></div>
</div>