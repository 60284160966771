import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as _ from 'underscore';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.scss']
})
export class OrderdetailsComponent implements OnInit {
  id: any;
  clientIdPrint: any;
  country: any;
  pnr: any;
  userId: any;
  nameList: any;
  gdspnr: string;
  detailList: any;
  pricedataList: any;
  gdstotallList: any;
  airlinrssrcode: any;
  airlinrcode: any;
  detailList1: any;
  profile: any;
  qrcode: any;
  date: any;
  carddata: any;
  pricesArrayFrom: FormGroup;
  invoicecountry: FormGroup;
  localUserIds: any;
  tkppnr: any;
  hideShowResults: boolean;
  cHeckApiValueSelected: any;

  ssr: any;
  psa_id: any;
  rppnroffice: any;
  pnrtime: any;
  gds: any;
  saleamount: any;
  loadingValue: boolean;
  sessionId: any;
  aircode: any;
  linecodes: any;
  naame: any;
  searchPnrForm: FormGroup;
  grandtotal: any;
  row_update: FormGroup;
  repriceform: FormGroup;
  balance: any;
  email: any;
dataArray: any;
reprice_payload: any;
perPaxPriceForm: any = {
  perpaxprice: ''
};
  price_box_visible: boolean= false;
  officeid= 'PT';
  code: any

  ress:  any
  res: any;
  fares: any;
  bags: any;
  price: any;
  totall: any;
  tstinfo: any;
  account_id1: any;
  code1:  any;
  ress1: any
  res1: string;
  fares1: any;
  bags1: any;
  price1: any;
  totall1: any;
  tstinfo2: any;
  account_id2: any;
  code2:  any;
  ress2:  any
  res2: string;
  fares2: any;
  bags2: any;
  price2: any;
  totall2: any;
  tstinfo3: any;
  account_id3: any;
  countries: any;
  loading: boolean;
  status: any;
  user_id: any;
  saledata: any;
  pnropen_box_visible: boolean;
  table:  any;
  costdata:  any;
  updategds: any;
  updatecleint: any;
  accounts=["Portugal","Belgium","France"];
  invoices=["Yes","No"];
  serviceItems = 
  ["amadeus",
  "BE_AMADEUS",
  "FR_AMADEUS",
  "REISSUE",  
  "BE_GAL_TKT",
  "FR_GAL_TKT",
  "GAL_TKT",
  "void",
  "repeat",
  "SPARK_PT_EK",
  "SPARK_BE_LH",
  "SPARK_BE_EK",
  "old_falta",
  "sdp",
  "refund",
  "ndc_BA",
  "ONLINETKT",
  "VISA",
  "others"
  ];

  pay_method = 
  ["Blank",
  'waiting',
  'BEL_Cash',
  'Bank_kbc',
  'Bank_caixa',
  'Bank_novo',
  'Bank_bcp',
  'Bank_bpi',
  'Ingenico',
  'bancontact',
  'visa_elec',
  'adjust',
  'bank',
  'Revoutl_Fr',];


  statuslist = 
  ["Pending",
  'TKTT',
  'Refund',
  'Cancel',
  'ok'];

  iataslist= ["64215502",'8208233','20290314','GTF','EVA','GETFLIGHT','other'];
  previousUrl: any;

  amadeus_SequenceNumber2: any;
  amadeus_Token2: any;
  amadeus_id2: any;

  amadeus_SequenceNumber3: any;
  amadeus_Token3: any;
  amadeus_id3: any;

  amadeus_SequenceNumber1: any;
  amadeus_Token1: any;
  amadeus_id1: any;
  office_idd: any;
  office_idd2: any;
  office_idd3: any;
  client_id: any;
  gridline: boolean= false;
  saleamount1: any;
  saledata1: any;
  pnrbar: any;
  pnrno: any;
  tkt_number: any;
  tkt_name: any;
  recived: any;
  modeofpayment: any;
  service: any;
  tax_id: any;
  remarks: any;
  country_accounts: any;
  docnumber: any;
  key: any;
  localUserId: any;
  costdata1: any;
  iata: any;
  invoicedata: any;
  showmessage: boolean;
  message: boolean;
  res3: any;
  res4: any;
  ids: string;
  row_id: any;
  updated: boolean;
  errorcode: boolean;

  constructor(private route:ActivatedRoute,private router:Router,
    
    private http: HttpClient,public formBuilder: FormBuilder,private fb: FormBuilder,
    ) { 
    /* this.pricesArrayFrom = this.formBuilder.group({
        invoicecoutry: ['', Validators.required],
        pricesArray: this.formBuilder.array([]),
        toalldebit: ['', Validators.required],
        fop: ['', Validators.required],
      });*/

     this.pricesArrayFrom = new FormGroup({
        invoicecoutry: new FormControl(),
     pricesArray: this.formBuilder.array([]),
        toalldebit: new FormControl(),
        fop: new FormControl(),
      });

     /* this.row_update = this.formBuilder.group({
        client_id : ['', Validators.required],
        tax_id: ['', Validators.required],
        tkt_name: ['', Validators.required],
        tkt_number: ['', Validators.required],
        country_accounts: ['', Validators.required],
        status: ['', Validators.required],
        service: ['', Validators.required],
        remarks: ['', Validators.required],
        gds: ['', Validators.required],
        saleamount: ['', Validators.required],
        recived: ['', Validators.required],
        modeofpayment: ['', Validators.required],
        iata: ['', Validators.required],
        pnrno: ['', Validators.required],
        invoicedata: ['', Validators.required],
        
      });*/

      this.row_update = new FormGroup({
        client_id :new FormControl(),
        tax_id: new FormControl(),
        tkt_name: new FormControl(),
        tkt_number: new FormControl(),
        country_accounts: new FormControl(),
        status: new FormControl(),
        service: new FormControl(),
        remarks:new FormControl(),
        gds: new FormControl(),
        saleamount: new FormControl(),
        recived:new FormControl(),
        modeofpayment:new FormControl(),
        iata: new FormControl(),
        pnrno:new FormControl(),
        invoicedata: new FormControl(),
        
      });

      this.repriceform = new FormGroup({
        country: new FormControl(),
      })

    }

    get status_row_update() { return this.row_update.get('status'); }
    get pnrNUmber() { return this.searchPnrForm.get('pnrNUmber'); }
    get checkAPiValue() { return this.searchPnrForm.get('checkAPiValue'); }
    get toalldebit() { return this.pricesArrayFrom.get('toalldebit'); }
    get fop() { return this.pricesArrayFrom.get('fop'); }
    get invoicecoutry() { return this.pricesArrayFrom.get('invoicecoutry'); }

    
    get getFormData(): FormArray {
      return <FormArray>this.pricesArrayFrom.get('pricesArray');
    }
    remove(index: number) {
      const control = <FormArray>this.pricesArrayFrom.get('pricesArray');
      control.removeAt(index);
    }
    check() {
      console.log(this.repriceform.value.country)    
    }


  ngOnInit(): void {


      
    let users: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = users.detail.token;
    this.id = users.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    //console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 
    this.ids = this.route.snapshot.paramMap.get('id');
    this.clientIdPrint = this.route.snapshot.paramMap.get('clientId');
    this.country = this.route.snapshot.paramMap.get('country');
    this.pnr = this.route.snapshot.paramMap.get('pnr');
    this.countries = this.route.snapshot.paramMap.get('countries');
    //console.log(this.country)
    var localUserId: any = sessionStorage?.getItem('backend-flight-user') ? JSON.parse(sessionStorage?.getItem('backend-flight-user') || '{}') : JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')

    this.localUserId = localUserId;
   


    const payload = {lineno:this.clientIdPrint};
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row/get_accounts', payload).subscribe((response: any) => {
     
     this.code = response.detail;
     this.table = response?.table_names;
     this.costdata1 = response?.costdata;
     this.costdata = _.sortBy(this.costdata1, 'date').reverse(); //////code here sorting for mix array
   }
   );
 

 




   // console.log(this.id)
   let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))
   this.userId = 1;

   if (this.ids) {
    let payloadData = {
      userId: this.userId,
      pnr: this.ids,
      clientId: this.clientIdPrint,
      code:this.pnr,
      row:this.country,
      country:this.countries,

    }
    
    this.submitSearchBYAMA(payloadData)

   }



  }

  changeson(event: any, i: any, index: any) {
    const control = <FormArray>this.pricesArrayFrom.get('pricesArray');
    control.at(index).patchValue({
      per2paxpriceTotal: (i * Number(event.target.value))
    });

    let a = 0;

    control.value.forEach((element, index) => {
      if (element.Selected) {
        //console.log(a, "element.perpaxprice", element.per2paxpriceTotal);
        a = a + Number(element.per2paxpriceTotal)
        this.toalldebit.setValue(a)
        this.grandtotal=a
      }
    });


  }
  getSelectValued(event, index) {
    const control: any = <FormArray>this.pricesArrayFrom.get('pricesArray');
    if (event.target.checked === false) {
      control.at(index)?.patchValue({
        perpaxprice: Number(0)
      });
       control.at(index)?.patchValue({
        per2paxpriceTotal: Number(0)
      });
      control?.at(index).get('perpaxprice').disable();
    }else{
      control?.at(index).get('perpaxprice').enable();


     
    }
    
  }
  save_price(option){
   
    if (option =='option1' ){ 
      this.reprice_payload =
      {
        fares: this.fares, 
        bags: this.bags,
        price: this.price,
        totall: this.totall,
        tstinfo: this.tstinfo,
        account_id1: this.account_id1,
        office_idd: this.office_idd,
        
        amadeus_SequenceNumber: this.amadeus_SequenceNumber1,
        amadeus_Token: this.amadeus_Token1,
        amadeus_id: this.amadeus_id1,
      };
     }


     if (option =='option2' ){ 
      this.reprice_payload =
      {
        fares: this.fares1,
        bags: this.bags1,
        price: this.price1,
        totall: this.totall1,
        tstinfo: this.tstinfo2,
        account_id: this.account_id2,
        office_idd: this.office_idd2,
        amadeus_SequenceNumber: this.amadeus_SequenceNumber2,
        amadeus_Token: this.amadeus_Token2,
        amadeus_id: this.amadeus_id2,
      };
     }

     if (option =='option3' ){ 
      this.reprice_payload =
      {
        fares: this.fares2,
        bags: this.bags2,
        price: this.price2,
        totall: this.totall2,
        tstinfo: this.tstinfo3,
        account_id1: this.account_id3,
        office_idd: this.office_idd3,
        amadeus_SequenceNumber: this.amadeus_SequenceNumber3,
        amadeus_Token: this.amadeus_Token3,
        amadeus_id: this.amadeus_id3,
      };     
    }




  this.http.post('https://www.khalsatravel.net/api/flight/Api_price_pnr/api_new_save_price', this.reprice_payload).subscribe((response: any) => {
    console.log(response);
    this.code = response.data;
    //window.location.reload()
  }
  );

}



  balanceroute(user_id) {
    this.loading = true;
    this.router.navigate(['AddOrder/'+user_id])
    this.loading = true;


  }
  go_to_edit(user_id){
    this.loading = true;
    this.router.navigate(['ClientEdit/'+user_id])
    this.loading = true;


  }

  get_data() {
     ///send here line no for get data
    // console.log(this.status)
     
  const payload = {lineno:this.ids,table:this.country};
  this.pnrbar = 0;
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row', payload).subscribe((response: any) => {
    //console.log(this.pnrbar)
  if (this.pnrbar ==0){
    this.client_id = response?.data?.client_id; 
    this.gds = response?.data?.gds; 
    this.pnrno = response?.data?.pnrno; 
    this.tkt_number = response?.data?.tkt_number; 
    this.tkt_name = response?.data?.tkt_name; 
    this.status = response?.data?.status;
    this.saleamount = response?.data?.saleamount; 
    this.recived = response?.data?.recived; 
    this.modeofpayment = response?.data?.modeofpayment; 
    this.service = response?.data?.service; 
    this.country_accounts = response?.data?.country_accounts; 
    this.tax_id = response?.data?.tax_id; 
    this.remarks = response?.data?.remarks; 
    this.iata = response?.data?.iata; 
    this.row_id= response?.data?.id; 
    this.invoicedata = response?.data?.invoice; 
  }

  if (this.pnrbar ==1){
    this.client_id = response?.data?.client_id; 
    this.gds = response?.data?.gds; 
    this.pnrno = response?.data?.pnrno; 
    this.tkt_number = response?.data?.tkt_number; 
    this.tkt_name = response?.data?.tkt_name; 
    this.status = response?.data?.status;
    this.saleamount = response?.data?.saleamount; 
    this.recived = response?.data?.recived; 
    this.modeofpayment = response?.data?.modeofpayment; 
    this.service = response?.data?.service; 
    this.tax_id = response?.data?.tax_id; 
    this.remarks = response?.data?.remarks; 
    this.iata = response?.data?.iata; 
    this.invoicedata = response?.data?.invoice; 
    this.row_id = response?.data?.id; 
  }
}
  );
}

update_account(){
  ///send here line no for get data
  this.country
  const payload = {
    client_id_get: this.row_update.value.client_id,
    country_accounts: this.row_update.value.country_accounts,
    gds: this.row_update.value.gds,
    iata: this.row_update.value.iata,
    modeofpayment: this.row_update.value.modeofpayment,
    pnrno: this.row_update.value.pnrno,
    recived: this.row_update.value.recived,
    remarks: this.row_update.value.remarks,
    saleamount: this.row_update.value.saleamount,
    service: this.row_update.value.service,
    status: this.row_update.value.status,
    tax_id: this.row_update.value.tax_id,
    tkt_name: this.row_update.value.tkt_name,
    row_id:this.ids,
    table:this.country,
    admin_id: this.id,
    tkt_number: this.row_update.value.tkt_number
  };
 
  
this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row/update_data', payload).subscribe((response: any) => {
  if (response.status == 1){
    this.updated = true;
    this.get_data();
    
     } else {
      alert(response.error)
      this.errorcode = true;
    this.res1 = response.error;
     }

    },
    err => {
     alert("Ticket number can not blank ");
    });
 
 
//window.location.reload();

}
go_to_accounts(){ 
  this.router.navigate(['AgentBooking/'+ this.clientIdPrint])

}

  onSubmit() {
    const payload = { perpaxprice: this.perPaxPriceForm.perpaxprice };
    this.http.post<any>('your-api-endpoint', payload)
      .subscribe(
        response => {
        //  console.log('Post successful:', response);
        },
      );
  }

  submitSearchBYAMA(payloadData) {
    this.loading = true;
    this.nameList = [];
    this.gdspnr = ''
    this.detailList = []
    this.pricedataList = []
    this.gdstotallList = []

    // this.loading = true;
    const payloadapi = payloadData;
    this.http.post('https://www.kelvintravel.com/api_agent/flight/Api_pnrret', payloadapi).subscribe((res: any) => {
      this.loading = false;
    this.pnrbar =res.pnrbar
      if (res.pnrbar == 0){
        this.gridline=true;
        this.nameList = [];
        this.gdspnr = ''
        this.detailList = []
        this.pricedataList = []
        this.gdstotallList = []
        this.gdstotallList = res?.gdstotall;
        this.airlinrssrcode = res?.airlinrssrcode;
        this.airlinrcode = res?.airlinrcode;
        this.detailList1 = res?.detail1; 
        this.gdspnr = res?.gdspnr?.controlNumber;
        this.detailList = res?.detail;
        this.ssr = res?.ssr;
        this.docnumber = res?.name[0].docnumber;
    
      }   if (res.pnrbar == 1){ 
        this.gridline=false;
      }
        
       
        if (res.gds == 'amadeus') {
  
          if (res.pnropen == 1){
          this.pnropen_box_visible=false
        }
        if (res.pnropen == 0){
          this.pnropen_box_visible=true
        }
          this.res = res;
         
          this.profile = res?.profile;
          this.qrcode = res?.qrcode;
          this.user_id = res?.user_id; 
          this.balance = res?.balance;
          this.nameList = res?.name;
          
          this.date = res?.gdspnr?.date        
          this.carddata = res?.carddata?.card
          
          this.psa_id = res?.detailss.psa_id;
          this.rppnroffice = res?.rppnroffice;
          this.pnrtime = res?.pnrtime;
          this.gds = res?.gds;
          this.userId = this.userId;
          this.grandtotal = res?.saledata?.saleamount;
          this.date = res?.saledata?.date;
          this.saledata1 = res?.saledata;
          this.status = res?.saledata?.t_code;
          this.saleamount1 = res?.saleamount?.saleamount;
          this.loadingValue = false
          this.sessionId = res?.session;
          this.pricedataList = res?.pricedata;
          
          this.aircode = res?.aircode;
          this.linecodes = res?.linecodes;
          this.naame = res?.naame;
          
          this.gdstotallList?.forEach(element => {
            element.Selected = true;
          });
          
          this.sessionId = res?.session;
          this.getPriceData(this?.gdstotallList);
          this.toalldebit.setValue(res?.toalldebit);
          this.email = res?.email; 
         
        }
         
      
       

})
}
getPriceData(values: any[]) {
  const control = <FormArray>this.pricesArrayFrom.get('pricesArray');
  values?.forEach((pricess: any, index) => {
   //console.log(pricess.name, "text", pricess.test)
    const grp = this.formBuilder.group({
      Selected: [pricess.Selected, Validators.required],
      namaaae: [pricess.namaaae ? pricess.namaaae : pricess.lastname + pricess.paxname, Validators.required],
      passnumber: [pricess.passnumber ? pricess.passnumber : pricess.pricekey],
      perpaxprice: [pricess.perpaxprice , Validators.required],
      per2paxpriceTotal: [pricess.perpaxprice , Validators.required],
      ref_id_array: [pricess.ref_id_array ? pricess.ref_id_array : pricess.bagasss,],
      tstCreationDate: [pricess.tstCreationDate,],
      invoicecoutry: [pricess.invoicecoutry,],
      tstReferenceNumber: [pricess.tstReferenceNumber,],
      type: [pricess.type],
    });
    control.push(grp);
  });

}

success(res: any) {

  Swal.fire({
    toast: true,
    // position: 'top-end',
    icon: 'success',
    title: "Searching",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: 'swal-container',
    },
  });
}
error(err) {
  Swal.fire({
    toast: true,
    //    position: 'top-end',
    icon: 'warning',
    title: "Error",
    showConfirmButton: false,
    timer: 2000,
    customClass: {
      container: 'swal-container',
    },
  });

}


DataCheckSendBYAMA(payloadData) {
  this.loadingValue = true;
 
 this.http.post('https://www.kelvintravel.com/api_agent/flight/Api_robotkt11', payloadData).subscribe((res: any) => {
    if (res.status === "1") {
      this.success(res);
      this.loadingValue = false;
      Swal.fire({
        toast: true,
        //  position: 'top-end',
        icon: 'success',
        title: res.detail,
        showConfirmButton: false,
        timer: 8000,
        customClass: {
          container: 'swal-container',
        },
      });
      if (this.getFormData.value.length) {
        this.getFormData.value.forEach((index) => {
          this.remove(index)
        })
      }
      let payloadData = {
      
          userId: this.userId,
          pnr: this.ids,
          clientId: this.clientIdPrint,
          code:this.pnr,
          row:this.country,
       }
      this.submitSearchBYAMA(payloadData);
    } else if (res.status === "0") {

      this.loadingValue = false;

      alert(res.error);
      Swal.fire({
        toast: true,
        //  position: 'top-end',
        icon: 'warning',
        title: res.error,
        showConfirmButton: false,
        timer: 40000,
        customClass: {
          container: 'swal-container',
        },
      });
      location.reload();
    } else {

      Swal.fire({
        toast: true,
        //  position: 'top-end',
        icon: 'warning',
        title: "Sometthing is wrong Contact with Admin",
        showConfirmButton: false,
        timer: 40000,
        customClass: {
          container: 'swal-container',
        },
      });
      this.ChangesHideSHow()
    }

  }, (err) => {
    this.error(err);
  });
}
ChangesHideSHow() {
  this.hideShowResults = false;
  if (this.getFormData.value.length) {
    this.getFormData.value.forEach((index) => {
      this.remove(index)
    })
  }
  this.cHeckApiValueSelected = null;

  this.searchPnrForm.reset();
  this.pricesArrayFrom.reset();

}
submitSerachPnr() {
  let payloadData = {
    userId: this.userId,
    pnr: this.pnrNUmber.value,
    code:this.pnr,
    row:this.country,
  }
  //console.log("this.checkAPiValue.value", this.checkAPiValue.value)
  if (this.checkAPiValue.value == 'Galileo PNR') {
    this.submitSearchBYGAL(payloadData)
  } else if (this.checkAPiValue.value == 'Amadeus PNR') {
    this.submitSearchBYAMA(payloadData)
  }
  // else {
  //   Swal.fire({
  //     toast: true,
  //     position: 'top-end',
  //     icon: 'warning',
  //     title: "Something is wrong",
  //     showConfirmButton: false,
  //     timer: 20000,

  //   });
  // }
}

submitSearchBYGAL(payloadData) {
  this.nameList = [];
  this.gdspnr = ''
  this.detailList = []
  this.pricedataList = []
  this.gdstotallList = []
  //console.log("request", payloadData)
  this.http.post('https://www.kelvintravel.com/api_agent/flight/api_pnrget_gal', payloadData).subscribe((res: any) => {
    this.hideShowResults = true;
    this.nameList = res.name;
    this.gdspnr = res.gdspnr?.controlNumber
    this.detailList = res.detail;
    this.psa_id = res.detailss;
    this.pricedataList = res.pricedata;
    this.tkppnr = this.tkppnr;
    this.gdstotallList = res?.gdstotall
    this.gdstotallList.forEach(element => {
      element.Selected = true;
    });
    console.log("this.goo", this.gdstotallList);
    this.getPriceData(this?.gdstotallList);
    this.toalldebit.setValue(res?.toalldebit);
    this.success(res);
    this.email = res?.email; 
    this.userId = this?.userId;


  }, (err) => {
    this.error(err)
  });

}

PricePnrmultiple(pnr:any){
  this.loading = true;
  this.price_box_visible=true
  this.loadingValue = true
   
  const payload_api1 = {pnr:this.gdspnr, code:this.sessionId,id:this.userId,office:this.repriceform.value.country};
  this.http.post('https://www.khalsatravel.net/api/flight/Api_price_pnr/rpfares', payload_api1).subscribe((response_api1: any) => {
    this.loadingValue = false
    this.loading = false;
    if (response_api1.status =="0") {            
      this.code = response_api1?.code;
      this.ress = response_api1?.response;
  }  else if (response_api1[0].response === "Result" && response_api1[0].status == 1) {
     this.res = "ok";  
     this.fares = response_api1[0]?.fares;
     this.office_idd = response_api1[0]?.office_idd;
      this.bags = response_api1[0]?.bags;
      this.price = response_api1[0]?.price11;
      this.totall = response_api1[0]?.totall;
      this.tstinfo = response_api1[0]?.tstinfo;
      this.account_id1 = response_api1[0]?.account_id;
      
      this.amadeus_SequenceNumber1=response_api1[0]?.amadeus_SequenceNumber;
      this.amadeus_Token1=response_api1[0]?.amadeus_Token;
      this.amadeus_id1=response_api1[0]?.amadeus_id;
    
    }
  }); 

  this.loadingValue = true
  const payload_api2 = {pnr:this.gdspnr, code:this.sessionId,id:this.userId,office:this.repriceform.value.country};
  this.http.post('https://www.khalsatravel.net/api/flight/Api_price_pnr/rufares', payload_api2).subscribe((response_api2: any) => {
    this.loadingValue = false
    this.loading = false;
    if (response_api2.status =="0") {            
      this.code1 = response_api2?.code;
      this.ress1 = response_api2?.response;
  }  else if (response_api2[0]?.response === "Result" && response_api2[0].status == 1) {
     this.res1 = "ok";  
     this.fares1 = response_api2[0]?.fares;
     this.office_idd2 = response_api2[0]?.office_idd;
      this.bags1 = response_api2[0]?.bags;
      this.price1 = response_api2[0]?.price11;
      this.totall1 = response_api2[0]?.totall;
      this.tstinfo2 = response_api2[0]?.tstinfo;
      this.account_id2 = response_api2[0]?.account_id;

      this.amadeus_SequenceNumber2=response_api2[0]?.amadeus_SequenceNumber;
      this.amadeus_Token2=response_api2[0]?.amadeus_Token;
      this.amadeus_id2=response_api2[0]?.amadeus_id;

    }
  }); 


  const payload_api3 = {pnr:this.gdspnr, code:this.sessionId,id:this.userId,office:this.repriceform.value.country};
  this.http.post('https://www.khalsatravel.net/api/flight/Api_price_pnr/sbffares', payload_api3).subscribe((response_api3: any) => {
    this.loadingValue = false
    this.loading = false;
    if (response_api3.status =="0") {            
      this.code2 = response_api3?.code;
      this.ress2 = response_api3?.response;
  }  else if (response_api3[0].response === "Result" && response_api3[0].status == 1) {
     this.res2 = "ok";  
     this.fares2 = response_api3[0]?.fares;
     this.office_idd3 = response_api3[0]?.office_idd;
      this.bags2 = response_api3[0]?.bags;
      this.price2 = response_api3[0]?.price11;
      this.totall2 = response_api3[0]?.totall;
      this.tstinfo3 = response_api3[0]?.tstinfo;
      this.account_id3 = response_api3[0]?.account_id;

      this.amadeus_SequenceNumber3=response_api3[0]?.amadeus_SequenceNumber;
      this.amadeus_Token3=response_api3[0]?.amadeus_Token;
      this.amadeus_id3=response_api3[0]?.amadeus_id;
    }
  }); 
  }  
  
save() {
  alert('Error')
  this.loadingValue = true;
  const formArryValue: any = [];
  this.getFormData.value.forEach(element => {
    if (element.Selected) {
      formArryValue.push(element)
    }
  });
  var localUserId: any = sessionStorage?.getItem('backend-flight-user') ? JSON.parse(sessionStorage?.getItem('backend-flight-user') || '{}') : JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')
 // console.log("user", localUserId)
  this.userId = localUserId?.detail?.id;
  this.localUserIds = localUserId?.detail?.id;
  let payloadData = {
    userId: this.localUserIds,
    pnr: this.ids,
    formArryValue: formArryValue,
    clientId: this.clientIdPrint,
    sessionId: this.sessionId,
    fop: this.fop.value,
    code: this.tkppnr,
    toalldebit: this.toalldebit.value,


    country:this.repriceform.value.country

  };
  if (formArryValue.length >= 1) {
    this.loadingValue = true;
    this.DataCheckSendBYAMA(payloadData)
    this.submitSerachPnr()

  } else {
    this.loadingValue = false;
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'warning',
      title: "Please Select One checkbox",
      showConfirmButton: false,
      timer: 30000,
      customClass: {
        container: 'swal-container',
      },
    });

    alert('Error')
  }

}
   







}

