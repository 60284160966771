import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})

export class InvoicesComponent implements OnInit {
rows:any
angForm_invoice: FormGroup;
clientid:any
addinvoice= false
accounts: any;
isTableView: boolean;
userList: any;
loadingTableData: boolean;
collectionSize: any;
redicretlink: any;
clientsearch: FormGroup;
localUserIds: any;
localUserIdsbag: any;
invoicedata_list: any;
year:any
country_table:any  
fromDate: Date;
toDate: Date;
  table: any;
  code: any;
  router: any;
  key: any;
  id: any;


constructor(private route:ActivatedRoute,private http:HttpClient) { }

  ngOnInit(): void {

     
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 
    this.country_table = this.route.snapshot.paramMap.get('country');       
    const payload = { code:this.country_table }
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_bel_invocies/bills', payload)
      .subscribe((res: any) => {
        if (res.response == 'Result') {
          this.invoicedata_list = res.detail;
          this.rows = res.detail;
          this.table = this.country_table;
          this.year = res.year;
          this.code = res.code;
        } else {
        alert("No Data Found");
      }
      }) 
}



onFromDateChange(value: Date): void {
  this.fromDate = value;  
}
onToDateChange(value: Date): void {
  this.toDate = value;   
}
search_date() {
  if (this.country_table == "fr") {
    const payload = { fromDate: this.fromDate, toDate: this.toDate, vat: '',code:this.country_table }
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_bel_invocies/search_date', payload)
      .subscribe((res: any) => {
        if (res.response == 'Result') {
          this.invoicedata_list = res.detail;
          this.rows = res.detail;
          this.table = this.country_table;
          this.year = res.year;
        } else {
        alert("No Data Found");
      }
      })
  }
  if (this.country_table == "pt") {

    const payload = { fromDate: this.fromDate, toDate: this.toDate, vat: '' }
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_pt_invocies/search_date', payload)
      .subscribe((res: any) => {
        this.invoicedata_list = res.detail;
        this.rows = res.detail;
      })
  }

  if (this.country_table == "be") {

    const payload = { fromDate: this.fromDate, toDate: this.toDate, vat: '' }
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_bel_invocies/search_date', payload)
      .subscribe((res: any) => {
        this.invoicedata_list = res.detail;
        this.rows = res.detail;
      })
  }

console.log(this.redicretlink)
}

}
