import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';


@Component({
  selector: 'app-agent-booking',
  templateUrl: './agent-booking.component.html',
  styleUrls: ['./agent-booking.component.scss']
})
export class AgentBookingComponent implements OnInit {
  loading: boolean;
  rows:any
  router: any;
  clientlist: any;
  search_bar: boolean;
  SerachclientDeatilsFrom: FormGroup;
  client_use_id: any;
  fromDate: any;
  toDate: any;
  admin_id: any;
  account: any;
  searchedclientredirect: any;
  key: any;
  id: any;
  
  
  constructor(public formBuilder: FormBuilder,
    private http: HttpClient,private route:ActivatedRoute,
    ) {
      this.SerachclientDeatilsFrom = this.formBuilder.group({
        client: ['', Validators.required],
        });
     }
  ngOnInit(): void {

     
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 




    this.searchedclientredirect = this.route.snapshot.paramMap.get('id');
    
    const startdate = "1900-01-01";
    const start = formatDate(startdate, 'yyyy-MM-dd', 'en-US');
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    let param_value = this.searchedclientredirect  + '&' + 'fromDate=' + start +'&'+ 'toDate=' + cValue +'&'+'admin=' + this.admin_id 
    this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist?client_id='+param_value).subscribe((response: any) => {
    this.rows= response.detail;
    this.account= response.accounts;     
      
 });
    var localUserId: any = window.localStorage.getItem('backend-flight-user');
    localUserId = JSON.parse(localUserId);
    this.admin_id = localUserId?.detail.id;
  }


  search_client(){
    if (this.SerachclientDeatilsFrom.value.client.length > 2){ 
      this.search_bar=true
      this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_getclient?text='+this.SerachclientDeatilsFrom.value.client).subscribe((response: any) => {
        this.clientlist=response
        
   });

  }
  else{
    this.search_bar=false
  }
}


onClientData (ele) {
  this.client_use_id = ele.use_id;
  this.search_bar=false; 
  this.admin_id = 1;
  this.getaccount()
}


clientaccount(){
  this.getaccount()

}

getaccount (){
  const startdate = "1900-01-01";
  const start = formatDate(startdate, 'yyyy-MM-dd', 'en-US');
  const currentDate = new Date();
  const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  let param_value = this.client_use_id  + '&' + 'fromDate=' + start +'&'+ 'toDate=' + cValue +'&'+'admin=' + this.admin_id 

  //this.router.navigate(['/back-end/print-ticket-show/'+ row.pnr + '/' + row.id + '/' + row.client_id+ '/' + this.account])
  //https://www.kelvintravel.com/api_agent/agent/Api_mylist?client_id=1&fromDate=2024-2-25&toDate=2024-2-28&mode=null

 // 'link' : environment.apiUrl + 'payment-by-link/' + client_id + '/' + pnr + '/' + price,
  this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_mylist?client_id='+param_value).subscribe((response: any) => {
  this.rows= response.detail;
  this.account= response.accounts;
});


}
  
 





}
