import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  invoice: FormGroup;
  countries: number;
  type: any;
  name: any;
  image: any;

  constructor(public formBuilder: FormBuilder,private router:Router,private http:HttpClient) { 
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

this.invoice = this.formBuilder.group({
  pnr_value: new FormControl('', Validators.required),
  
  
});
  }



  ngOnInit(): void {
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))  
    this.type = user.detail?.type;
    this.name = user.detail?.name;
    this.image = user.detail?.image;

  }
  search_pnr() {
    this.countries = 0;
    
    
    this.router.navigate(['Orderdetails/'+this.invoice.value.pnr_value+'/'+'0'+'/'+'0'+'/'+'1'+'/'+this.countries])

  }


  pt_table(){
    this.router.navigate(['Accounts/pt'])

  }

  missing_table(){
    this.router.navigate(['Accounts/missing'])

  }
  fr_table(){
    this.router.navigate(['Accounts/fr'])

  }

  be_table(){
    this.router.navigate(['Accounts/be'])
  }

  pt_agent_table(){
    this.router.navigate(['Accounts/pt_agent'])

  }


  fr_agent_table(){
    this.router.navigate(['Accounts/fr_agent'])

  }

  be_agent_table(){
    this.router.navigate(['Accounts/be_agent'])
  }

  
  pt_invoice_table(){
    this.router.navigate(['Invoices/pt'])

  }

  fr_invoice_table(){
    this.router.navigate(['Invoices/fr'])

  }

  be_invoice_table(){
    this.router.navigate(['Invoices/be'])
  }


  signout(){
    localStorage.removeItem('backend-flight-user')
    localStorage.removeItem('isLoggedin')

    sessionStorage.removeItem('backend-flight-user')
    sessionStorage.removeItem('isLoggedin')
    this.router.navigate(['auth/Login'])
  }
}
