<div class="container-xxl flex-grow-1 container-p-y">


  



  <div class="card mb-4">
    <div class="card-widget-separator-wrapper">
      <div class="card-body card-widget-separator">
        <div class="row gy-4 gy-sm-1">
          <div class="col-sm-6 col-lg-3">
            <div class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
              <div>
                <h3 class="mb-1">24</h3>
                <p class="mb-0">Clients</p>
              </div> 
              <div class="avatar me-sm-4">
                <span class="avatar-initial rounded bg-label-secondary"> 
                  <i class="bx bx-user bx-sm"></i>
                </span>
              </div>
            </div>
            <hr class="d-none d-sm-block d-lg-none me-4" />
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
              <div>
                <h3 class="mb-1">165</h3>
                <p class="mb-0">Invoices</p>
              </div>
              <div class="avatar me-lg-4">
                <span class="avatar-initial rounded bg-label-secondary">
                  <i class="bx bx-file bx-sm"></i>
                </span>
              </div>
            </div>
            <hr class="d-none d-sm-block d-lg-none" />
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
              <div>
                <h3 class="mb-1">$2.46k</h3>
                <p class="mb-0">Paid</p>
              </div>
              <div class="avatar me-sm-4">
                <span class="avatar-initial rounded bg-label-secondary">
                  <i class="bx bx-check-double bx-sm"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="d-flex justify-content-between align-items-start">
              <div>
                <h3 class="mb-1">$876</h3>
                <p class="mb-0">Unpaid</p>
              </div>
              <div class="avatar">
                <span class="avatar-initial rounded bg-label-secondary">
                  <i class="bx bx-error-circle bx-sm"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Invoice List Table -->
  <div class="card">
    <div class="card-datatable table-responsive">
      <div class="card-header d-flex border-top rounded-0 flex-wrap">
        <div class="me-5 ms-n2 pe-5">
          <div id="DataTables_Table_0_filter" class="dataTables_filter">
            <label>
              <input type="search" class="form-control" placeholder="Search Client" aria-controls="DataTables_Table_0">
            </label>
          </div>

        </div>

        <div class="d-flex justify-content-start justify-content-md-end align-items-baseline">
          <div
            class="dt-action-buttons d-flex align-items-start align-items-md-center justify-content-sm-center mb-3 mb-sm-0">

            <div class="dt-buttons d-flex flex-wrap">


              <div class="input-group input-daterange" id="bs-datepicker-daterange" style="width: 45%;">
                <input type="date" id="dateRangePicker" placeholder="MM/DD/YYYY" class="form-control"
                  [ngModel]="fromDate" (ngModelChange)="onFromDateChange($event)">
                <span class="input-group-text">to</span>

                <input type="date" placeholder="MM/DD/YYYY" class="form-control" [ngModel]="toDate"
                  (ngModelChange)="onToDateChange($event)">
              </div>
              <button (click)="search_date()" class="dt-button buttons-collection btn btn-label-secondary me-3"
                tabindex="0" aria-controls="DataTables_Table_0" type="button" aria-haspopup="dialog"
                aria-expanded="false"><span><i class="bx bx-export me-1"></i>Search</span>
              </button>




              
              <button class="dt-button buttons-collection dropdown-toggle btn btn-label-secondary me-3" tabindex="0"
                aria-controls="DataTables_Table_0" type="button" aria-haspopup="dialog" aria-expanded="false"><span><i
                    class="bx bx-export me-1"></i>Export</span><span class="dt-down-arrow">▼</span></button>



              <a class="dt-button add-new btn btn-primary" routerLink="/AddOrder">
                <span>
                  <i class="bx bx-plus me-0 me-sm-1"></i>
                  <span class="d-none d-sm-inline-block"> New Invoice</span>
                </span>
              </a>

            </div>
          </div>
        </div>
      </div>
      <table class="invoice-list-table table border-top">
        <thead>
          <tr style="text-align: center;">
            <th>ID</th>
            <th>Client Id</th>
            <th>PNR</th>
            <th>Amount</th>
            <th class="text-truncate">Invoice No.</th>
            <th class="text-truncate">Issued Date</th>
            <th>Invoice Status</th>
            <th class="cell-fit">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr style="text-align: center;" *ngFor="let user of invoicedata_list" class="odd">
            <td class="sorting_1">#{{user.id}} </td>
            <td style="max-width: 140px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: left"
              class="sorting_1"><a href="app-invoice-preview.html">
                <div class="d-flex justify-content-start align-items-center">
                  <div class="avatar-wrapper">
                    <div class="avatar avatar-sm me-2">
                      <img src="../../assets/img/avatars/10.png" alt="Avatar" class="rounded-circle">
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <a href="" class="text-body text-truncate">
                      <span class="fw-medium">
                        <span class="fw-medium">{{user.client_id}} </span></span></a>
                    <small class="text-truncate text-muted">{{user.summary}}</small>
                  </div>
                </div>
              </a></td>
            <td class="sorting_1">
              <span class="badge bg-label-danger">{{user.pnrno}} </span>
            </td>
            <td class="sorting_1">{{user.salevalue}} </td>
            <td class="sorting_1"
              style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              {{user.invoice_number}} </td>
            <td class="sorting_1">{{user.date}} </td>
            <td><span class="badge bg-label-success"> {{user.paid}} </span></td>
            <td>
              <div class="d-flex align-items-center">
                  <a href="" [routerLink]="['/Invoice/'+user.id+'/'+year+'/'+code+'/']" target="_blank"
                      class="dropdown-item"> <i class="bx bx-show mx-1"></i></a>          
                 
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>