import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss']
})
export class InvoiceEditComponent implements OnInit {
  invoice: FormGroup;
  invoice_id: any;
  table_year: any;
  details: any;
  flightinfo: any;
  clientinfo: any;
  groupinv: any;
  officeinfo: any;
  response: any;
  country_table: any;
  year: string;
  table: any;
  code: any;
  vat_no: any;
  grandotal: any;
  totalrow: any;
  tva: number;
  taxcode: any;
  constructor(public formBuilder: FormBuilder,private route:ActivatedRoute,private http:HttpClient) {
    this.invoice = this.formBuilder.group({
      invoice_date: new FormControl('', Validators.required),
      vat_no: new FormControl('', Validators.required),
      client_id: new FormControl('', Validators.required),
      pnr: new FormControl('', Validators.required),
      product: new FormControl('', Validators.required),
      salevalue:new FormControl('', Validators.required),
      quantity:new FormControl('', Validators.required),
      vatcode: new FormControl('', Validators.required),
      summary: new FormControl('', Validators.required),
      totalrow: new FormControl(this.grandotal, Validators.required),
      
    });
   }
   update_client(){ 
    const payload ={client_id :this.invoice.value.client_id,invoice_id: this.invoice_id,code:this.country_table, year: this.year}

    this.http.post("https://www.kelvintravel.com/api_agent/agent/Api_bel_invocies/update_client/", payload).subscribe((response: any) => {
      console.log(response)

    });
    //window.location.reload();
   }


   get_total(){
    this.totalrow= this.invoice.value.salevalue * this.invoice.value.quantity
    this.tva= Math.round(this.totalrow * (this.invoice.value.vatcode/100))
    this.grandotal=Math.round(this.totalrow+this.tva)
   }

   submit(){
             
    const payload = { code:this.country_table, year: this.year,id:this.invoice_id,
      invoice_issuedate:this.invoice.value.invoice_date,pnr:this.invoice.value.pnr,
      product:this.invoice.value.product,quantity:this.invoice.value.quantity,
      salevalue:this.invoice.value.salevalue,summary:this.invoice.value.summary,
      vatcode:this.invoice.value.vatcode,grandotal:this.grandotal,client_id:this.invoice.value.client_id  };


    this.http.post('https://www.kelvintravel.com/api_agent/agent/api_bill_view/update', payload).subscribe((response: any) => {
      console.log(response)

    });
   }


  ngOnInit(): void {
    this.year = this.route.snapshot.paramMap.get('year');
    this.country_table = this.route.snapshot.paramMap.get('code');
    this.invoice_id = this.route.snapshot.paramMap.get('id');
      
    let param_value = this.invoice_id  + '&' + 'code=' + this.country_table +'&'+ 'year=' + this.year

    this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_bill_view/?id='+param_value)
    .subscribe((response: any) => {
      this.table = this.country_table;
      this.year = response.year;
      this.code = response.code;
      this.details = response.detail;
      this.officeinfo = JSON.parse(response?.office)

      if(this.details.vat == 0){
          this.taxcode = "001 ";
      }else {
      this.taxcode = this.details.vat;
    }

      if (this?.details?.flightinfo != '') {
        this.flightinfo = JSON.parse(this.details?.flightinfo)
      }
      if (this?.details?.client_info != '') {
        this.clientinfo = JSON.parse(this.details?.client_info)
      }
      if (this?.details?.groupinv) {
        this.groupinv = JSON.parse(this?.details?.groupinv)
        console.log(this.groupinv)
      }

    });
  }

}
