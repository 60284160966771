import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {
id:any
  psa_id: number;
  code: any;
  table: any;
  costdata: any;
  res: any;
 edit_form: FormGroup;
 moloni_form: FormGroup;
 editformres:any
 clientlist:any
 search_bar:boolean =false
 search_form: FormGroup;
  molinires: any;
  import_tax_id: any;
  router: any;
  key: any;
  create_form: FormGroup;
  result: any;
  country_id: any;
  loading: boolean;
  constructor(private route:ActivatedRoute,public formBuilder: FormBuilder,private http:HttpClient) {
    this.edit_form = this.formBuilder.group({
      city_code1: ['', [Validators.required]],
      client_balance1: ['', [Validators.required]],
      company_name1:['', [Validators.required]],
      country_code1: ['', [Validators.required]],
      es_allowed1: ['', [Validators.required]],
      id1: [this.route.snapshot.paramMap.get('id')],
      lastname1: ['', [Validators.required]],
      logo1: ['', [Validators.required]],
      mail1: ['', [Validators.required]],
      markup1: ['', [Validators.required]],
      mobile1: ['', [Validators.required]],
      morada1: ['', [Validators.required]],
      name1: ['', [Validators.required]],
      postal_code1: ['', [Validators.required]],
      qr_allowed1: ['', [Validators.required]],
      t_code1: ['', [Validators.required]],
      tax_id1: ['', [Validators.required]],
      tax_name1: ['', [Validators.required]],
      tax_number1: ['', [Validators.required]],
      user_code1: ['', [Validators.required]],
      user_type1: ['', [Validators.required]],
      web_balance1: ['', [Validators.required]],
    }),

    this.create_form = this.formBuilder.group({
         
      vat:['', [Validators.required]],
      name: ['', [Validators.required]],      
      address: ['', [Validators.required]],      
      city: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],    
      country: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required]],
    }),
    this.search_form = this.formBuilder.group({
      vat1: ['', [Validators.required]],

    }),

    this.moloni_form = this.formBuilder.group({
      customer_id1: ['', [Validators.required]],
       address1: ['', [Validators.required]],
      city1: ['', [Validators.required]],
      country_name1: ['', [Validators.required]],
     
      email1: ['', [Validators.required]],
      name1: ['', [Validators.required]],
      number1: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      vat1: ['', [Validators.required]],
      zip_code1: ['', [Validators.required]],
       country_id: [ '',[Validators.required]],
      
      

    })
   }

  ngOnInit(): void {

    this.loading = true;
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 




    this.id = this.route.snapshot.paramMap.get('id');
    const payload = {id:this.id};
    this.http.post('https://www.kelvintravel.com/api_agent/agent/api_client/api_new_getclinet', payload).subscribe((res: any) => {
     
    this.res=res
    this.import_tax_id=res?.tax_id
    this.editformres=res
    this.import_tax_id=res?.detail?.customer_id
    this.loading = false;

  });
  }
  update_client(){ 
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_create_client/update_client/', this.edit_form.value).subscribe((response: any) => {
       
      
      
      
  });
  this.load_editdata();
  }

   create_client(){ 
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_create_client/create_client/', this.create_form.value).subscribe((response: any) => {
      if (response.status == 0) { 
        this.result = response.error;  
      } 
      if (response.status == 1) { 
        this.result = response.detail;  
      }   
      
  });
  this.load_editdata();
  }

  load_editdata(){ 
    this.id = this.route.snapshot.paramMap.get('id');
    const payload = {id:this.id};
    this.http.post('https://www.kelvintravel.com/api_agent/agent/api_client/api_new_getclinet', payload).subscribe((res: any) => {
      this.res=res
      this.import_tax_id=res?.tax_id
      this.editformres=res
      this.import_tax_id=res?.detail?.customer_id
     
  });
    

   }


  load_molini(){ 

    this.loading = true;
    const payload = {taxid:this.res?.tax_number};

    this.http.post('https://www.kelvintravel.com/api_agent/agent/api_client/get_taxno', payload).subscribe((response: any) => {
      
   
    this.molinires=response?.detail[0]
     this.import_tax_id=response?.detail[0]?.customer_id
     this.country_id = response?.detail[0]?.country_id 
     this.loading = false;
  });
    

   }


   onClientData(vat){
    this.molinires=[]
    this.res=[]
    this.search_bar=false

    const payload = {taxid:vat};
    this.http.post('https://www.kelvintravel.com/api_agent/agent/api_client/get_taxno', payload).subscribe((response: any) => {
    this.molinires=response?.detail
    this.import_tax_id=response?.detail?.customer_id
    this.search_bar=false

  });
    

}


  seachVAT(){ 
    this.search_bar = true;

    this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_getclient/get_pt?text='+ this.search_form.value.vat1).subscribe((response: any) => {
     
    this.clientlist = response
  });
    
   }




  edit_moloni_data(){ 
    const payload = {formdata:this.moloni_form.value, tax_id:this.import_tax_id};

    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_create_client/vat_update', payload).subscribe((response: any) => {
    
    if (response.status == 0) { 
      this.result = response.error;  
    } 
    if (response.status == 1) { 
      this.result = response.detail;  
    }   
  //  console.log(this.res.mail)
  });
  }
}
 