import { Component,OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { state } from '@angular/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'kelvintravel';
  deviceInfo = null;
  key: any;
  id: any;
  router: any;
  constructor(private http: HttpClient, ) {    
  }
  ngOnInit(): void {  
    
    
      let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
        this.key = user.detail.token;
        this.id = user.detail.id;
      const payload = {key:this.key,id:this.id};
      this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payload).subscribe((response: any) => {
      //  console.log(response) 
        if (response.status == 102) { 
          alert(response.error);
          localStorage.removeItem('backend-flight-user')
          localStorage.removeItem('isLoggedin')
          this.router.navigate(['/auth/login']);
        }           
        }  
    ); 
}
}