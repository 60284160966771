 <!-- Content -->



     
<div *ngIf="loading">
<div class="loaderorder">
  
</div>
</div>


 <div class="container-xxl flex-grow-1 container-p-y">
  <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3">
  <div class="d-flex flex-column justify-content-center">
    <h5 class="mb-1 mt-3" *ngIf="gdspnr"> 
      Order #{{gdspnr}} 
      <span class="badge bg-label-info" *ngIf="docnumber">Ticket Issued</span>
      <span class="badge bg-label-info" *ngIf="!docnumber">Ticket Not Issued</span>
    </h5>
    <p class="text-body"> {{date}} <span id="orderYear"></span>,
      <span class="badge bg-label-primary" >
        {{saledata1.agent | uppercase}} Booking</span>
       </p>
  </div>

  <div class="d-flex align-content-center flex-wrap gap-2">

   <a href="http://localhost:4200/#/auth/ticket-print/{{gdspnr|uppercase}}/{{psa_id}}/{{email}}/{{userId}}/admin"
    target="_blank" class="btn btn-label-dark delete-order">
      Print Ticket</a> 
      <button (click)='save()' class="btn btn-label-danger delete-order" [disabled]="toalldebit.invalid">
        Issue with {{res?.price_office}} </button>
       </div>

   
</div>

<!-- Order Details Table -->



<div class="row">
  <div class="col-12 col-lg-8">
    <div class="card mb-4" *ngIf="gdspnr">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="card-title m-0">Order details</h5>
        <h6 class="m-0">
          <span href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#repricemodal" 
           style="cursor: pointer;" class="badge bg-label-info">Reprice Booking</span></h6>
      </div>
      <div class="card-datatable table-responsive">
        <table class="datatables-order-details table" >
          <thead>
            <tr >
              <th  class="w-25">AIRLINE</th>
              <th  class="w-25">DATE</th>
              <th class="w-25">AIRPORT</th>
              <th class="w-25">TIME</th>
              <th class="w-25">CLASS</th>
              <th>STATUS</th>
            </tr>
          </thead>
  <tbody>
<tr class="odd" *ngFor="let item of detailList">
    <td class="  control" tabindex="0" style="display: none;"></td>
   
        <td class="sorting_1">
            <div class="d-flex justify-content-start align-items-center text-nowrap">
                <div class="avatar-wrapper">
                  <div class="">
                    <img src="https://www.khalsatravel.net/webroot/frontend/airline-images/{{item.airline_image}}.png" 
                    alt="" class=" airlineimage rounded-2">
                </div>
            </div>
            <div class="d-flex flex-column">
                <h6 class="text-body mb-0">  {{item.airline_code}}</h6>
                <small class="text-muted">{{item.baggageAllowance}}</small>
            </div>
            </div>
            </td>
            <td><span>{{item.depDate}}</span></td>
            <td><span>{{item.cityCode}}-{{item.aircityCode}}</span></td>
            <td><span>{{item.depTime}}-{{item.arrTime}}</span></td>
            <td><span class="text-body">{{item.Classofservice}}</span></td>
            <td>
                <h6 class="mb-0">{{item.status}}</h6>
            </td>
            </tr>
            
            
             
            </tbody>



        </table>
        <div class="d-flex justify-content-end align-items-center m-3 mb-2 p-1" >
          <div class="order-calculations">
       
            <div class="d-flex justify-content-between">
              <h6 class="w-px-100 mb-0">Total:</h6>
              <h6 class="mb-0">{{grandtotal}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-4" *ngIf="price_box_visible">
      <div class="card-widget-separator-wrapper">
        <div class="card-body card-widget-separator">
          <div class="row gy-4 gy-sm-1">
            <div class="col-sm-6 col-lg-3" *ngIf="res">
              <div class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                <div>
                  <h6 class="mb-2">Option 1</h6>
                  <h4 class="mb-2">{{totall}} €</h4>
                  <p class="mb-0"><span class="text-muted me-2"></span><span  (click)="save_price('option1')" class="badge bg-label-success">{{fares}}</span></p>
                </div>
                <div class="avatar me-sm-4">
                  <span class="avatar-initial rounded bg-label-secondary">
                    <i class="bx bx-store-alt bx-sm"></i>
                  </span>
                </div>
              </div>
              <hr class="d-none d-sm-block d-lg-none me-4">
            </div>
            <div class="col-sm-6 col-lg-3" *ngIf="res1">
              <div class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                <div>
                  <h6 class="mb-2">Option 2</h6>
                  <h4 class="mb-2">{{totall1}} €</h4>
                  <p class="mb-0"><span class="text-muted me-2"></span><span  (click)="save_price('option2')" class="badge bg-label-success">{{fares1}}</span></p>
                </div>
                <div class="avatar me-sm-4">
                  <span class="avatar-initial rounded bg-label-secondary">
                    <i class="bx bx-store-alt bx-sm"></i>
                  </span>
                </div>
              </div>
              <hr class="d-none d-sm-block d-lg-none me-4">
            </div>
            <div class="col-sm-6 col-lg-3" *ngIf="res2">
              <div class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                <div>
                  <h6 class="mb-2">Option 3</h6>
                  <h4 class="mb-2">{{totall2}} €</h4>
                  <p class="mb-0"><span class="text-muted me-2"></span><span (click)="save_price('option3')" class="badge bg-label-success">{{fares2}}</span></p>
                </div>
                <div class="avatar me-sm-4">
                  <span class="avatar-initial rounded bg-label-secondary">
                    <i class="bx bx-store-alt bx-sm"></i>
                  </span>
                </div>
              </div>
              <hr class="d-none d-sm-block d-lg-none me-4">
            </div>
          </div>
        </div>
      </div>
    </div>



  <div class="card mb-4" *ngIf="pnropen_box_visible">
    <div class="card-header">
      <h5 class="card-title m-0">PNR activity</h5>
    </div>
    <div class="card-body">
      <ul  class="timeline pb-0 mb-0">
        <li class="timeline-item timeline-item-transparent border-primary">
           
          <span class="timeline-point-wrapper"><span
              class="timeline-point timeline-point-primary"></span></span>
          <div class="timeline-event">
            <div class="timeline-header">
              <a class="mb-0">  
                  <pre style="padding: 0px 0px;margin:0px;">{{rppnroffice}}   {{pnrtime}}</pre>
                  <ng-container *ngFor="let item of naame">
                      <pre style="padding: 0px 0px;margin:0px;">{{item}}</pre>
                  </ng-container>
                  <ng-container *ngFor="let item of aircode">
                      <pre style="padding: 0px 0px;margin:0px;">{{item}}</pre>
                  </ng-container>
                  <ng-container *ngFor="let items of linecodes">
                      <pre style="padding: 0px 0px;margin:0px;">{{items}}</pre>
                  </ng-container>

              </a>
              
            </div>
            
          </div>
        </li>
        <li class="timeline-item timeline-item-transparent border-primary">
          <span class="timeline-point-wrapper"><span
              class="timeline-point timeline-point-primary"></span></span>
          <div class="timeline-event">
            <div class="timeline-header">
              <h6 class="mb-0">End Screen</h6>                     
            </div>                  
          </div>
        </li>
    
      </ul>
    </div>
  </div>

  <div class="col-md-6 col-lg-12 mb-4 mb-md-0">
    <div class="card">
      <div class="table-responsive text-nowrap">
        <table class="table text-nowrap">
          <thead>
            <tr>
             
               
            </tr>
          </thead>
          <tbody class="table-border-bottom-0"  *ngFor="let user of code">
            <tr>
              <td><span class="badge bg-label-primary rounded-pill badge-center p-3 me-2">
              </span> {{user.pnr}}</td>
              <td>
                <div class="d-flex align-items-center">                           
                  <div class="d-flex flex-column">
                    <span class="fw-medium lh-1">{{user.name}}</span>
                    <small class="text-muted">{{user.number}}</small>
                  </div>
                </div>
              </td>
             
              <td>
                <div class="text-muted lh-1">
                  <span class="text-primary fw-medium">{{user.amount}} </span>/
                  {{user.bill}} </div>
                <small class="text-muted">{{user.mode}}</small>
              </td>
              <td>                
                <small class="text-muted">{{user.date}}</small>
              </td>
              <td>
 <span [ngClass]="{'bg-label-warning': user.t_code === 'Pending', 'bg-label-success': user.t_code === 'TKTT','bg-label-info': user.t_code === 'ok'}" class="badge px-2" >{{user.t_code}}</span>
          
              
              <td>
                <div class="dropdown">
                  <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i class="bx bx-dots-vertical-rounded"></i></button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:void(0);">
                      <i class="bx bx-edit-alt me-1"></i> View Details</a>
                    <a class="dropdown-item" href="javascript:void(0);">
                      <i class="bx bx-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>
        
          </tbody>
        </table>
      </div>
    </div>
  </div> 


</div>



  <div class="col-12 col-lg-4">
    
  <div> 
    <div class="card mb-4">
      <div class="alert alert-danger m-0">
        No Price saved — check it out!
      </div>
    </div>

    <div class="card mb-4">
      <div class="card-header">
        <h6 class="card-title m-0">Price saved from : {{res?.price_office}}</h6>
      </div>
      <div class="card-body">


        <section class="">
          <form [formGroup]="pricesArrayFrom" class="contact-form">
              <div class="contact-form ">
                 
              </div>
              <div class="contact-form row" style=" margin: 0; ">
                <ng-container class="" formArrayName="pricesArray">
              
                  <h6 [formGroupName]="i" class="form-field col-lg-12" *ngFor="let group of getFormData.controls; let i=index"
                    style="display: flex;">
                    <div class="avatar me-2 ">
                      <img src="../../assets/img/avatars/1.png" alt="Avatar" class="rounded-circle" />
                      <div style=" width: max-content; margin: auto; " class="form-check form-check-danger">
                        <input type="checkbox" class="form-check-input" formControlName="Selected" ngModelOptions ="{standalone: true}"
                          (change)="getSelectValued($event,i)">
                      </div>
                    </div>
                    <div>
                      <small class="label" for="name">{{getFormData.value[i].status}}
                        {{getFormData.value[i].tstCreationDate}}</small>
                      x{{getFormData.value[i].passnumber}} <br>
                      <small *ngFor="let group1 of getFormData.value[i].namaaae; let i=index" style="font-weight: 700;">{{group1}}
                        <br></small>
                      <div style=" display: flex; ">
              
              
                        <div>
                          <input ngModelOptions ="{standalone: true}" style='width: fit-content;' id="name" class="form-control invoice-item-price mb-0" type="number"
                            formControlName="perpaxprice" (change)="changeson($event,getFormData.value[i].passnumber,i)">
                        </div>
              
                      </div>
                    </div>
                  </h6>
              
                </ng-container>
<div style=" display: flex; flex-direction: column; " class="d-flex justify-content-start  mb-4">
  <div style=" display: flex; ">
    <span class="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center">
      <i class="bx bx-cart-alt bx-sm lh-sm"></i>
    </span>
    <input style="width: fit-content;" type="text" placeholder="Form of Paymnt"
      class="form-control invoice-item-price mb-0" formControlName="fop" placeholder=""
      [class.is-invalid]="fop.invalid && fop.touched" [class.is-valid]="fop.valid && fop.touched">
  </div>
  <div *ngIf="fop.invalid && fop.touched">
    <small class="text-danger" *ngIf="fop.errors?.required">
      Form of payment is Required</small>
  </div>
</div>

</div>
              <div style="display: flex;" class="contact-form ">
                  <div class="form-field col-lg-4" style="display:none">

                      <label class="label" for="name">Totall Debit</label>
                      <input style=" padding: 0; " type="number" class="input-text js-input"
                          formControlName="toalldebit" [ngModel]="saleamount1"  placeholder=""
                          [class.is-invalid]="toalldebit.invalid && toalldebit.touched"
                          [class.is-valid]="toalldebit.valid && toalldebit.touched">
                      <div *ngIf="toalldebit.invalid && toalldebit.touched">
                          <small class="text-danger" *ngIf="toalldebit.errors?.required">
                              Total debit is
                              Required</small>
                      </div>

                  </div>


              </div>
          </form>
      </section>


      </div>
    </div>

  </div>
   
    <div class="card mb-4">
      <div class="card">
        <div class="card-body">
           
              <div *ngIf="updated"class="alert alert-primary alert-dismissible" role="alert">
            Updated successfully
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
          </div>
          <div *ngIf="errorcode"class="alert alert-danger alert-dismissible" role="alert">
           Some Error during updated
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
          </div>
          
          <div class="d-flex align-items-center justify-content-between">
            <div class="content-left update">
              <table class=" table border-top dataTable collapsed">
                 <thead>
                <tr>                    
                    <th class="" style="width: 64px;">STATUS</th>
                    <th class="" style="width: 149px;">OFFICE</th>
                    <th class="" style="width: 149px;">sale </th>
                    <th class=""  style="width: 121px;">IATA</th>   
                 </tr>
            </thead>
            <tbody>
                    <tr>                         
                        <td class="dt-checkboxes-cell" > {{saledata1?.t_code| uppercase }}</td>
                        <td class="dt-checkboxes-cell" > {{saledata1?.country}}</td>
                        <td class="dt-checkboxes-cell" > {{saledata1?.billing}}</td>
                        <td class="dt-checkboxes-cell" > {{saledata1?.iata}}</td>
                        </tr>  
            </table>
         </div>
        </div> 
        <small class="badge bg-label-danger">
          Remark: {{saledata1?.paymentinfo}}</small>
        
      </div>
        <h6>              
              <a (click)="get_data()"data-bs-toggle="offcanvas" class="pointer" data-bs-target="#offcanvasEcommerceCustomerAdd">
                Update</a>
            </h6>
                    
             
             
       
      </div>
    </div>


    <div class="card mb-4">
      <div class="card-header">
        <h6 class="card-title m-0">Passenger detail:</h6>
      </div>
      <div class="card-body">
        <div *ngFor="let item of nameList" class="d-flex justify-content-start align-items-center mb-4">
          <div class="avatar me-2">
            <img src="../../assets/img/avatars/1.png" alt="Avatar" class="rounded-circle" />
          </div>
          <div class="d-flex flex-column">
            <a href="app-user-view-account.html" class="text-body text-nowrap">
              <h6  class="mb-0">{{item.paxname}}</h6>
            </a>
            <small class="badge bg-label-danger" *ngIf="item.docnumber">
              E-Tkt No. {{item.docnumber}}</small>
          </div>
        </div>
       
        <div class="d-flex justify-content-between">
          <h6>Airline PNR:  {{gdspnr | uppercase }}</h6>
          <h6>
            <a href=" javascript:void(0)" data-bs-toggle="modal" data-bs-target="#editUser">Edit</a>
          </h6>
          
        </div>
         
      </div>
    </div>

    <div class="card mb-4">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <div class="content-left">
              <h3 class="mb-0"> {{balance}}Euro</h3>
              <small class= "badge bg-label-success">Current Balance</small>
            </div>
            <button class="btn btn-label-danger" (click)="balanceroute(user_id)" 
             >Add Balance</button>
            </div>      
             
             
        </div>
      </div>
    </div>

    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between">
        <h6 class="card-title m-0">Customer details</h6>
        
        <h6 class="m-0">
          <a (click)="go_to_edit(user_id)">Edit</a>
        </h6>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-start align-items-center mb-4">
            <div class="avatar me-2">
              <img src="../../assets/img/avatars/1.png" alt="Avatar" class="rounded-circle" />
            </div>
            <div class="d-flex flex-column">
              <a href="app-user-view-account.html" class="text-body text-nowrap">
                <h6 class="mb-0">{{profile?.agent_name}}</h6>
              </a>
              <small class="text-muted">Customer ID: #{{user_id}}</small>
            </div>
          </div>
          <p class="mb-0">Email:  {{profile?.agent_email}}</p>
        <p class="mb-0">Mobile: {{profile?.agent_phone}}</p>
        <p class="mb-0">{{profile?.agent_address}} <br />{{profile?.agent_city}} <br />
          {{profile?.agent_zip_code}} <br /></p>
      </div>
    </div>
   
   
   
    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between">
        <h6 class="card-title m-0">Accounts: {{table | uppercase }}</h6>
        <h6 class="m-0">
          <a (click)="go_to_accounts()">Edit</a>
        </h6>
      </div>
      <div class="card-body">
        <div class="report-list">
          <div class="report-list-item rounded-2 mb-3">
            <div class="d-flex align-items-start">
              <div class="report-list-icon shadow-sm me-2">
                <img src="../../assets/svg/icons/paypal-icon.svg" width="22" height="22" alt="Paypal">
              </div>
              <div class="d-flex justify-content-between align-items-end w-100 flex-wrap gap-2">
                <div class="d-flex flex-column">
                  <span>Purchase</span>
                  <h5 class="mb-0">€ {{costdata}}</h5>
                </div>
                <small class="text-success">+2.34k</small>
              </div>
            </div>
          </div>
          <div class="report-list-item rounded-2 mb-3">
            <div class="d-flex align-items-start">
              <div class="report-list-icon shadow-sm me-2">
                <img src="../../assets/svg/icons/shopping-bag-icon.svg" width="22" height="22" alt="Shopping Bag">
              </div>
              <div class="d-flex justify-content-between align-items-end w-100 flex-wrap gap-2">
                <div class="d-flex flex-column">
                  <span>Payments</span>
                  <h5 class="mb-0">$38,658</h5>
                </div>
                <small class="text-danger">-1.15k</small>
              </div>
            </div>
          </div>
          <div class="report-list-item rounded-2">
            <div class="d-flex align-items-start">
              <div class="report-list-icon shadow-sm me-2">
                <img src="../../assets/svg/icons/wallet-icon.svg" width="22" height="22" alt="Wallet">
              </div>
              <div class="d-flex justify-content-between align-items-end w-100 flex-wrap gap-2">
                <div class="d-flex flex-column">
                  <span>Balance</span>
                  <h5 class="mb-0">$18,220</h5>
                </div>
                <small class="text-success">+1.35k</small>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>


<div class="modal fade" id="repricemodal"  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-simple modal-add-new-address"  >
    <div class="modal-content p-3 p-md-5">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="text-center mb-4">
          <p class="address-subtitle">Choose Amadeus for issue</p>
        </div>              

        <form   [formGroup]="repriceform" id="addNewAddressForm" class="row g-3" onsubmit="return false">
          <div class="col-12">
            <div class="row">
              <div class="col-md mb-md-0 mb-3">
                <div class="form-check custom-option custom-option-icon">
                  <label class="form-check-label custom-option-content" for="ptcheck">
                    <span class="custom-option-body">
                      <i class="bx bx-home"></i>
                      <span class="custom-option-title">Portugal</span>
                    </span>
                    <input type="radio" id="ptcheck" class="form-check-input" value="PT"  formControlName="country">

                  </label>
                </div>
              </div>

              <div class="col-md mb-md-0 mb-3">
                <div class="form-check custom-option custom-option-icon">
                  <label class="form-check-label custom-option-content" for="frcheck">
                    <span class="custom-option-body">
                      <i class="bx bx-home"></i>
                      <span class="custom-option-title">France</span>
                    </span>
                    <input  type="radio" id="frcheck" class="form-check-input" value="FR"  formControlName="country">
                  </label>
                </div>
              </div>

              <div class="col-md mb-md-0 mb-3">
                <div class="form-check custom-option custom-option-icon">
                  <label class="form-check-label custom-option-content" for="becheck">
                    <span class="custom-option-body">
                      <i class="bx bx-briefcase"></i>
                      <span class="custom-option-title">Belgium </span>
                    </span>
                    <input  type="radio" id="becheck" class="form-check-input" value="BE" formControlName="country">
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 text-center">
            <button type="submit" class="btn btn-primary me-sm-3 me-1" (click)="PricePnrmultiple(gdspnr)" data-bs-dismiss="modal" [disabled]="repriceform.invalid" aria-label="Close">Send for Reproce</button>
            <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<!-- Edit User Modal -->
<div class="modal fade" id="editUser" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-simple modal-edit-user">
    <div class="modal-content p-3 p-md-5">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="text-center mb-4">
          <h3>Edit User Information</h3>
          <p>Updating user details will receive a privacy audit.</p>
        </div>
        <form id="editUserForm" class="row g-3" onsubmit="return false">
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditUserFirstName">First Name</label>
            <input type="text" id="modalEditUserFirstName" name="modalEditUserFirstName"
              class="form-control" placeholder="John" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditUserLastName">Last Name</label>
            <input type="text" id="modalEditUserLastName" name="modalEditUserLastName" class="form-control"
              placeholder="Doe" />
          </div>
          <div class="col-12">
            <label class="form-label" for="modalEditUserName">Username</label>
            <input type="text" id="modalEditUserName" name="modalEditUserName" class="form-control"
              placeholder="john.doe.007" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditUserEmail">Email</label>
            <input type="text" id="modalEditUserEmail" name="modalEditUserEmail" class="form-control"
              placeholder="example@domain.com" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditUserStatus">Status</label>
            <select id="modalEditUserStatus" name="modalEditUserStatus" class="form-select"
              aria-label="Default select example">
              <option selected>Status</option>
              <option value="1">Active</option>
              <option value="2">Inactive</option>
              <option value="3">Suspended</option>
            </select>
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditTaxID">Tax ID</label>
            <input type="text" id="modalEditTaxID" name="modalEditTaxID"
              class="form-control modal-edit-tax-id" placeholder="123 456 7890" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditUserPhone">Phone Number</label>
            <div class="input-group input-group-merge">
              <span class="input-group-text">+1</span>
              <input type="text" id="modalEditUserPhone" name="modalEditUserPhone"
                class="form-control phone-number-mask" placeholder="202 555 0111" />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditUserLanguage">Language</label>
            <select id="modalEditUserLanguage" name="modalEditUserLanguage" class="select2 form-select"
              multiple>
              <option value="">Select</option>
              <option value="english" selected>English</option>
              <option value="spanish">Spanish</option>
              <option value="french">French</option>
              <option value="german">German</option>
              <option value="dutch">Dutch</option>
              <option value="hebrew">Hebrew</option>
              <option value="sanskrit">Sanskrit</option>
              <option value="hindi">Hindi</option>
            </select>
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalEditUserCountry">Country</label>
            <select id="modalEditUserCountry" name="modalEditUserCountry" class="select2 form-select"
              data-allow-clear="true">
              <option value="">Select</option>
              <option value="Australia">Australia</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Belarus">Belarus</option>
              <option value="Brazil">Brazil</option>
              <option value="Canada">Canada</option>
              <option value="China">China</option>
              <option value="France">France</option>
              <option value="Germany">Germany</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Japan">Japan</option>
              <option value="Korea">Korea, Republic of</option>
              <option value="Mexico">Mexico</option>
              <option value="Philippines">Philippines</option>
              <option value="Russia">Russian Federation</option>
              <option value="South Africa">South Africa</option>
              <option value="Thailand">Thailand</option>
              <option value="Turkey">Turkey</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
            </select>
          </div>
          <div class="col-12">
            <label class="switch">
              <input type="checkbox" class="switch-input" />
              <span class="switch-toggle-slider">
                <span class="switch-on"></span>
                <span class="switch-off"></span>
              </span>
              <span class="switch-label">Use as a billing address?</span>
            </label>
          </div>
          <div class="col-12 text-center">
            <button type="submit" class="btn btn-primary me-sm-3 me-1">Submit</button>
            <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--/ Edit User Modal -->

<!-- Add New Address Modal -->
<div class="modal fade" id="addNewAddress" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-simple modal-add-new-address">
    <div class="modal-content p-3 p-md-5">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="text-center mb-4">
          <h3 class="address-title">Add New Address</h3>
          <p class="address-subtitle">Add new address for express delivery</p>
        </div>
        <form id="addNewAddressForm" class="row g-3" onsubmit="return false">
          <div class="col-12">
            <div class="row">
              <div class="col-md mb-md-0 mb-3">
                <div class="form-check custom-option custom-option-icon">
                  <label class="form-check-label custom-option-content" for="customRadioHome">
                    <span class="custom-option-body">
                      <i class="bx bx-home"></i>
                      <span class="custom-option-title">Home</span>
                      <small> Delivery time (9am – 9pm) </small>
                    </span>
                    <input name="customRadioIcon" class="form-check-input" type="radio" value=""
                      id="customRadioHome" checked />
                  </label>
                </div>
              </div>
              <div class="col-md mb-md-0 mb-3">
                <div class="form-check custom-option custom-option-icon">
                  <label class="form-check-label custom-option-content" for="customRadioOffice">
                    <span class="custom-option-body">
                      <i class="bx bx-briefcase"></i>
                      <span class="custom-option-title"> Office </span>
                      <small> Delivery time (9am – 5pm) </small>
                    </span>
                    <input name="customRadioIcon" class="form-check-input" type="radio" value=""
                      id="customRadioOffice" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalAddressFirstName">First Name</label>
            <input type="text" id="modalAddressFirstName" name="modalAddressFirstName" class="form-control"
              placeholder="John" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalAddressLastName">Last Name</label>
            <input type="text" id="modalAddressLastName" name="modalAddressLastName" class="form-control"
              placeholder="Doe" />
          </div>
          <div class="col-12">
            <label class="form-label" for="modalAddressCountry">Country</label>
            <select id="modalAddressCountry" name="modalAddressCountry" class="select2 form-select"
              data-allow-clear="true">
              <option value="">Select</option>
              <option value="Australia">Australia</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Belarus">Belarus</option>
              <option value="Brazil">Brazil</option>
              <option value="Canada">Canada</option>
              <option value="China">China</option>
              <option value="France">France</option>
              <option value="Germany">Germany</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Japan">Japan</option>
              <option value="Korea">Korea, Republic of</option>
              <option value="Mexico">Mexico</option>
              <option value="Philippines">Philippines</option>
              <option value="Russia">Russian Federation</option>
              <option value="South Africa">South Africa</option>
              <option value="Thailand">Thailand</option>
              <option value="Turkey">Turkey</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
            </select>
          </div>
          <div class="col-12">
            <label class="form-label" for="modalAddressAddress1">Address Line 1</label>
            <input type="text" id="modalAddressAddress1" name="modalAddressAddress1" class="form-control"
              placeholder="12, Business Park" />
          </div>
          <div class="col-12">
            <label class="form-label" for="modalAddressAddress2">Address Line 2</label>
            <input type="text" id="modalAddressAddress2" name="modalAddressAddress2" class="form-control"
              placeholder="Mall Road" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalAddressLandmark">Landmark</label>
            <input type="text" id="modalAddressLandmark" name="modalAddressLandmark" class="form-control"
              placeholder="Nr. Hard Rock Cafe" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalAddressCity">City</label>
            <input type="text" id="modalAddressCity" name="modalAddressCity" class="form-control"
              placeholder="Los Angeles" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalAddressLandmark">State</label>
            <input type="text" id="modalAddressState" name="modalAddressState" class="form-control"
              placeholder="California" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label" for="modalAddressZipCode">Zip Code</label>
            <input type="text" id="modalAddressZipCode" name="modalAddressZipCode" class="form-control"
              placeholder="99950" />
          </div>
          <div class="col-12">
            <label class="switch">
              <input type="checkbox" class="switch-input" />
              <span class="switch-toggle-slider">
                <span class="switch-on"></span>
                <span class="switch-off"></span>
              </span>
              <span class="switch-label">Use as a billing address?</span>
            </label>
          </div>
          <div class="col-12 text-center">
            <button type="submit" class="btn btn-primary me-sm-3 me-1">Submit</button>
            <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1"  id="offcanvasEcommerceCustomerAdd" aria-modal="true" role="dialog">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">Update Account: {{row_id}}</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body mx-0 flex-grow-0">
    <form  [formGroup]="row_update" >
      <div class="ecommerce-customer-add-basic mb-3">         
        <div class="mb-3 fv-plugins-icon-container">
          <label class="form-label1" for="ecommerce-customer-add-name">Client ID*</label>                     
          <input ngModel="{{client_id}}" ngModelOptions ="{standalone: false}"formControlName="client_id" type="text" class="form-control">
          </div>  
       
          <div class="mb-3">
              <label class="form-label1" for="ecommerce-customer-add-state">Name</label>
              <input formControlName="tkt_name" type="text" ngModelOptions ="{standalone: false}" ngModel="{{tkt_name}}" class="form-control">
            </div>
                <div class="mb-3">
               <label class="form-label1" for="ecommerce-customer-add-address-2"> Payment remark</label>
               <input formControlName="remarks" type="text" ngModelOptions ="{standalone: false}" ngModel="{{remarks}}" class="form-control">
              
             </div>
             
             
             <div class="row mb-3">
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="ecommerce-customer-add-state">Gds</label>
         <input formControlName="gds" ngModel="{{gds}}"   ngModelOptions ="{standalone: false}"
          type="number" id="ecommerce-customer-add-state" class="form-control" placeholder="">

       </div>
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="ecommerce-customer-add-post-code">Sale Amount</label>
         <input formControlName="saleamount" ngModel="{{saleamount}}" type="number" id="ecommerce-customer-add-post-code" class="form-control" name="saleamount">
       </div>
     </div>
     <div class="row mb-3">
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="ecommerce-customer-add-state">Recived Amount</label>
         <input formControlName="recived"  ngModel="{{recived}}"  type="number"  ngModelOptions ="{standalone: false}"
          class="form-control" placeholder="" aria-label="Southern tip" name="customerState">
       </div>
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="ecommerce-customer-add-post-code">Mode of Payment</label>  
              <select formControlName="modeofpayment" ngModelOptions ="{standalone: false}" class="form-control" ngModel="{{modeofpayment}}" >
               
                  <option *ngFor="let sta of pay_method" [ngValue]="sta">{{sta}}</option>
               </select>  
       </div>
     </div>
     <div class="row mb-3">
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="ecommerce-customer-add-state">Office Country</label>
         <select formControlName="country_accounts" class="form-control" ngModelOptions ="{standalone: false}" ngModel="{{country_accounts}}" >
          <option [ngValue]="country_accounts" Value="country_accounts">{{country_accounts}}</option>
              <option *ngFor="let sta of accounts"  [ngValue]="sta">{{sta}}</option>
            </select>  

       </div>
      
       <div class="col-12 col-sm-6">
        <label class="form-label1" for="ecommerce-customer-add-state">Need Invocie*</label>
        <select formControlName="invoicedata" class="form-control"ngModelOptions ="{standalone: false}" ngModel="{{invoicedata}}" >
          <option [ngValue]="invoicedata" Value="invoicedata">{{invoicedata}}</option>
          <option *ngFor="let sta of invoices"  [ngValue]="sta">{{sta}}</option>
           </select>   
      </div>
     </div>

     <div class="row mb-3">
      <div class="col-12 col-sm-6">
        <label class="form-label1" for="ecommerce-customer-add-state">Service</label>
        <select formControlName="service" class="form-control" ngModelOptions ="{standalone: false}"ngModel="{{service}}">
         <option [ngValue]="service" Value="service">{{service}}</option>          
          <option *ngFor="let sta of serviceItems" [ngValue]="sta">{{sta}}</option>
        </select>          
      </div>
       
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="commerce-customer-add-state">TAX ID</label>
         <input formControlName="tax_id" type="text" ngModel="{{tax_id}}"  class="form-control">
       </div>
     </div>
     

     <div class="row mb-3">
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="ecommerce-customer-add-state">PNR No.</label>
         <input formControlName="pnrno" type="text "ngModel="{{pnrno}}" class="form-control">
       </div>
       <div class="col-12 col-sm-6">
        <label class="form-label1" for="ecommerce-customer-add-town">IATA Code</label>
         
        <select formControlName="iata" class="form-control" ngModel="{{iata}}" >
          <option [ngValue]="iata" Value="iata">{{iata}}</option>
          <option *ngFor="let sta of iataslist"  [ngValue]="sta">{{sta}}</option>
           </select>                      
          </div>   
      
     </div>      

     <div class="row mb-3">        
       <div class="col-12 col-sm-6">
         <label class="form-label1" for="ecommerce-customer-add-post-code">Ticket number</label>
         <input formControlName="tkt_number" type="text" ngModel="{{tkt_number}}" class="form-control" ngModelOptions ="{standalone: false}" >
       </div>

       <div class="col-12 col-sm-6">
        <label class="form-label1" for="ecommerce-customer-add-post-code">Status</label>
        <select formControlName="status" class="form-control" ngModel="{{status}}" >
      <option [ngValue]="status" Value="status">{{status}}</option>
          <option *ngFor="let sta of statuslist"  [ngValue]="sta">{{sta}}</option>
        </select>              
      </div>  
     </div>         
   </div>        
   <div class="pt-3">
     <button (click)="update_account()" type="submit" data-bs-dismiss="offcanvas" class="btn btn-primary me-sm-3 me-1 data-submit">update</button>
     <button type=" " class="btn bg-label-danger" data-bs-dismiss="offcanvas"  >Discard</button>
   </div>     
    <input type="hidden" ></form>
  </div>
</div>

<!--/ Add New Address Modal -->
</div>
<!--/ Content -->
