import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import * as _ from 'underscore';
import { FormArray,FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  accounts=["Portugal","Belgium","France"];
  invoices=["Yes","No"];
  serviceItems = 
  ["amadeus",
  "BE_AMADEUS",
  "FR_AMADEUS",
  "REISSUE",  
  "BE_GAL_TKT",
  "FR_GAL_TKT",
  "GAL_TKT",
  "void",
  "repeat",
  "SPARK_PT_EK",
  "SPARK_BE_LH",
  "SPARK_BE_EK",
  "old_falta",
  "sdp",
  "refund",
  "ndc_BA",
  "ONLINETKT",
  "VISA",
  "others"
  ];
  pay_method = 
  ["Blank",
  'waiting',
  'BEL_Cash',
  'Bank_kbc',
  'Bank_caixa',
  'Bank_novo',
  'bcp bank',
  'Bank_bpi',
  'Ingenico',
  'bancontact',
  'visa_elec',
  'adjust',
  'Revoutl_Fr',];

  statuslist = 
  ["Pending","CANX",
  'TKTT',
  'Refund',
  'Cancel',
  'ok'];
 
  iataslist= ["64215502",'8208233','20290314','GTF','EVA','GETFLIGHT','other'];
  rows:any
  country_table:any
  admin_id: number;
  account: any;
  rowsres: any;
  router: any;
  key: any;
  id: any;
  loading: boolean;
  lineno: any;
  table: any;
  row_updates: any;
  pax_id: any;
  date: any;
  modeofpayment: any;
  pnrno: any;
  recived: any;
  tax_id: any;
  tkt_name: any;
  saleamount: any;
  tktnameforbill: any;
  tax_code: any;
  molonidata: any;
  gds: any;
  iata: any;
  
  filterForm: FormGroup;
  userForm: FormGroup;
  client_id: string;
  tkt_number: string;
  status: string;
  service: string;
  country_accounts: string;
  remarks: string;
  invoicedata: string;
  row_id: any;
  country: any;
  res1: any;
  res2: any;
  res3: any;
  res4: any;
  imageViewData: null;
  loaderi: boolean;
  custmer_ref: any;
  localUserIds: any;
  pnrbar: number;
  show_otp: boolean;
  SerachclientDeatilsFrom: FormGroup;
  search_bar: boolean;
  clientlist: any;
  email: any;
  client_use_id: any;
search_bar_form: any;
 
PT_Client: boolean = false;
FR_Client: boolean = false;
BE_Client: boolean = false;
PT_Agent: boolean = false;
BE_agent: boolean = false;
FR_Agent: boolean = false;
Missing_Tkts: boolean = false;
All_Entries: boolean = false;
end_datek: any
start_datek: any
clientID:any
client_id_get: any;
  search_bar_one: boolean;
  clientlist_one: any;
  deleteForm: FormGroup;
  delete_id: boolean;
  end_date: string;
  start_date: string;
  client_id_get_one: any;
  constructor(private route:ActivatedRoute,private http:HttpClient,
    public formBuilder: FormBuilder,
    private fb: FormBuilder,
    private sb: FormBuilder) {  
      
    this.SerachclientDeatilsFrom = this.formBuilder.group({
      client_id_get: ['', Validators.required],
      });

      this.filterForm = this.formBuilder.group({
        client_id_get: ['', Validators.required],
        start_date: ['', Validators.required],
        end_date: ['', Validators.required],
      });
  
      this.deleteForm = this.sb.group({
      delete_id: new FormControl('', Validators.required), 
      });

        
     
    
    this.userForm = this.fb.group({
      client_id_get: new FormControl('', Validators.required),
      tax_id: new FormControl('', Validators.required),
      vat_no: new FormControl('', Validators.required),
      tkt_name: new FormControl('', Validators.required),
      tkt_number: new FormControl('', Validators.required),
      country_accounts: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      service: new FormControl('', Validators.required),
      remarkss: new FormControl('', Validators.required),
      gds: new FormControl('', Validators.required),
      saleamount: new FormControl('', Validators.required),
      recived: new FormControl('', Validators.required),
      modeofpayment: new FormControl('', Validators.required),
      iata: new FormControl('', Validators.required),
      pnrno: new FormControl('', Validators.required),
      invoicedata: new FormControl('', Validators.required),
    });

    
  }




  close_otp_modal() { 
    this.delete_id=false
  }
  delete (){  
    this.delete_id = true;
    console.log(this.delete_id);
  }

  onSubmitClient () {
    this.delete_id=false
    this.admin_id = 1;
    const row_id = this.row_id ; 
    const country  = this.country; 
    const delete_id = this.deleteForm.value.delete_id;     
    let param_value = 'country='+ country + '&' + 'key='+ this.key + '&' + 'id=' + delete_id +'&'+ 'code_id=' + row_id +'&'+'admin=' + this.admin_id 
    this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_update_row/delete_code/?' +param_value).subscribe((response: any) => {
    this.applyFilters()
              
      }  
  ); 

     
   
 }
 

  resetfilterForm () {
    //this.filterForm.reset()
    this.client_id_get_one = "";
    this.start_datek = "";
    this.end_datek = "";
    this.filterForm.value.client_id_get = "";
    this.filterForm.value.start_date = "";
    this.filterForm.value.end_date = "";
    localStorage.setItem('clientID', this.client_id_get);
    console.log(this.client_id_get)
    this.applyFilters();

  }

  cleanmodal() {
    this.client_id = ""
    this.gds = ""
    this.pnrno =""
    this.tkt_number = ""
    this.tkt_name = ""
    this.status = ""
    this.saleamount = ""
    this.recived = ""
    this.modeofpayment =""
    this.service = ""
    this.country_accounts = ""
    this.tax_id = ""
    this.remarks = ""
    this.iata = ""
    this.invoicedata = ""

   }  

   
  ngOnInit(): void {

    
    var PT_Client = localStorage?.getItem('PT_Client');
    var FR_Client = localStorage?.getItem('FR_Client');
    var BE_Client = localStorage?.getItem('BE_Client');
    var PT_Agent = localStorage?.getItem('PT_Agent');
    var BE_agent = localStorage?.getItem('BE_agent');
    var FR_Agent = localStorage?.getItem('FR_Agent');
    var Missing_Tkts = localStorage?.getItem('Missing_Tkts');
    var All_Entries = localStorage?.getItem('All_Entries');
    var clientID = localStorage?.getItem('clientID');
    var start_date = localStorage?.getItem('start_date');
    var end_date = localStorage?.getItem('end_date');

    if (PT_Client == 'false') {
      this.PT_Client = false;
    } else {
      this.PT_Client = true;
    }

    if (FR_Client == 'false') {
      this.FR_Client = false;
    } else {
      this.FR_Client = true;
    }

    if (BE_Client == 'false') {
      this.BE_Client = false;
    } else {
      this.BE_Client = true;
    }

    if (PT_Agent == 'false') {
      this.PT_Agent = false;
    } else {
      this.PT_Agent = true;
    }

    if (BE_agent == 'false') {
      this.BE_agent = false;
    } else {
      this.BE_agent = true;
    }

    if (FR_Agent == 'false') {
      this.FR_Agent = false;
    } else {
      this.FR_Agent = true;
    }

    if (Missing_Tkts == 'false') {
      this.Missing_Tkts = false;
    } else {
      this.Missing_Tkts = true;
    }

    if (All_Entries == 'false') {
      this.All_Entries = false;
    } else {
      this.All_Entries = true;
    }
    if (clientID != '') {
      this.clientID=clientID;
    } else {
      this.clientID= '';
    }
    if (start_date != '') {
      this.start_datek=start_date;
    } else {
      this.start_datek= '';
    }
    if (end_date != '') {
      this.end_datek=end_date;
    } else {
      this.end_datek= '';
    }
    this.row_updates = this.formBuilder.group({
      pax_id : ['', Validators.required],
      tax_id: ['', Validators.required],
      tkt_name: ['', Validators.required],
      tkt_number: ['', Validators.required],
      country_accounts: ['', Validators.required],
      status: ['', Validators.required],
      service: ['', Validators.required],
      date: ['', Validators.required],
      gds: ['', Validators.required],
      saleamount: ['', Validators.required],
      recived: ['', Validators.required],
      modeofpayment: ['', Validators.required],
      iata: ['', Validators.required],
      pnrno: ['', Validators.required],
    });
    
    
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    //console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 

this.rowsres = "";
this.loading = true;  
const be_client =  this.BE_Client;
const fr_client =  this.FR_Client;
const pt_agent =  this.PT_Agent;
const be_agent =  this.BE_agent;
const fr_agent =  this.FR_Agent;
const missing_tkt =   this.Missing_Tkts;
const all_entires = this.All_Entries;  
const pt_client = this.PT_Client;
const client_id = this.filterForm.value.client_id_get;
 

let param_value ='start_date='+ start_date +' &'+'pt_agent=' + pt_agent +'&'+ 'end_date=' + end_date +'&'+'admin=' + this.admin_id +'&'+
              'be_client=' + be_client +'&'+ 'fr_client=' + fr_client +'&'+
'be_agent=' + be_agent +'&'+ 'fr_agent=' + fr_agent +'&'+ 'missing_tkt=' + missing_tkt +'&'+
'client_id=' + client_id +'&'+ 'all_entires=' + all_entires +'&'+ 'pt_client=' + pt_client  


this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_data?'+param_value).subscribe((response: any) => {
this.rowsres = response.detail
this.loading = false;  
//this.account=this.country_table
this.rows = _.sortBy(this.rowsres, 'date').reverse(); //////code here sorting for mix array



   });

}

onClientData (ele) {
  this.email = "";
  this.client_id_get = ele.use_id;
  this.search_bar=false;  
  this.email = ele.email;
}
  
  onClientData_one (ele) {
    this.email = "";
    this.client_id_get_one = ele.use_id;
    this.search_bar_one=false; 
    this.email = ele.email;
    this.applyFilters();
  }



applyFilters() {
  console.log('PT_Client:', this.PT_Client);
  console.log('FR_Client:', this.FR_Client);
  console.log('BE_Client:', this.BE_Client);
  console.log('PT_Agent:', this.PT_Agent);
  console.log('BE_agent:', this.BE_agent);
  console.log('FR_Agent:', this.FR_Agent);
  console.log('Missing_Tkts:', this.Missing_Tkts);
  console.log('All_Entries:', this.All_Entries);

  console.log('clientID:', this.filterForm.value.client_id_get);
  console.log('start_date:', this.filterForm.value.start_date);
  console.log('end_date:', this.filterForm.value.end_date);

  localStorage.setItem('PT_Client', String(this.PT_Client));
  localStorage.setItem('FR_Client', String(this.FR_Client));
  localStorage.setItem('BE_Client', String(this.BE_Client));
  localStorage.setItem('PT_Agent', String(this.PT_Agent));
  localStorage.setItem('BE_agent', String(this.BE_agent));
  localStorage.setItem('FR_Agent', String(this.FR_Agent));
  localStorage.setItem('Missing_Tkts', String(this.Missing_Tkts));
  localStorage.setItem('All_Entries', String(this.All_Entries));

 // localStorage.setItem('clientID', this.filterForm.value.client_id_get);
  //localStorage.setItem('start_date', this.filterForm.value.start_date);
  //localStorage.setItem('end_date', this.filterForm.value.end_date);

  this.loading = true;  
  const be_client =  this.BE_Client;
  const fr_client =  this.FR_Client;
  const pt_agent =  this.PT_Agent;
  const be_agent =  this.BE_agent;
  const fr_agent =  this.FR_Agent;
  const missing_tkt =   this.Missing_Tkts;
  const all_entires = this.All_Entries;  
  const pt_client = this.PT_Client;
 const client_id = this.filterForm.value.client_id_get;
 const start_date = this.filterForm.value.start_date;
 const end_date = this.filterForm.value.end_date;

  let param_value ='start_date='+ start_date +' &'+'pt_agent=' + pt_agent +'&'+ 'end_date=' + end_date +'&'+'admin=' + this.admin_id +'&'+
                'be_client=' + be_client +'&'+ 'fr_client=' + fr_client +'&'+
'be_agent=' + be_agent +'&'+ 'fr_agent=' + fr_agent +'&'+ 'missing_tkt=' + missing_tkt +'&'+
'client_id=' + client_id +'&'+ 'all_entires=' + all_entires +'&'+ 'pt_client=' + pt_client  


this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_data?'+param_value).subscribe((response: any) => {
  this.rowsres=response.detail
  this.loading = false;  
  this.account=this.country_table
  this.rows = _.sortBy(this.rowsres, 'date').reverse(); //////code here sorting for mix array




});
}

nav_bar_search() {

  const be_client =  this.search_bar_form.value.be_client;
 const fr_client =  this.search_bar_form.value.fr_client;
 const pt_agent =  this.search_bar_form.value.pt_agent;
 const be_agent =  this.search_bar_form.value.be_agent;
 const fr_agent =  this.search_bar_form.value.fr_agent;
 const missing_tkt =   this.search_bar_form.value.missing_tkt;
 const all_entires = this.search_bar_form.value.all_entires;  
 const pt_client = this.search_bar_form.value.pt_client;
const client_id = ""
const start_date = this.search_bar_form.value.start_date;
const end_date = this.search_bar_form.value.end_date;

this.loading = true;  
this.country_table = this.route.snapshot.paramMap.get('country');
this.admin_id = 1;
const payload={table_name:this.country_table,admin_id:this.admin_id}


let param_value ='start_date='+ start_date +' &'+'pt_agent=' + pt_agent +'&'+ 'end_date=' + end_date +'&'+'admin=' + this.admin_id +'&'+
                'be_client=' + be_client +'&'+ 'fr_client=' + fr_client +'&'+
'be_agent=' + be_agent +'&'+ 'fr_agent=' + fr_agent +'&'+ 'missing_tkt=' + missing_tkt +'&'+
'client_id=' + client_id +'&'+ 'all_entires=' + all_entires +'&'+ 'pt_client=' + pt_client  


this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_data?'+param_value).subscribe((response: any) => {
  this.rowsres=response.detail
  this.loading = false;  
  this.account=this.country_table
  this.rows = _.sortBy(this.rowsres, 'date').reverse(); //////code here sorting for mix array

});

}



get_data(user) {
  this.email = "";

this.client_id = ""
this.gds = ""
this.pnrno =""
this.tkt_number = ""
this.tkt_name = ""
this.status = ""
this.saleamount = ""
this.recived = ""
this.modeofpayment =""
this.service = ""
this.country_accounts = ""
this.tax_id = ""
this.remarks = ""
this.iata = ""
this.invoicedata = ""
 ///send here line no for get data
// console.log(this.status)
this.pnrbar = 0 ;
this.country = user.table_code;
const payload = {lineno:user.id,table:this.country};
this.row_id = user.id;
this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row', payload).subscribe((response: any) => {
//console.log(this.pnrbar)
if (this.pnrbar ==0){
  this.client_id_get = response?.data?.client_id; 
this.client_id = response?.data?.client_id; 
this.gds = response?.data?.gds; 
this.pnrno = response?.data?.pnrno; 
this.tkt_number = response?.data?.tkt_number; 
this.tkt_name = response?.data?.tkt_name; 
this.status = response?.data?.status;
this.saleamount = response?.data?.saleamount; 
this.recived = response?.data?.recived; 
this.modeofpayment = response?.data?.modeofpayment; 
this.service = response?.data?.service; 
this.country_accounts = response?.data?.country_accounts; 
this.tax_id = response?.data?.tax_id; 
this.remarks = response.data?.remarks; 
this.iata = response?.data?.iata; 
this.invoicedata = response?.data?.invoice; 
 
}


}
);
}

/*end_date() {
sessionStorage.setItem('end_date', JSON.stringify("true"));
console.log("dddd")
}*/
/*start_date() {
  sessionStorage.setItem('start_date', JSON.stringify("true"));
  console.log("dddd")
  }*/

  client_ids() {
    sessionStorage.setItem('client_ids', JSON.stringify("true"));
    console.log("dddd")
    }
    pt_clients() {
      sessionStorage.setItem('pt_clients', JSON.stringify("true"));
      console.log("dddd")
      }
      all_entires() {
        sessionStorage.setItem('all_entires', JSON.stringify("true"));
        console.log("dddd")
        }
        missing_tkt() {
          sessionStorage.setItem('missing_tkt', JSON.stringify("true"));
          console.log("dddd")
          }
          fr_agent() {
            sessionStorage.setItem('fr_agent', JSON.stringify("true"));
            console.log("dddd")
            }
            be_agent() {
              sessionStorage.setItem('be_agent', JSON.stringify("true"));
              console.log("dddd")
              }
              pt_agent() {
                sessionStorage.setItem('pt_agent', JSON.stringify("ffff"));
                console.log("dddd")
                }
                fr_client() {
                  sessionStorage.setItem('fr_client', JSON.stringify("fftrueff"));
                  console.log("dddd")
                  }
                  be_client() {
                    sessionStorage.setItem('be_client', JSON.stringify("ffff"));
                    console.log("dddd")
                    }


  search_client_one() {
    if (this.filterForm.value.client_id_get.length > 0) {

      this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_getclient?text=' + this.filterForm.value.client_id_get).subscribe((response: any) => {
        if (response == null) {
          this.search_bar_one = false
        } else {
          this.search_bar_one = true
          this.clientlist_one = response

        }
      });

    }
    else {
      this.search_bar_one = false
    }
  }

search_client(){
  if (this.SerachclientDeatilsFrom.value.client_id_get.length > 0){ 
    
    this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_getclient?text='+this.SerachclientDeatilsFrom.value.client_id_get).subscribe((response: any) => {
    if(response == null){
      this.search_bar=false
  } else {
    this.search_bar=true
  this.clientlist=response
}    
 });

}
else{
  this.search_bar=false
}
}



onClientclick (ele) {
  this.client_use_id = ele.use_id;
  this.search_bar=false; 
  this.admin_id = 1;
  this.getaccount()
}

getaccount (){
  const startdate = "1900-01-01";
  const start = formatDate(startdate, 'yyyy-MM-dd', 'en-US');
  const currentDate = new Date();
  const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  let param_value = this.client_use_id  + '&' + 'fromDate=' + start +'&'+ 'toDate=' + cValue +'&'+'admin=' + this.admin_id 

  //this.router.navigate(['/back-end/print-ticket-show/'+ row.pnr + '/' + row.id + '/' + row.client_id+ '/' + this.account])
  //https://www.kelvintravel.com/api_agent/agent/Api_mylist?client_id=1&fromDate=2024-2-25&toDate=2024-2-28&mode=null

 // 'link' : environment.apiUrl + 'payment-by-link/' + client_id + '/' + pnr + '/' + price,
  this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_data?client_id='+param_value).subscribe((response: any) => {
  this.rows= response.detail;
  this.account= response.accounts;
});


}
  

jpg_data(user) {
  this.imageViewData = null;
  this.loaderi = true;
  this.custmer_ref = user.custmer_ref;
  const payload = { custmer_ref:this.custmer_ref,id:this.localUserIds};   
  this.http.post('https://www.kelvintravel.com/api_agent/flight/Api_base/',payload).subscribe((response: any) => {   
    this.imageViewData = response;
    this.loaderi = false;
   });
}

pt_invoice(user){
  this.lineno = user.id;
  this.tax_code = user.customer_id;
   
  this.table = "pt";
  const payload = {lineno:this.lineno,table:this.table};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row',payload).subscribe((response: any) => {
    
    this.date = response.data?.date;
    this.modeofpayment = response.data?.modeofpayment;
    this.pnrno = response.data?.pnrno;
    this.recived = response.data?.recived;
    this.pax_id = response.data?.client_id;
    this.tax_id = response.data?.tax_id;
    this.tktnameforbill = response.data?.tktnameforbill; 
    this.saleamount = response.data?.saleamount; 
    this.gds = response.data?.gds; 
    this.iata = response.data?.iata; 
  });  
  const payload_moloni = {portugal_id:this.tax_code,server:this.table};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_client/get_client',payload_moloni).subscribe((response: any) => {
   this.molonidata = response.detail[0];
});
}

  send_to_moloni() {
    const payload_send = {
      client_id: this.row_updates.value.client_id,
      country_accounts: this.row_updates.value.country_accounts,
      gds: this.row_updates.value.gds,
      iata: this.row_updates.value.iata,
      modeofpayment: this.row_updates.value.modeofpayment,
      pnrno: this.row_updates.value.pnrno,
      recived: this.row_updates.value.recived,
      remarks: this.row_updates.value.remarks,
      saleamount: this.row_updates.value.saleamount,
      service: this.row_updates.value.service,
      status: this.row_updates.value.status,
      tax_id: this.row_updates.value.tax_id,
      tkt_name: this.row_updates.value.tkt_name,
      tkt_number: this.row_updates.value.tkt_number
    }

   
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_client/get_client',payload_send).subscribe((response: any) => {
   this.molonidata = response.detail[0];
 

});   
}

update_accounts(){
  //this.country = 1;
  const payload = {client_id_get: this.userForm.value.client_id_get,
    country_accounts: this.userForm.value.country_accounts,
    gds: this.userForm.value.gds,
    iata: this.userForm.value.iata,
    modeofpayment: this.userForm.value.modeofpayment,
    pnrno: this.userForm.value.pnrno,
    recived: this.userForm.value.recived,
    remarks: this.userForm.value.remarkss,
    saleamount: this.userForm.value.saleamount,
    service: this.userForm.value.service,
    status: this.userForm.value.status,
    tax_id: this.userForm.value.tax_id,
    invoicedata: this.userForm.value.invoicedata,
    tkt_name: this.userForm.value.tkt_name,
    row_id:this.row_id,
    table:this.country,
    tkt_number: this.userForm.value.tkt_number};
  
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_update_row/update_data', payload).subscribe((response: any) => {
      if (response.status == 1){
         
     this.res1 = response.error?.update;
     this.res2 = response.error?.update1;
     this.res3 = response.error?.update2;
     this.res4 = response.error?.update3;
    // window.location.reload();

      } if (response.status == 0){
        this.res1 = response.error;

      }

     }
     );

     this.applyFilters();
}



}
 
 
