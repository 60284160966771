

<div class="container-xxl flex-grow-1 container-p-y">
    
  <div class="app-ecommerce">
    <!-- Add Product -->
    <div class="d-flex flex-wrap justify-content-between align-items-center mb-3">
      <div class="d-flex flex-column justify-content-center">
        <h4 class="mb-1 mt-3">Add a new Request</h4>

      </div>
      <div class="d-flex align-content-center flex-wrap gap-3">
        <button class="btn btn-label-secondary" (click)="discard()">Discard</button>
        <button class="btn btn-label-warning" (click)="submit_as_draft()">Save draft</button>
        <button (click)="submit()" type="submit" class="btn btn-danger"  [disabled]="SerachclientDeatilsFrom.invalid">
          Submit Request</button>
      </div>
    </div>

    <div class="row">
      <!-- First column-->
      <div class="col-12 col-lg-8">
        <!-- Product Information -->
        <div class="card mb-4">
          <div class="card-header">
            <h5 class="card-tile mb-0">Product information</h5>
          </div>
          <div class="card-body" >


            <form [formGroup]="SerachclientDeatilsFrom">
            <div class="row mb-3">
              <div class="col">
            <label class="form-label" for="ecommerce-product-name">Client Name</label>
              <input (keyup)="search_client()"
                type="text" ngModel="{{client_use_id}}"
                class="form-control"
                id="ecommerce-product-name"
                placeholder="Client Name"
                formControlName="client"
                aria-label="Product title" />
                <div class="list-group" *ngIf="search_bar">
                  <ul class="gzVnrw jIeOgV AutoComplete__List">

                  <div *ngFor="let item of clientlist">
                    <li class="htoDjs cLbWEz AutoComplete__ListItem  AutoComplete__ListItem " (click)="onClientData(item)">
                      <div
                        style="display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #d9dee3;padding-bottom: 10px;">
                        <div>
                          <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Name: <span
                              style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item.name}}</span> </div>
                          <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Email: <span
                              style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item.email}}</span> </div>
                        </div>
                        <div>
                          <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Phone: <span
                              style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item.phone}}</span> </div>
                          <div style="font-size: 17px;font-weight: bold;color: rgb(105 123 141);">Vat: <span
                              style="font-size: 17px;font-weight: normal;color: rgb(105 123 141);">{{item.vat}}</span> </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>

            </div>



            </div>
            <div class="row mb-3">

              <div class="col">
                <label class="form-label" for="ecommerce-product-barcode">PNR</label>
                <input
                  type="text"
                  class="form-control"
                  id="ecommerce-product-barcode"
                  placeholder="PNR" ngModel="{{draft_pnr}}"
                  formControlName="pnr"/>
                  <div *ngIf="pnr.invalid && pnr.touched">
                    <small class="text-danger" *ngIf="pnr.errors?.required">
    
                      Sale Value is
                      Required</small>
                  </div>
              </div>
                       
              <div class="col">
                <label class="form-label" for="ecommerce-product-barcode">Service</label>
                <select formControlName="service" class="form-control">
                  <option ngModel="{{draft_service}}">{{draft_service}}</option>
                  
                  <option *ngFor="let sta of serviceItems" [ngValue]="sta">{{sta}}</option>
                </select>
                <div *ngIf="service.invalid && service.touched">
                  <small class="text-danger" *ngIf="service.errors?.required">
  
                    Sale Value is
                    Required</small>
                </div>
              </div>
            </div>

            <div class="row mb-3">
            <div class="col">
              <label class="form-label" for="ecommerce-product-sku">Sale Value</label>
              <input
                type="number" ngModel="{{draft_sale_value}}"
                class="form-control"
                id="ecommerce-product-sku"
                placeholder="Sale Value"
                formControlName="sale_value"/>
                <div *ngIf="sale_value.invalid && sale_value.touched">
                  <small class="text-danger" *ngIf="sale_value.errors?.required">
  
                    Sale Value is
                    Required</small>
                </div>
            </div>
          </div>

            <div class="row mb-3">
              <div class="col">
                <label class="form-label" for="ecommerce-product-sku">Choose Office</label>
                  <select formControlName="invoicecountry" class="form-control">
                    <option ngModel="{{draft_invoicecountry}}">{{draft_invoicecountry}}</option>
                    <option *ngFor="let sta of accounts" [ngValue]="sta">{{sta}}</option>
                  </select>
                  <div *ngIf="invoicecountry.invalid && invoicecountry.touched">
                    <small class="text-danger" *ngIf="invoicecountry.errors?.required">
    
                      Sale Value is
                      Required</small>
                  </div>
              </div>
              <div class="col">
                <label class="form-label" for="ecommerce-product-barcode">Need Invoice</label>
                  <select formControlName="country_account" class="form-control">
                    <option ngModel="{{draft_invoicecountry}}">{{draft_invoicecountry}}</option>
                    <option *ngFor="let sta of invoices" [ngValue]="sta">{{sta}}</option>
                  </select>
                  <div *ngIf="country_account.invalid && country_account.touched">
                    <small class="text-danger" *ngIf="country_account.errors?.required">
    
                      Sale Value is
                      Required</small>
                  </div>
              </div>

            </div>
            <!-- Description -->
            <div>
              <label class="form-label">Description <span class="text-muted">(Optional)</span></label>
              <ngx-quill ngModel="{{draft_description}}" #editor class="editor" formControlName="description" modules="modules"
                (contentChanged)="logChange($event);"></ngx-quill>
            
            </div>






          </form>
          </div>
        </div>


        <!-- /Inventory -->
      </div>
      <!-- /Second column -->

      <!-- Second column -->
      <div class="col-12 col-lg-4">

        
        <div class="card mb-4">
          <div class="card-header" style=" display: flex; justify-content: space-between; ">
            <div><h5 class="card-title mb-0">Payment Methods</h5> </div>
            <div class="badge bg-label-danger ">{{paymethodFrom.value.payment_method}}</div>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- Navigation -->
              <div class="col-12 col-md-6 mx-auto card-separator">
                <div class="d-flex justify-content-between flex-column mb-3 mb-md-0 pe-md-0">
                  <ul class="nav nav-align-left nav-pills flex-column">
                    <li class="nav-item">
                      <button class="nav-link active" (click)="change_payment_method('no_payment')" data-bs-toggle="tab" data-bs-target="#no_payment">
                        <i class="bx bx-cube me-2"></i>
                        <span class="align-middle">No Pay</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" data-bs-toggle="tab" (click)="change_payment_method('Bank')" data-bs-target="#Bank">
                        <i class="bx bx-car me-2"></i>
                        <span class="align-middle">Bank</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" (click)="change_payment_method('cash')" data-bs-toggle="tab" data-bs-target="#Cash">
                        <i class="bx bx-cube me-2"></i>
                        <span class="align-middle">Cash</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" (click)="change_payment_method('BELcash')" data-bs-toggle="tab" data-bs-target="#BelCash">
                        <i class="bx bx-globe me-2"></i>
                        <span class="align-middle">Bel Cash</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" (click)="change_payment_method('Visa')" data-bs-toggle="tab" data-bs-target="#Visa">
                        <i class="bx bx-globe me-2"></i>
                        <span class="align-middle">Visa</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" (click)="change_payment_method('Entity')" data-bs-toggle="tab" data-bs-target="#Entity">
                        <i class="bx bx-link me-2"></i>
                        <span class="align-middle">Entity</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" (click)="change_payment_method('CCard')" data-bs-toggle="tab" data-bs-target="#Card">
                        <i class="bx bx-lock me-2"></i>
                        <span class="align-middle">CCard</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" (click)="change_payment_method('Stripe')" data-bs-toggle="tab" data-bs-target="#Stripe">
                        <i class="bx bx-lock me-2"></i>
                        <span class="align-middle">Stripe</span>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link" (click)="change_payment_method('Bancontact')" data-bs-toggle="tab" data-bs-target="#Bancontact">
                        <i class="bx bx-lock me-2"></i>
                        <span class="align-middle">Bancontact</span>
                      </button>
                    </li>
  

            </ul>
                </div>
              </div>

              
              <div class="col-12 col-md-6 pt-4 pt-md-0">
                <div class="tab-content p-0 pe-md-6 ps-md-0">

                  <div class="tab-pane fade show" id="Cash" role="tabpanel">
                    <h5>Cash Received</h5>
                  
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received" ngModel={{draft_amount_received}}/>
                        </form>
                      </div>
                    
                    </div>
                  </div>

                  <div class="tab-pane fade show" id="Bank" role="tabpanel">
                  
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <label>Choose Bank</label>
                        <div class="col-12 col-sm-12"style=" display: flex; flex-wrap: wrap; justify-content: space-between; ">
                        <button type="button" (click)="change_payment_method('CGD')"  class="btn btn-icon btn-outline-primary my-2"><img style="width: 100%;" class="tf-icons bx bxl-danger" src="https://cdn.worldvectorlogo.com/logos/cgd-4.svg"></button>
                        <button type="button" (click)="change_payment_method('BPI')"  class="btn btn-icon btn-outline-primary my-2"><img style="width: 100%;" class="tf-icons bx bxl-danger" src="https://static.brandirectory.com/logos/BPIA001_logo_02_jpg.jpg"></button>
                        <button type="button" (click)="change_payment_method('Novo')"  class="btn btn-icon btn-outline-primary my-2"><img style="width: 100%;" class="tf-icons bx bxl-danger" src="https://res.cloudinary.com/apideck/image/upload/v1591439765/icons/novobanco-es.jpg"></button>
                        <button type="button" (click)="change_payment_method('BCP')"  class="btn btn-icon btn-outline-primary my-2"><img style="width: 100%;" class="tf-icons bx bxl-danger" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSo9SO52WwsUnj8O5ShHOleTU2UHjEJFLXcQ&usqp=CAU"></button>
                        <button type="button" (click)="change_payment_method('KBC')"  class="btn btn-icon btn-outline-primary my-2"><img style="width: 100%;" class="tf-icons bx bxl-danger" src="https://capitant.be/media/KBC-700x525.png.webp"></button>
                        <button type="button" (click)="change_payment_method('BNP')"  class="btn btn-icon btn-outline-primary my-2"><img style="width: 100%;" class="tf-icons bx bxl-danger" src="https://casablancafinancecity.com/wp-content/uploads/2018/02/BNP-Paribas-Logo.jpg"></button>
                        <button type="button" (click)="change_payment_method('Revoutl_Fr')"  class="btn btn-icon btn-outline-primary my-2">
                          <img style="width: 100%;" 
                          class="tf-icons bx bxl-danger" src="https://cdn.icon-icons.com/icons2/2699/PNG/512/revolut_logo_icon_168016.png">
                        </button>
                     
                      </div></div>

                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                          


                          <label>Bank Received</label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received" ngModel={{draft_amount_received}}/>
                          <div *ngIf="amount_received.invalid && amount_received.touched">
                            <small class="text-danger" *ngIf="amount_received.errors?.required">
            
                              Sale Value is
                              Required</small>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>

                  <div class="tab-pane fade" id="Bancontact" role="tabpanel">
                    <h5 class="mb-4">Machine</h5>
                   
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input 
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"  ngModel={{draft_amount_received}}/>
                        </form>
                      </div>
                    </div>
                  </div>


                  <div class="tab-pane fade" id="BelCash" role="tabpanel">
                    <h5 class="mb-4">Be Cash</h5>
                   
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input 
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"  ngModel={{draft_amount_received}}/>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="Visa" role="tabpanel">
                    <h5 class="mb-4">Visa</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"  ngModel={{draft_amount_received}}/>
                          <div *ngIf="amount_received.invalid && amount_received.touched">
                            <small class="text-danger" *ngIf="amount_received.errors?.required">
            
                              Sale Value is
                              Required</small>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>

                  <div class="tab-pane fade" id="Entity" role="tabpanel">
                    <h5 class="mb-4">Entity</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"  ngModel={{draft_amount_received}}
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"/>
                          <div *ngIf="amount_received.invalid && amount_received.touched">
                            <small class="text-danger" *ngIf="amount_received.errors?.required">
            
                              Sale Value is
                              Required</small>
                          </div>
                        </form>
                      </div>
                      <div class="col-12 col-sm-12">
                        <button class="btn btn-primary" (click)="multiBanco()" data-bs-toggle="modal" data-bs-target="#entity"><i class="bx bx-check me-2"></i>Confirm</button>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="Card" role="tabpanel">
                    <h5 class="mb-4">Card</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"  ngModel={{draft_amount_received}}
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"/>
                          <div *ngIf="amount_received.invalid && amount_received.touched">
                            <small class="text-danger" *ngIf="amount_received.errors?.required">
            
                              Sale Value is
                              Required</small>
                          </div>
                        </form>
                      </div>
                      <div class="col-12 col-sm-12">
                        <button class="btn btn-primary" (click)="gernateUrl()" data-bs-toggle="modal" data-bs-target="#ccb"><i class="bx bx-check me-2"></i>Confirm</button>
                      </div>
                    </div>
                  </div>

                  
                  <div class="tab-pane fade" id="Stripe" role="tabpanel">
                    <h5 class="mb-4">QR code payment</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"  ngModel={{draft_amount_received}}
                          id="ecommerce-product-stock"
                          placeholder="Amount"
                          aria-label="Quantity" formControlName="amount_received"/>
                          <div *ngIf="amount_received.invalid && amount_received.touched">
                            <small class="text-danger" *ngIf="amount_received.errors?.required">
            
                              Sale Value is
                              Required</small>
                          </div>
                        </form>
                      </div>
                      <div class="col-12 col-sm-12">
                        <button class="btn btn-primary" (click)="gernateqr_code()" data-bs-toggle="modal" data-bs-target="#ccb">
                          <i class="bx bx-check me-2"></i>Confirm</button>
                      </div>
                    </div>
                  </div>

                  <!--
                  <div class="tab-pane fade" id="BCP" role="tabpanel">
                    <h5 class="mb-4">BCP</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"/>
                        </form>
                      </div>
                      <div class="col-12 col-sm-12">
                        <button class="btn btn-primary"><i class="bx bx-check me-2"></i>Confirm</button>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="Caixa" role="tabpanel">
                    <h5 class="mb-4">Caixa</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"/>
                        </form>
                      </div>
                      <div class="col-12 col-sm-12">
                        <button class="btn btn-primary"><i class="bx bx-check me-2"></i>Confirm</button>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="Novo" role="tabpanel">
                    <h5 class="mb-4">Novo</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"/>
                        </form>
                      </div>
                      <div class="col-12 col-sm-12">
                        <button class="btn btn-primary"><i class="bx bx-check me-2"></i>Confirm</button>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="BPI" role="tabpanel">
                    <h5 class="mb-4">BPI</h5>
                    <label class="form-label" for="ecommerce-product-stock">Amount Received</label>
                    <div class="row mb-3 g-3">
                      <div class="col-12 col-sm-12">
                        <form [formGroup]="SerachclientDeatilsFrom">
                        <input
                          type="number"
                          class="form-control"
                          id="ecommerce-product-stock"
                          placeholder="Amount Received"
                          aria-label="Quantity" formControlName="amount_received"/>
                        </form>
                      </div>
                      <div class="col-12 col-sm-12">
                        <button class="btn btn-primary"><i class="bx bx-check me-2"></i>Confirm</button>
                      </div>
                    </div>
                  </div> -->

                </div>
              </div>
              <!-- /Options-->
            </div>
          </div>
        </div>
        <div class="card mb-4" *ngIf="client_use_id">
          <div class="card-header">
            <h6 class="card-title m-0">Customer details</h6>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-start align-items-center mb-4">
              <div class="avatar me-2">
                <img src="../../assets/img/avatars/1.png" alt="Avatar" class="rounded-circle">
              </div>
              <div class="d-flex flex-column">
                <a href="app-user-view-account.html" class="text-body text-nowrap">
                  <h6 class="mb-0">{{client_name}}</h6>
                </a>
                <small class="text-muted">Customer ID: #{{client_use_id}}</small></div>
            </div>

            <div class="d-flex justify-content-between">
              <h6>Contact info</h6>
              <h6><a href=" javascript:void(0)" data-bs-toggle="modal" data-bs-target="#editUser">Edit</a></h6>
            </div>
            <p class=" mb-1">Email: {{client_email}}</p>
            <p class=" mb-0">Mobile: {{client_phone_Id}}</p>
            <p class=" mb-0">Code: {{client_code}}</p>
          </div>
        </div>

        <div class="card  mb-4" >
          <div class="card-header align-items-center">
            <h5 class="card-action-title mb-0">Draft Requests</h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0">
              <li class="mb-3" *ngFor="let request of draft_requests">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-start">
                    <div class="avatar me-3">
                      <button (click)="load_draft(request.slot)" type="button" class="btn rounded-pill btn-icon btn-label-primary">
                        <span class="tf-icons bx bx-pie-chart-alt"></span>
                      </button>
                    </div>
                    <div class="me-2">
                      <h6 class="mb-0">{{request.userId}}</h6>
                      <small class="text-muted">{{request.saleamount}} | {{request.service}}</small>
                    </div>
                  </div>
                  <div class="ms-auto">
                    <span class="badge bg-label-danger">{{request.pnr}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <!-- /Second column -->
    </div>
  </div>


</div>

<div >
  <!-- Display request details here 
  <p>{{ request.mode_Of_Payment}}</p>-->
</div>


<div class="modal animate__animated animate__flipInY" id="ccb" tabindex="-1" aria-modal="true" role="dialog" >
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">Credit Card Link</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body px-0">
        <div class="row pl-3"> 

          <div class="col-md-12" style="line-break: anywhere;">
            <p>{{inviteLink}} </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

  <div class="modal animate__animated animate__flipInY" id="entity" tabindex="-1" aria-modal="true" role="dialog" >
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel1">Multi Banco</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body px-0">
          <div class="row pl-3">
            <div class="col-md-3">
              <img class="card-img" src="https://banner2.cleanpng.com/20180810/vcs/kisspng-vector-graphics-multibanco-computer-icons-logo-por-pegasus-mtodos-de-pagamento-5b6d61ae62f150.4096152915338950864053.jpg" alt="Multibanco"
                style="height:100px; width:100px ">
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-1 px-0"></div>
                <div class="col-md-5"><b>Entity</b></div>
                <div class="col-md-6"> {{multiBancoData?.entity}}</div>

                <div class="col-md-1 px-0"></div>
                <div class="col-md-5"><b>Reference</b></div>
                <div class="col-md-6">{{multiBancoData?.reference}}</div>

                <div class="col-md-1 px-0"></div>
                <div class="col-md-5"><b>Amount</b></div>
                <div class="col-md-6" *ngIf="multiBancoData?.amount">
                  {{multiBancoData?.amount}}</div>
                <div class="col-md-6" *ngIf="multiBancoData?.value">
                  {{multiBancoData?.value}}</div>

                  <div class="col-md-1 px-0"></div>
                <div class="col-md-5"><b>Status</b></div>
                <div class="col-md-6 text-warning">
                  {{multiBancoData?.status}}</div>
  
              </div>
  
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>