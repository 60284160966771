
      <nav class="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar" >
        <div class="container-xxl">
          <div class="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
<span class="app-brand-text demo menu-text fw-bold">
<a href="" class="app-brand-link">Khalsa Travel</a></span>
            <a routerLink="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
              <i class="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
          </div>
          
          


          <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <a class="nav-item nav-link px-0 me-xl-4" routerLink="javascript:void(0)">
              <i class="bx bx-menu bx-sm"></i>
            </a>
          </div>
          

          <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
            <ul class="navbar-nav flex-row align-items-center ms-auto">
              <form [formGroup]="invoice">
                  <div class="input-group input-group-merge">
                <input  formControlName="pnr_value" type="text" class="form-control" placeholder="Search PNR..." aria-label="Search..." aria-describedby="basic-addon-search31">
                </div>  
              </form>
                  <li class="nav-item navbar-search-wrapper me-2 me-xl-0">
                    <button (click)="search_pnr()" class="nav-link search-toggler" >
                       <i class="bx bx-search bx-sm"></i>
                </button>
              </li>


              
    
       <li class="nav-item dropdown-language dropdown me-2 me-xl-0">
                <a class="nav-link dropdown-toggle hide-arrow" routerLink="javascript:void(0);" data-bs-toggle="dropdown">
                  <i class="bx bx-globe bx-sm"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a class="dropdown-item" routerLink="javascript:void(0);" data-language="en" data-text-direction="ltr">
                      <span class="align-middle">English</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="javascript:void(0);" data-language="fr" data-text-direction="ltr">
                      <span class="align-middle">French</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="javascript:void(0);" data-language="ar" data-text-direction="rtl">
                      <span class="align-middle">Arabic</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="javascript:void(0);" data-language="de" data-text-direction="ltr">
                      <span class="align-middle">German</span>
                    </a>
                  </li>
                </ul>
              </li>

              
              <li class="nav-item dropdown-shortcuts navbar-dropdown dropdown me-2 me-xl-0">
                <a
                  class="nav-link dropdown-toggle hide-arrow"
                  routerLink="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false">
                  <i class="bx bx-grid-alt bx-sm"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end py-0">
                  <div class="dropdown-menu-header border-bottom">
                    <div class="dropdown-header d-flex align-items-center py-3">
                      <h5 class="text-body mb-0 me-auto">Shortcuts</h5>
                      <a
                        routerLink="javascript:void(0)"
                        class="dropdown-shortcuts-add text-body"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Add shortcuts"
                        ><i class="bx bx-sm bx-plus-circle"></i
                      ></a>
                    </div>
                  </div>
                  <div class="dropdown-shortcuts-list scrollable-container">
                    <div class="row row-bordered overflow-visible g-0">
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-calendar fs-4"></i>
                        </span>
                        <a routerLink="app-calendar.html" class="stretched-link">Calendar</a>
                        <small class="text-muted mb-0">Appointments</small>
                      </div>
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-food-menu fs-4"></i>
                        </span>
                        <a routerLink="app-invoice-list.html" class="stretched-link">Invoice App</a>
                        <small class="text-muted mb-0">Manage Accounts</small>
                      </div>
                    </div>
                    <div class="row row-bordered overflow-visible g-0">
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-user fs-4"></i>
                        </span>
                        <a routerLink="app-user-list.html" class="stretched-link">User App</a>
                        <small class="text-muted mb-0">Manage Users</small>
                      </div>
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-check-shield fs-4"></i>
                        </span>
                        <a routerLink="app-access-roles.html" class="stretched-link">Role Management</a>
                        <small class="text-muted mb-0">Permission</small>
                      </div>
                    </div>
                    <div class="row row-bordered overflow-visible g-0">
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-pie-chart-alt-2 fs-4"></i>
                        </span>
                        <a routerLink="index.html" class="stretched-link">Dashboard</a>
                        <small class="text-muted mb-0">User Profile</small>
                      </div>
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-cog fs-4"></i>
                        </span>
                        <a routerLink="pages-account-settings-account.html" class="stretched-link">Setting</a>
                        <small class="text-muted mb-0">Account Settings</small>
                      </div>
                    </div>
                    <div class="row row-bordered overflow-visible g-0">
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-help-circle fs-4"></i>
                        </span>
                        <a routerLink="pages-faq.html" class="stretched-link">FAQs</a>
                        <small class="text-muted mb-0">FAQs & Articles</small>
                      </div>
                      <div class="dropdown-shortcuts-item col">
                        <span class="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
                          <i class="bx bx-window-open fs-4"></i>
                        </span>
                        <a routerLink="modal-examples.html" class="stretched-link">Modals</a>
                        <small class="text-muted mb-0">Useful Popups</small>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              
              <li class="nav-item dropdown-style-switcher dropdown me-2 me-xl-0">
                <a class="nav-link dropdown-toggle hide-arrow" routerLink="javascript:void(0);" data-bs-toggle="dropdown">
                  <i class="bx bx-sm"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-styles">
                  <li>
                    <a class="dropdown-item" routerLink="javascript:void(0);" data-theme="light">
                      <span class="align-middle"><i class="bx bx-sun me-2"></i>Light</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="javascript:void(0);" data-theme="dark">
                      <span class="align-middle"><i class="bx bx-moon me-2"></i>Dark</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="javascript:void(0);" data-theme="system">
                      <span class="align-middle"><i class="bx bx-desktop me-2"></i>System</span>
                    </a>
                  </li>
                </ul>
              </li>

              
              <li class="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                <a
                  class="nav-link dropdown-toggle hide-arrow"
                  routerLink="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false">
                  <i class="bx bx-bell bx-sm"></i>
                  <span class="badge bg-danger rounded-pill badge-notifications">5</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-end py-0">
                  <li class="dropdown-menu-header border-bottom">
                    <div class="dropdown-header d-flex align-items-center py-3">
                      <h5 class="text-body mb-0 me-auto">Notification</h5>
                      <a
                        routerLink="javascript:void(0)"
                        class="dropdown-notifications-all text-body"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Mark all as read"
                        ><i class="bx fs-4 bx-envelope-open"></i
                      ></a>
                    </div>
                  </li>
                  <li class="dropdown-notifications-list scrollable-container">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item list-group-item-action dropdown-notifications-item">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <img src="../../assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle" />
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">Congratulation Lettie 🎉</h6>
                            <p class="mb-0">Won the monthly best seller gold badge</p>
                            <small class="text-muted">1h ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <span class="avatar-initial rounded-circle bg-label-danger">CF</span>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">Charles Franklin</h6>
                            <p class="mb-0">Accepted your connection</p>
                            <small class="text-muted">12hr ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <img src="../../assets/img/avatars/2.png" alt class="w-px-40 h-auto rounded-circle" />
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">New Message ✉️</h6>
                            <p class="mb-0">You have new message from Natalie</p>
                            <small class="text-muted">1h ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <span class="avatar-initial rounded-circle bg-label-success"
                                ><i class="bx bx-cart"></i
                              ></span>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">Whoo! You have new order 🛒</h6>
                            <p class="mb-0">ACME Inc. made new order $1,154</p>
                            <small class="text-muted">1 day ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <img src="../../assets/img/avatars/9.png" alt class="w-px-40 h-auto rounded-circle" />
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">Application has been approved 🚀</h6>
                            <p class="mb-0">Your ABC project application has been approved.</p>
                            <small class="text-muted">2 days ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <span class="avatar-initial rounded-circle bg-label-success"
                                ><i class="bx bx-pie-chart-alt"></i
                              ></span>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">Monthly report is generated</h6>
                            <p class="mb-0">July monthly financial report is generated</p>
                            <small class="text-muted">3 days ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <img src="../../assets/img/avatars/5.png" alt class="w-px-40 h-auto rounded-circle" />
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">Send connection request</h6>
                            <p class="mb-0">Peter sent you connection request</p>
                            <small class="text-muted">4 days ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <img src="../../assets/img/avatars/6.png" alt class="w-px-40 h-auto rounded-circle" />
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">New message from Jane</h6>
                            <p class="mb-0">Your have new message from Jane</p>
                            <small class="text-muted">5 days ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                              <span class="avatar-initial rounded-circle bg-label-warning"
                                ><i class="bx bx-error"></i
                              ></span>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <h6 class="mb-1">CPU is running high</h6>
                            <p class="mb-0">CPU Utilization Percent is currently at 88.63%,</p>
                            <small class="text-muted">5 days ago</small>
                          </div>
                          <div class="flex-shrink-0 dropdown-notifications-actions">
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-read"
                              ><span class="badge badge-dot"></span
                            ></a>
                            <a routerLink="javascript:void(0)" class="dropdown-notifications-archive"
                              ><span class="bx bx-x"></span
                            ></a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown-menu-footer border-top p-3">
                    <button class="btn btn-primary text-uppercase w-100">view all notifications</button>
                  </li>
                </ul>
              </li>

              
              <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a class="nav-link dropdown-toggle hide-arrow" routerLink="javascript:void(0);" data-bs-toggle="dropdown">
                  <div class="avatar avatar-online">
                    <img src="../../assets/img/avatars/{{image}}.png" alt class="w-px-40 h-auto rounded-circle" />
                  </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a class="dropdown-item" routerLink="pages-account-settings-account.html">
                      <div class="d-flex">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar avatar-online">
                            <img src="../../assets/img/avatars/{{image}}.png" alt class="w-px-40 h-auto rounded-circle" />
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <span class="fw-medium d-block">{{name}}</span>
                          <small class="text-muted">{{type}}</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="pages-profile-user.html">
                      <i class="bx bx-user me-2"></i>
                      <span class="align-middle">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="pages-account-settings-account.html">
                      <i class="bx bx-cog me-2"></i>
                      <span class="align-middle">Settings</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="pages-account-settings-billing.html">
                      <span class="d-flex align-items-center align-middle">
                        <i class="flex-shrink-0 bx bx-credit-card me-2"></i>
                        <span class="flex-grow-1 align-middle">Credit</span>
                        <span class="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">0</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li>
                    <a class="dropdown-item" routerLink="pages-faq.html">
                      <i class="bx bx-help-circle me-2"></i>
                      <span class="align-middle">Help</span>
                    </a>
                  </li>
                  
                  <li>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li>
                    <a class="dropdown-item"routerLink="">
                      <i class="bx bx-power-off me-2"></i>
                      <span class="align-middle" (click)="signout()">Log Out</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>


          <div class="navbar-search-wrapper search-input-wrapper container-fluid d-none">
            <input
              type="text"
              class="form-control search-input border-0"
              placeholder="Search..."
              aria-label="Search..." />
            <i class="bx bx-x bx-sm search-toggler cursor-pointer"></i>
          </div>
        </div>
      </nav>

            <aside id="layout-menu" class="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0 mb-3" >
            <div class="container-xxl d-flex h-100">
              <ul class="menu-inner">
                <li class="menu-item">
                  

                    <a routerLink="Dashboard" class="menu-link" routerLinkActive="active" >
                      <i class="menu-icon tf-icons bx bx-layout"></i>
                      <div data-i18n="Layouts">Dashboard</div>
                    </a>
                  </li>

                <li class="menu-item">
                  <a routerLink="Pendingtask" class="menu-link"routerLinkActive="active">
                    <i class="menu-icon tf-icons bx bx-layout"></i>
                    <div data-i18n="Layouts">Pending Order</div>
                  </a>
                </li>             
                
                <li class="menu-item">
                  <a routerLink="Accounts/pt" class="menu-link" routerLinkActive="active">
                    <i class="menu-icon tf-icons bx bx-layout"></i>
                    <div data-i18n="Layouts">Order List</div>
                  </a>
                </li>     
                

                
                <li class="menu-item">
                    <a routerLink="AgentBooking/null" class="menu-link" routerLinkActive="active">
                      <i class="menu-icon tf-icons bx bx-layout"></i>
                      <div data-i18n="Layouts">Web Data</div>
                    </a>
                  </li>


                  <li class="menu-item" routerLinkActive="active" style="cursor: pointer;">
                    <a  class="menu-link dropdown-toggle" id="navbarDropdownkanu1"
                     role="button" data-bs-toggle="dropdown" data-trigger="hover">
                    <i class="menu-icon tf-icons bx bx-customize"></i>
                    <div >Invoices</div></a>
                  
                    <ul class="dropdown-menu" routerLinkActive="active" aria-labelledby="navbarDropdownkanu1">
                    <li class="menu-item">
                      <a (click)="pt_invoice_table()" class="menu-link">
                        <i class="menu-icon tf-icons bx bx-calendar"></i>
                        <div >Portugal</div>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a (click)="be_invoice_table()" class="menu-link">
                        <i class="menu-icon tf-icons bx bx-calendar"></i>
                        <div >Belgium</div>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a (click)="fr_invoice_table()" class="menu-link">
                        <i class="menu-icon tf-icons bx bx-calendar"></i>
                        <div >France</div>
                      </a>
                    </li>
                  </ul>
                </li>

             

                <li class="menu-item" routerLinkActive="active">
                    <a routerLink="ClientList" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-customize"></i>
                    <div data-i18n="Accounts">Clients </div></a>
                </li>

                <li class="menu-item" routerLinkActive="active">
                  <a routerLink="notepad" class="menu-link">
                  <i class="menu-icon tf-icons bx bx-customize"></i>
                  <div data-i18n="Accounts">Notepad </div></a>
              </li>

              <li class="menu-item" routerLinkActive="active">
                <a routerLink="cash" class="menu-link">
                <i class="menu-icon tf-icons bx bx-customize"></i>
                <div data-i18n="Accounts">Cash </div></a>
            </li>

              </ul>
            </div>
          </aside>
