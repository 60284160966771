import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  rows: any;
  search_form: FormGroup;
  add_client_form: FormGroup;
  key: any;
  id: any;
  display: boolean;
  displayone: boolean;
  success: any;
  error: any;
  loading: boolean;

  constructor(private http:HttpClient,private router:Router,public formBuilder: FormBuilder,) { 
    this.search_form = this.formBuilder.group({
      id: ['', [Validators.required]]
    })


    this.add_client_form = this.formBuilder.group({
      email: ['', [Validators.required]],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      country: ['', [Validators.required]],
      adress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      switch: ['', [Validators.required]],
    })
  }
 

  ngOnInit(): void {

    this.loading = true; 
    let user: any = JSON.parse(localStorage.getItem('backend-flight-user'))   
    this.key = user.detail.token;
    this.id = user.detail.id;
  const payloads = {key:this.key,id:this.id};
  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payloads).subscribe((response: any) => {
    console.log(response) 
    if (response.status == 102) { 
      alert(response.error);
      localStorage.removeItem('backend-flight-user')
      localStorage.removeItem('isLoggedin')
      this.router.navigate(['/auth/login']);
    }           
    }  
); 
    const payload = {};
    this.display= false;      
  this.http.get('https://www.kelvintravel.com/api_agent/agent/api_client/api_new',payload).subscribe((response: any) => {
  this.displayone= true;  
  this.rows= response;
 
  this.loading = false;
 // console.log(this.rows)
   });

}
go_to_edit(id: any){
  this.router.navigate(['ClientEdit/'+id])
}


search_client(){
  if (this.search_form.value.id.length > 2){ 
    this.displayone = false;
    this.http.get('https://www.kelvintravel.com/api_agent/agent/Api_getclient?text='+this.search_form.value.id).subscribe((response: any) => {
      this.display= true;  
      this.rows= response;
      
 });

}
 
}

 

add_client(){
  const payload = {formdata:this.add_client_form.value};

  this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_create_client/',payload).subscribe((response: any) => {
   
    if (response.status == "1") {
     // this.success(response);
      alert(response.detail);
      const payload1 = {};
      this.http.get('https://www.kelvintravel.com/api_agent/agent/api_client/api_new',payload1).subscribe((response: any) => {
        this.displayone= true;  
        this.rows= response;    
         });
    } else {     
      //this.error(response);
      alert(response.error);
    }
  }, (err: any) => {    
    this.error(err)
  });
  
}

}

 