import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth.guard';
import { AppComponent } from './app.component';
const routes: Routes = [  
  { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule) },

{
path: '',
component:AppComponent,
canActivate: [AuthGuard],
children: [
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full'
  },
  { path: 'pages', loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule),}

]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
